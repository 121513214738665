import React from 'react';
import cx from 'classnames';
import ReactDOM from 'react-dom';

type CompoundComponents = {
  Header: React.FC;
  Content: React.FC;
  Footer: React.FC;
  Root: React.FC;
};
type ModalProps = {
  open: boolean;
  setOpen: () => void;
};
type ModalValue = {};

const Header: React.FC = ({ children }) => {
  return <div className="row modal-content__header">{children}</div>;
};

const Content: React.FC = ({ children }) => {
  return <div className="row">{children}</div>;
};

const Footer: React.FC = ({ children }) => {
  return <div className="row">{children}</div>;
};

const Root: React.FC = ({ children }) => {
  return <div className="modal-content rows">{children}</div>;
};

const modalRoot: HTMLElement = document.getElementById('modal-root') as HTMLElement;

const Modal: React.FC<ModalProps> & CompoundComponents = ({ setOpen, open, children }) => {
  return open
    ? ReactDOM.createPortal(
        <div className={cx(['modal', 'is-active'])}>
          <div className="modal-background" onClick={(): void => setOpen()}></div>
          {children}
        </div>,
        modalRoot
      )
    : null;
};

Modal.Header = Header;
Modal.Content = Content;
Modal.Footer = Footer;
Modal.Root = Root;
export default Modal;
