import { PhotoFormModel, BlankingFormModel, BlankingRequest, PhotoRequest } from 'interfaces/API/products';

const isProperPhoto = (maybeProperPhoto: PhotoFormModel): maybeProperPhoto is PhotoFormModel & { file: File } => {
  return typeof maybeProperPhoto['file'] !== 'undefined' && maybeProperPhoto['file'] instanceof File;
};

const castToPhotoRequest = ({ file }: PhotoFormModel & { file: File }): PhotoRequest => {
  return { file };
};

const isProperBlanking = (
  maybeProperBlanking: BlankingFormModel
): maybeProperBlanking is BlankingFormModel & { file: File } => {
  return typeof maybeProperBlanking['file'] !== 'undefined' && maybeProperBlanking['file'] instanceof File;
};

const castToBlankingRequest = ({ file }: BlankingFormModel & { file: File }): BlankingRequest => {
  return { file };
};

export const filterPhotos = (array: PhotoFormModel[]): PhotoRequest[] =>
  array.filter(isProperPhoto).map(castToPhotoRequest);

export const filterBlankings = (array: BlankingFormModel[]): BlankingRequest[] =>
  array.filter(isProperBlanking).map(castToBlankingRequest);
