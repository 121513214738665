import React from 'react';
import { withFormik, FormikProps, Form, Field, ErrorMessage } from 'formik';
import { CircleArrow } from 'assets';
import ActivityLogActions, { ActivityLogActionTypes } from 'modules/activityLog/actions';
import { Dispatch } from 'redux';
import * as yup from 'yup';

import FieldTextInput from 'molecules/fields/FieldTextInput';
import FieldInputError from 'atoms/FieldInputError';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/rootReducer';

type CreateMessageFormValues = {
  message: string;
};

type CreateMessageManagementProps = {
  productId: number;
  projectId: number;
  dispatch: Dispatch<ActivityLogActionTypes>;
  photo?: string;
};

const CreateMessageSchema = yup.object().shape({
  message: yup.string().required('Wpisz komentarz'),
});

const CreateMessage: React.FC<CreateMessageManagementProps & FormikProps<CreateMessageFormValues>> = ({
  photo,
  ...props
}) => {


  return (
    <Form>
      <div className="columns is-marginless create-message">
        {photo && (
          <div className="column is-narrow">
            <img src={photo} />
          </div>
        )}
        <div className="column">
          <Field
            name="message"
            component={FieldTextInput}
            placeholder="Napisz komentarz..."
            type="text"
            className="no-border"
          />
          <ErrorMessage name="message" component={FieldInputError} />
        </div>
        <div className="column is-narrow has-items-centered pointer">
          <CircleArrow onClick={(): void => props.handleSubmit()} />
        </div>
      </div>
    </Form>
  );
};

export default withFormik<CreateMessageManagementProps, CreateMessageFormValues>({
  enableReinitialize: true,
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: CreateMessageSchema,
  mapPropsToValues: () => {
    return {
      message: '',
    };
  },
  handleSubmit: async ({ message }, { props: { productId, projectId, dispatch }, resetForm }) => {
    if (productId) {
      await ActivityLogActions.sendProductMessage(dispatch, productId, message, resetForm);
    } else if (projectId) {
      await ActivityLogActions.sendProjectMessage(dispatch, projectId, message, resetForm);
    }
  },
})(CreateMessage);
