import React from 'react';
import cx from 'classnames';
import ProjectMemberItem from './ProjectMemberItem';
import { ProjectMember } from 'interfaces/API/projects';
import { CirclePlus } from 'assets';

type ProjectMembersContent = {
  members: ProjectMember[];
};

const ProjectMembersContent: React.FC<ProjectMembersContent> = ({ members }) => {
  return (
    <div className="project-members-container">
      <div className={cx('project-members-content')}>
        {members.map(({ user: { id, ...user } }: ProjectMember) => (
          <ProjectMemberItem key={id} {...user} />
        ))}
      </div>
      {/* <div className="project-members-footer project-members-footer__icon">
        <CirclePlus className="scale-on-hover " />
      </div> */}
    </div>
  );
};

export default ProjectMembersContent;
