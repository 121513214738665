import { useCallback, useState } from 'react';

const useToggler = (initialState = false): [boolean, () => void] => {
  const [state, setState] = useState(initialState);

  const toggler = useCallback(() => {
    setState((prev) => !prev);
  }, []);

  return [state, toggler];
};

export default useToggler;
