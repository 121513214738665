import API from './axiosConfig';
import { ProductStage, ProductStageGroup } from 'interfaces/API/projects';

export interface ProductStageResponse {
  id: ProductStage;
  label: string;
  group: ProductStageGroup;
  description: string;
}

export interface DropdownItem {
  id: number;
  name: string;
}

export type PackagingType = {
  value: number;
  label: string;
};

export type Brand = {
  id: number;
  name: string;
};

export type Category = {
  id: number;
  name: string;
};

const fetchCategories = async (): Promise<Category[]> => {
  try {
    const response = await API.get<Category[]>('/categories/');
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const fetchBrands = async (): Promise<Brand[]> => {
  try {
    const response = await API.get<Brand[]>('/brands/');
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const fetchPackagingTypes = async (): Promise<PackagingType[]> => {
  try {
    const response = await API.get<PackagingType[]>('/packaging-types/');
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
const fetchProductStages = async (): Promise<ProductStageResponse[]> => {
  try {
    const response = await API.get<ProductStageResponse[]>('/stages/');
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export default {
  fetchCategories,
  fetchBrands,
  fetchPackagingTypes,
  fetchProductStages,
};
