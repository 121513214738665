import React from 'react';
import { Provider } from 'react-redux';
import store from './store/store';
import './App.scss';
import { Switch, Router, Redirect } from 'react-router-dom';
import { Login, Main } from 'containers';
import { PrivateRoute, NotAuthorizedRoute } from 'helpers/PrivateRoute';
import { createBrowserHistory } from 'history';
import 'react-image-lightbox/style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const history = createBrowserHistory();

const App: React.FC = () => {
  return (
    <Router history={history}>
      <Provider store={store}>
        <Switch>
          <NotAuthorizedRoute exact path="/login">
            <ToastContainer position={toast.POSITION.BOTTOM_LEFT} autoClose={5000} />
            <Login />
          </NotAuthorizedRoute>
          <PrivateRoute path={['/projects/:id/products/:pid', '/projects/new', '/projects/:id', '/']}>
            <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} autoClose={5000} />
            <Main />
          </PrivateRoute>
          <Redirect to="/" />
        </Switch>
      </Provider>
    </Router>
  );
};

export default App;
