import React, { useEffect, useMemo } from 'react';
import photoBriefSelectors from 'modules/photoBrief/selectors';
import photoBriefActions from 'modules/photoBrief/actions';
import { Dispatch } from 'redux';
import PhotoBriefNotCompleted from './PhotoBriefNotCompleted';
import { ItemValue } from 'molecules/modals/ImagesFormModal';
import { ProductActionTypes } from 'modules/products/actions';
import useNumberParams from 'hooks/useNumberParams';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'modules/rootReducer';
import useCurrentStage from 'hooks/useCurrentStage';
import useCurrentPhotoBrief from 'hooks/useCurrentPhotoBrief';
import { PhotoBriefStage } from 'interfaces/API/photoBrief';
import { ClimbingLoader } from 'atoms/ClimbingLoader';
import userSelectors from 'modules/users/selectors';
import RetryButton from 'molecules/RetryButton';
import { RequestState } from 'hooks/useRequestState';
interface PhotoBriefValues {
  conceptDescription: string;
  sessionDate: string;
  hasModels: boolean;
  hasStylings: boolean;
  hasSceneries: boolean;
  models: ItemValue[];
  styling: ItemValue[];
  scenery: ItemValue[];
}

type ManagementProps = {
  dispatch: Dispatch<ProductActionTypes>;
};

const Photobrief: React.FC = () => {
  const { productId } = useNumberParams();
  const dispatch = useDispatch();

  useEffect(() => {
    (async (): Promise<void> => {
      await photoBriefActions.getPhotoBriefCategories(dispatch);
    })();
  }, [dispatch]);

  const [categories, userRole] = useSelector((state: AppState) => [
    photoBriefSelectors.categories(state),
    userSelectors.currentUserRole(state),
  ]);
  const [status, currentPhotoBrief, handleRetry] = useCurrentPhotoBrief();
  const acceptable = useMemo(() => currentPhotoBrief?.acceptable || false, [currentPhotoBrief]);
  const editable = useMemo(() => currentPhotoBrief?.editable || false, [currentPhotoBrief]);
  const publishable = useMemo(() => currentPhotoBrief?.publishable || false, [currentPhotoBrief]);
  const completable = useMemo(() => currentPhotoBrief?.completable || false, [currentPhotoBrief]);

  useCurrentStage(2);
  return status === RequestState.IS_LOADING ? (
    <ClimbingLoader />
  ) : status === RequestState.HAS_ERROR ? (
    <div className="retry-dashboard">
      <RetryButton onRetry={handleRetry} />
    </div>
  ) : currentPhotoBrief ? (
    <PhotoBriefNotCompleted
      currentPhotoBrief={currentPhotoBrief}
      acceptable={acceptable}
      editable={editable}
      publishable={publishable}
      completable={completable}
      choices={currentPhotoBrief?.choices || []}
      stage={currentPhotoBrief?.stage || PhotoBriefStage.CREATED}
      productId={productId}
      dispatch={dispatch}
      allCategories={categories}
      userRole={userRole}
    />
  ) : null;
};
export default Photobrief;
