import { AppState } from 'modules/rootReducer';
import { createSelector, Selector } from 'reselect';
import { DesignStageStore } from 'interfaces/API/draft';

//TODO: CREATESELECTORS

export const drafts = (state: AppState): DesignStageStore[] => state.drafts.draftList;

export const draftById = (draftId: number): Selector<AppState, DesignStageStore | undefined> =>
  createSelector(drafts, (list) => list.find((item) => item.id === draftId));

// export const labelToStageSelector = (stage: string): Selector<AppState, ProductStageResponse | undefined> =>
//   createSelector(productStages, (list) => list.find((item) => item.id === stage));
