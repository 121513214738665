import { createAction, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';
import dataApi, { PackagingType, Brand, Category, ProductStageResponse } from 'API/projectData';

export type DataState = {
  packagingTypes: PackagingType[];
  brands: Brand[];
  categories: Category[];
  productStages: ProductStageResponse[];
};

export enum actionTypes {
  SET_PACKAGING_TYPES = 'data/SET_PACKAGING_TYPES',
  SET_BRANDS = 'data/SET_BRANDS',
  SET_CATEGORIES = 'data/SET_CATEGORIES',
  SET_PRODUCT_STAGES = 'data/SET_PRODUCT_STAGES',
}

const internalActions = {
  setPackagingTypes: createAction(actionTypes.SET_PACKAGING_TYPES)<PackagingType[]>(),
  setBrands: createAction(actionTypes.SET_BRANDS)<Brand[]>(),
  setCategories: createAction(actionTypes.SET_CATEGORIES)<Category[]>(),
  saveBriefStages: createAction(actionTypes.SET_PRODUCT_STAGES)<ProductStageResponse[]>(),
};

// const setPackagingTypes = createAction(actionTypes.SET_PACKAGING_TYPES)<PackagingType[]>();
// const setBrands = createAction(actionTypes.SET_BRANDS)<Brand[]>();
// const setCategories = createAction(actionTypes.SET_CATEGORIES)<Category[]>();

export type DataActionTypes = ActionType<typeof internalActions>;

export const getPackagingTypes = async (dispatch: Dispatch<DataActionTypes>): Promise<boolean> => {
  const response = await dataApi.fetchPackagingTypes();
  if (response) {
    dispatch(internalActions.setPackagingTypes(response));
    return true;
  }
  return false;
};

export const getBrands = async (dispatch: Dispatch<DataActionTypes>): Promise<boolean> => {
  const response = await dataApi.fetchBrands();
  if (response) {
    dispatch(internalActions.setBrands(response));
    return true;
  }
  return false;
};

export const getCategories = async (dispatch: Dispatch<DataActionTypes>): Promise<boolean> => {
  const response = await dataApi.fetchCategories();
  if (response) {
    dispatch(internalActions.setCategories(response));
    return true;
  }
  return false;
};

export const getProductStages = async (dispatch: Dispatch<DataActionTypes>): Promise<boolean> => {
  const response = await dataApi.fetchProductStages();
  if (response) {
    dispatch(internalActions.saveBriefStages(response));
    return true;
  }
  return false;
};

// export const handleStageChange = (currentStage: BriefState): BriefActionTypes => ({
//   type: SET_CURRENT_STAGE,
//   payload: currentStage,
// });
