import React, { TextareaHTMLAttributes } from 'react';
import cx from 'classnames';

const TextArea: React.FC<TextareaHTMLAttributes<HTMLTextAreaElement>> = ({ className, ...props }) => {
  return (
    <textarea
      className={cx('textarea is-input-border is-mobile', className)}
      {...props}
      title={props.value ? props.placeholder : undefined}
    />
  );
};

export default TextArea;
