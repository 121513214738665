import React from 'react';
import { OutboLogo, outboLoginBg } from 'assets';
import LoginForm from 'organisms/LoginForm';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';

const Login: React.FC = (props): any => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  return (
    <div className="columns is-marginless">
      <div className="column has-items-centered login__pos">
        <div className="rows mb-10">
          <div className="row login__pos">
            <OutboLogo className="login__logo mb-2" />
          </div>
          <div className="row  login__container mt-2">
            <LoginForm history={history} dispatch={dispatch} location={location} />
          </div>
        </div>
      </div>

      <div className="column is-8 is-paddingless login__background">
        <img src={outboLoginBg} alt="background" />
      </div>
    </div>
  );
};

export default Login;
