import API from './axiosConfig';

export enum MessageType {
  USER = 'USER',
  SYSTEM_SUCCESS = 'Success',
  SYSTEM_FAILURE = 'Failure',
}

export type MessageActivityLogItem = {
  messageType: MessageType;
  message: string;
  stage: string;
  createdBy: number;
  createdAt: Date;
};

export const fetchProductActivityLog = async (productId: number): Promise<MessageActivityLogItem[] | []> => {
  try {
    const response = await API.get<MessageActivityLogItem[]>(`/products/${productId}/messages/`);
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const fetchProjectActivityLog = async (projectId: number): Promise<MessageActivityLogItem[] | []> => {
  try {
    const response = await API.get<MessageActivityLogItem[]>(`/projects/${projectId}/messages/`);
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const postNewProductMessage = async (
  message: string,
  productId: number
): Promise<MessageActivityLogItem | null> => {
  try {
    const response = await API.post<MessageActivityLogItem>(`/products/${productId}/messages/`, { message });
    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const postNewProjectMessage = async (
  message: string,
  projectId: number
): Promise<MessageActivityLogItem | null> => {
  try {
    const response = await API.post<MessageActivityLogItem>(`/projects/${projectId}/messages/`, { message });
    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};
