import { actionTypes, DraftState, PrintingFilesActionTypes } from './actions';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import { DesignStageStore } from 'interfaces/API/draft';

const initialState: DraftState = {
  printingFilesList: [],
};

const dataReducer = createReducer<DraftState, PrintingFilesActionTypes>(initialState)
  .handleType(actionTypes.ADD_PRINTING_FILE, (state, action) =>
    produce(state, (draftState) => {
      const draft: DesignStageStore = { ...action.payload.response, id: action.payload.printingFilesId };
      draftState.printingFilesList.push(draft);
    })
  )
  .handleType(actionTypes.UPDATE_PRINTING_FILE, (state, { payload: { printingFilesId, response } }) =>
    produce(state, (draftState) => {
      const index = draftState.printingFilesList.findIndex((pf) => pf.id === printingFilesId);
      // TODO: nested produce? how?
      draftState.printingFilesList[index] = { ...draftState.printingFilesList[index], ...response };
    })
  )
  .handleType(actionTypes.ADD_PRINTING_FILES_FILE, (state, { payload: { printingFilesId, response } }) =>
    produce(state, (draftState) => {
      const index = draftState.printingFilesList.findIndex((item) => item.id === printingFilesId);
      draftState.printingFilesList[index].files.unshift(response);
    })
  )
  .handleType(actionTypes.UPDATE_PRINTING_FILE_OPENED_BY, (state, { payload: { printingFilesId, response, fileId } }) =>
    produce(state, (draftState) => {
      const index = draftState.printingFilesList.findIndex((draft) => draft.id === printingFilesId);
      const files = draftState.printingFilesList[index].files;
      const fileIndex = files.findIndex((file) => file.id === fileId);
      draftState.printingFilesList[index].files[fileIndex].openedBy = response;
    })
  );

export default dataReducer;
