import React from 'react';
import { ProductFileStage, ProductFileStageTypes } from 'molecules/ProductFileStage';
import useCurrentPF from 'hooks/useCurrentPF';
import useCurrentStage from 'hooks/useCurrentStage';
import RetryButton from 'molecules/RetryButton';
import { ClimbingLoader } from 'atoms/ClimbingLoader';
import { RequestState } from 'hooks/useRequestState';

const PrintingFilesList: React.FC = () => {
  //TODO EXTRACT USECURRENTSTAGEHOOK
  const [status, currentPrintingFile, handleRetry] = useCurrentPF();

  useCurrentStage(4);

  return status === RequestState.IS_LOADING ? (
    <ClimbingLoader />
  ) : status === RequestState.HAS_ERROR ? (
    <div className="retry-dashboard">
      <RetryButton onRetry={handleRetry} isLoading={false} />
    </div>
  ) : currentPrintingFile ? (
    <ProductFileStage
      type={ProductFileStageTypes.PF}
      editable={currentPrintingFile.editable}
      acceptable={currentPrintingFile.acceptable}
    >
      <ProductFileStage.Header />
      <ProductFileStage.List>
        {currentPrintingFile &&
          currentPrintingFile.files.map((file, idx) => {
            return <ProductFileStage.ListItem key={file.id} file={file} first={idx === 0} />;
          })}
      </ProductFileStage.List>
    </ProductFileStage>
  ) : null;
};

export default PrintingFilesList;
