import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useNumberParams from './useNumberParams';
import { DesignStageStore } from 'interfaces/API/draft';
import { printingFileById } from 'modules/printingFiles/selectors';
import { getPrintingFileById } from 'modules/printingFiles/actions';
import useRequestState, { RequestState } from './useRequestState';

type CurrentPF = [RequestState, DesignStageStore | null, () => void];

const useCurrentPF = (): CurrentPF => {
  const { productId } = useNumberParams();
  const dispatch = useDispatch();
  const [status, setError, setLoading, setDefault] = useRequestState();
  const [retry, setRetry] = useState(0);
  const pf = useSelector(printingFileById(productId));
  const handleRetry = (): void => {
    setDefault();
    setRetry(retry + 1);
  };

  useEffect(() => {
    if (!pf && status === RequestState.DEFAULT) {
      setLoading();
      (async (): Promise<void> => {
        const response = await getPrintingFileById(productId, dispatch);
        if (!response) {
          setError();
          return;
        }
        setDefault();
      })();
    }
  }, [dispatch, pf, productId, setDefault, setError, setLoading, status]);

  return [status, pf ? pf : null, handleRetry];
};
export default useCurrentPF;
