import React from 'react';
import RetryButton from 'molecules/RetryButton';
import cx from 'classnames';

type RetryScreenProps = {
  onRetry: () => void;
  isLoading: boolean;
};

export const RetryScreen: React.FC<RetryScreenProps> = ({ onRetry, isLoading }) => {
  return (
    <div className={cx('retry-container')}>
      <RetryButton onRetry={onRetry} isLoading={isLoading} />
    </div>
  );
};
