import React from 'react';
import PhotoBriefCompleted from 'organisms/FinalPhotoBrief/PhotoBriefCompleted';
import useFinalPhotoBrief from 'hooks/useCurrentFinalPhotoBrief';
import { useDispatch, useSelector } from 'react-redux';
import useNumberParams from 'hooks/useNumberParams';
import { ClimbingLoader } from 'atoms/ClimbingLoader';
import RetryButton from 'molecules/RetryButton';
import { RequestState } from 'hooks/useRequestState';
import userSelectors from 'modules/users/selectors';
import { AppState } from 'modules/rootReducer';

export const FinalPhotoBrief = () => {
  const [status, currentFinalPB, config, handleRetry] = useFinalPhotoBrief();
  const userRole = useSelector((state: AppState) => userSelectors.currentUserRole(state));
  const { productId } = useNumberParams();
  const dispatch = useDispatch();
  return status === RequestState.IS_LOADING ? (
    <ClimbingLoader />
  ) : status === RequestState.HAS_ERROR ? (
    <div className="retry-dashboard">
      <RetryButton onRetry={handleRetry} />
    </div>
  ) : currentFinalPB && config ? (
    <PhotoBriefCompleted
      role={userRole}
      currentFinalPhotoBrief={currentFinalPB}
      productId={productId}
      dispatch={dispatch}
      config={config}
    />
  ) : (
    <ClimbingLoader />
  );
};
