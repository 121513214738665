import * as yup from 'yup';

const ProductBriefSchema = yup.object().shape({
  namePol: yup.string().required('Podaj nazwę produktu w języku polskim.'),
  nameEng: yup.string().required('Podaj nazwę produktu w języku angielskim.'),
  modelNo: yup
    .string()
    .typeError('Wpisz model')
    .required('Wpisz model'),
  batchNo: yup
    .string()
    .typeError('Wpisz nr partii')
    .required('Wpisz nr partii'),
  ean: yup
    .string()
    .typeError('Wpisz EAN')
    .max(13, 'EAN nie może mieć więcej znaków niż 13.')
    .required('Wpisz EAN'),

  packagingType: yup
    .number()
    .typeError('Wybierz rodzaj pakowania')
    .required('Wybierz rodzaj pakowania'),
  mainDescription: yup.string().required('Wpisz opis'),
  extraDescription: yup.string().required('Wpisz opis dodatkowy'),
  certificate: yup.string().required('Podaj certyfikaty'),
  informationSigns: yup.string().required('Podaj znaki informacyjne'),
  capabilitiesIcons: yup.string().required('Podaj ikony kompetencji'),
  productIcons: yup.string().required('Podaj ikony produktowe'),
  hasDescriptionEng: yup.bool(),
  hasDescriptionPol: yup.bool(),
  hasDescriptionPor: yup.bool(),
  hasDescriptionRus: yup.bool(),
  hasDescriptionSpa: yup.bool(),
  illustration: yup.string().when('config.illustration', { is: true, then: (m: any) => m.required() }),
  cleaningMethod: yup.string().when('config.cleaningMethod', { is: true, then: (m: any) => m.required() }),
  countryOfOrigin: yup.string().when('config.countryOfOrigin', { is: true, then: (m: any) => m.required() }),
  manual: yup.string().when('config.manual', { is: true, then: (m: any) => m.required() }),
  material: yup.string().when('config.material', { is: true, then: (m: any) => m.required() }),

  warning: yup.string().when('config.warning', { is: true, then: (m: any) => m.required() }),
  arrangementPhoto: yup.string().when('config.photobrief', { is: true, then: (m: any) => m.required() }),
  packshot: yup.string().when('config.packshot', { is: true, then: (m: any) => m.required() }),
  clipping: yup.string().when('config.clipping', { is: true, then: (m: any) => m.required() }),
  extraPhoto: yup.string().when('config.extraPhoto', { is: true, then: (m: any) => m.required() }),

  photos: yup.array().min(1, 'Wymagane przynajmniej 1 zdjęcie.'),
  blankings: yup.array().min(1, 'Wymagany przynajmniej 1 wykrojnik.'),

  config: yup.object().shape({
    illustration: yup.bool(),
    cleaningMethod: yup.bool(),
    countryOfOrigin: yup.bool(),
    manual: yup.bool(),
    material: yup.bool(),
    warning: yup.bool(),
    photobrief: yup.bool(),
    packshot: yup.bool(),
    clipping: yup.bool(),
    extraPhoto: yup.bool(),
  }),
});

export default ProductBriefSchema;
