import React from 'react';
import { Route, Redirect, useLocation, RouteProps } from 'react-router-dom';
import { TOKEN } from 'API/axiosConfig';
import { parse } from 'query-string';

export const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem(TOKEN) ? children : <Redirect to={`/login?next=${location.pathname}`} />
      }
    />
  );
};
export const NotAuthorizedRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const location = useLocation();
  const query = parse(location.search);
  const redirectUrl = query.next ? query : '/';
  return (
    <Route {...rest} render={(props) => (!localStorage.getItem(TOKEN) ? children : <Redirect to={redirectUrl} />)} />
  );
};
