import React from 'react';
import cx from 'classnames';
type ModalFooterProps = {
  action: any;
  text: string;
  cancelAction: () => void;
  isSubmitting: boolean;
};

export const ModalFooter: React.FC<ModalFooterProps> = ({ action, text, cancelAction, isSubmitting }) => {
  return (
    <>
      <button
        className={cx({ 'is-loading': isSubmitting }, 'button is-orange-lighten mr-1')}
        type="button"
        onClick={async (): Promise<void> => {
          await action();
        }}
      >
        <span>{text}</span>
      </button>
      <button type="button" className="button is-light-grey" onClick={cancelAction}>
        <span>Anuluj</span>
      </button>
    </>
  );
};
