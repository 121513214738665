import { createAction, ActionType } from 'typesafe-actions';

export type ProgressState = {
  progress: number | null;
};

export enum actionTypes {
  SET_PROGRESS = 'progress/SET_PROGRESS',
  CLEAR_PROGRESS = 'progress/CLEAR_PROGRESS',
}

export const setProgress = createAction(actionTypes.SET_PROGRESS)<number>();
export const clearProgress = createAction(actionTypes.CLEAR_PROGRESS)();

export type ProgressActionTypes = ActionType<typeof setProgress | typeof clearProgress>;
