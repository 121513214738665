import API from './axiosConfig';
import { ProjectResponse, ProjectRequest, SimpleProject } from 'interfaces/API/projects';
import { toast } from 'react-toastify';
import { ProjectsQueryParams } from '../interfaces/API/projects';

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

const getProjects = async (params: RequireAtLeastOne<ProjectsQueryParams>): Promise<SimpleProject[] | null> => {
  try {
    const response = await API.get<ProjectResponse[]>('/projects/', { params });
    return response.data;
  } catch (err) {
    toast.error('Nie udało się pobrać listy projektów.');
    return null;
  }
};

const getProjectById = async (projectId: number): Promise<ProjectResponse | null> => {
  try {
    const response = await API.get<ProjectResponse>(`projects/${projectId}/`);
    return response.data;
  } catch (err) {
    toast.error('Wystąpił problem podczas pobierania projektu.');
    return null;
  }
};

const createNewProject = async (data: ProjectRequest): Promise<ProjectResponse | null> => {
  try {
    const response = await API.post('/projects/', data);
    return response.data;
  } catch (err) {
    toast.error('Wystąpił problem podczas tworzenia projektu.');
    return null;
  }
};

const publishProject = async (projectId: number): Promise<ProjectResponse | null> => {
  try {
    const response = await API.patch<ProjectResponse>(`/projects/${projectId}/`, { publish: true });
    return response.data;
  } catch (err) {
    toast.error('Publikacja projektu nie powiodła się');
    return null;
  }
};

export default {
  getProjects,
  getProjectById,
  createNewProject,
  publishProject,
};
