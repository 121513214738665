import { createAction, ActionType } from 'typesafe-actions';
import {
  FinalPhotoBriefResponse,
  FinalPhotoBriefStore,
  FinalPhotoBriefFormModel,
  FinalPhotoBriefPhotoRequest,
  FinalPhotoBriefPhotoType,
} from 'interfaces/API/finalPhotoBrief';
import { Dispatch } from 'redux';
import FinalPhotoBriefApi from 'API/finalPhotoBrief';
export type PhotoBriefState = {
  finalPhotoBriefList: FinalPhotoBriefStore[];
};

export enum actionTypes {
  ADD_FINAL_PHOTO_BRIEF = 'finalPhotoBrief/GET_FINAL_PHOTO_BRIEF',
  UPDATE_FINAL_PHOTO_BRIEF = 'finalPhotoBrief/UPDATE_PHOTO_BRIEF',
  REMOVE_FINAL_BRIEF_PHOTO = 'finalPhotoBrief/REMOVE_PHOTO',
}

const externalActions = {};

const internalActions = {
  deleteFinalPhotoBriefPhoto: createAction(actionTypes.REMOVE_FINAL_BRIEF_PHOTO)<{
    url: string;
    finalPhotoBriefId: number;
  }>(),
  addFinalPhotoBrief: createAction(actionTypes.ADD_FINAL_PHOTO_BRIEF)<{
    finalPhotoBriefId: number;
    response: FinalPhotoBriefResponse;
  }>(),
  updateFinalPhotoBrief: createAction(actionTypes.UPDATE_FINAL_PHOTO_BRIEF)<{
    finalPhotoBriefId: number;
    response: FinalPhotoBriefResponse;
  }>(),
};

export type FinalPhotoBriefActionTypes = ActionType<typeof internalActions | typeof externalActions>;

const getFinalPhotoBriefById = async (
  finalPhotoBriefId: number,
  dispatch: Dispatch<FinalPhotoBriefActionTypes>
): Promise<boolean> => {
  const response = await FinalPhotoBriefApi.getFinalPhotoBriefById(finalPhotoBriefId);
  if (response) {
    dispatch(internalActions.addFinalPhotoBrief({ finalPhotoBriefId, response }));
    return true;
  }
  return false;
};

const addFinalPhotoBriefItems = async (
  finalPhotoBriefId: number,
  data: FinalPhotoBriefFormModel[],
  dispatch: Dispatch<FinalPhotoBriefActionTypes>
): Promise<boolean> => {
  const photos = data.reduce((acc, curr) => {
    curr.photosForType.forEach((item) => {
      if (item.file) {
        acc.push({ type: curr.type, file: item.file });
      }
    });
    return acc;
  }, [] as FinalPhotoBriefPhotoRequest[]);

  const response = await FinalPhotoBriefApi.addFinalPhotoBriefItems({ photos }, finalPhotoBriefId);
  if (response) {
    dispatch(internalActions.updateFinalPhotoBrief({ finalPhotoBriefId, response }));
    return true;
  }
  return false;
};

const removeFinalPhotoBrief = async (
  url: string,
  finalPhotoBriefId: number,
  dispatch: Dispatch<FinalPhotoBriefActionTypes>
): Promise<void> => {
  const response = await FinalPhotoBriefApi.removeFinalPhotoBriefPhoto(url);
  if (response === 200 || response === 204) {
    dispatch(internalActions.deleteFinalPhotoBriefPhoto({ url, finalPhotoBriefId }));
  }
};

export default {
  getFinalPhotoBriefById,
  addFinalPhotoBriefItems,
  removeFinalPhotoBrief,
};
