import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/rootReducer';

interface StoreProviderProps {
  selectors: any;
  component: React.ComponentType;
}

const StoreProvider: React.FC<StoreProviderProps> = ({ component: Component, selectors, ...props }) => {
  // const result = useSelector((state:AppState)=> selectors.map((selector:any)=>selector(state)));
  const result = useSelector((state: AppState) =>
    Object.keys(selectors).reduce((previous, current) => ({ ...previous, [current]: selectors[current](state) }), {})
  );

  return <Component {...props} {...result} />;
};

export default StoreProvider;
