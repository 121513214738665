import { UserActionTypes, actionTypes, UserState } from './actions';

const initialState: UserState = {
  //TODO CHANGE TO 0
  currentUser: null,
  usersList: [],
};

const usersReducer = (state = initialState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_USER: {
      return { ...state, currentUser: action.payload };
    }
    case actionTypes.REMOVE_CURRENT_USER: {
      return { ...state, currentUser: null };
    }
    case actionTypes.SET_ALL_USERS: {
      return { ...state, usersList: action.payload };
    }
    default:
      return state;
  }
};
export default usersReducer;
