import React from 'react';
import cx from 'classnames';

type RetryProps = {
  onRetry: () => void;
  isLoading?: boolean;
};

const RetryButton: React.FC<RetryProps> = ({ onRetry, isLoading }) => {
  return (
    <button
      type="button"
      className={cx('button is-orange-lighten', { 'is-loading': isLoading })}
      onClick={(): void => onRetry()}
      disabled={isLoading}
    >
      <span>Spróbuj ponownie</span>
    </button>
  );
};
export default RetryButton;
