import React, { InputHTMLAttributes } from 'react';
import cx from 'classnames';
import { FieldTextInputInterface } from 'interfaces/InputInterfaces';
import debounce from 'lodash.debounce';

interface SearchInput {
  searchFunc: (searchValue: string) => void;
}

const SearchInput: React.FC<SearchInput & FieldTextInputInterface & InputHTMLAttributes<HTMLInputElement>> = ({
  field,
  form,
  searchFunc,
  className,
  ...props
}) => {
  return (
    <input
      className={cx('input', className)}
      {...field}
      {...props}
      title={props.value ? props.placeholder : undefined}
      onChange={(e) => {
        form.handleChange(e);
        searchFunc(e.target.value);
      }}
    />
  );
};

export default SearchInput;
