import { AppState } from 'modules/rootReducer';
import { createSelector, Selector } from 'reselect';
import { FinalPhotoBriefStore } from 'interfaces/API/finalPhotoBrief';

//TODO: CREATESELECTORS
const finalPhotoBriefs = (state: AppState): FinalPhotoBriefStore[] => state.finalPhotoBrief.finalPhotoBriefList;

const finalPhotoBriefById = (finalPhotoBriefId: number): Selector<AppState, FinalPhotoBriefStore | undefined> =>
  createSelector(finalPhotoBriefs, (list) => list.find((item) => item.id === finalPhotoBriefId));

export default {
  finalPhotoBriefs,
  finalPhotoBriefById,
};
