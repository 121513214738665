import API from './axiosConfig';
import objectToFormDataConverter from 'helpers/objectToFormDataConverter';
import { BriefRequest, BriefResponse } from 'interfaces/API/products';
import { ReactText } from 'react';

const getBriefById = async (briefId: number): Promise<BriefResponse | null> => {
  try {
    const response = await API.get<BriefResponse>(`/briefs/${briefId}/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

const patchBriefById = async (briefId: number, data: BriefRequest): Promise<BriefResponse | null> => {
  try {
    const { packagingType, ...rest } = data;
    let formData;
    if (packagingType === null) {
      formData = objectToFormDataConverter(rest);
    } else {
      formData = objectToFormDataConverter(data);
    }
    const response = await API.patch<BriefResponse>(`/briefs/${briefId}/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

const acceptBrief = async (briefId: number): Promise<BriefResponse | null> => {
  try {
    const response = await API.patch<BriefResponse>(`/briefs/${briefId}/`, { accept: true });
    return response.data;
  } catch (err) {
    return null;
  }
};

const rejectBrief = async (briefId: number): Promise<BriefResponse | null> => {
  try {
    const response = await API.patch<BriefResponse>(`/briefs/${briefId}/`, { accept: false });
    return response.data;
  } catch (err) {
    return null;
  }
};

const publishBrief = async (briefId: number): Promise<BriefResponse | null> => {
  try {
    const response = await API.patch<BriefResponse>(`/briefs/${briefId}/`, { publish: true });
    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const deleteBlanking = async (blankingId: number): Promise<number | null> => {
  try {
    const response = await API.delete<void>(`/blankings/${blankingId}/`);
    return response.status;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const deletePhoto = async (photoId: number): Promise<number | null> => {
  try {
    const response = await API.delete<void>(`/photos/${photoId}/`);
    return response.status;
  } catch (err) {
    console.error(err);
    return null;
  }
};
const cloneBrief = async (copyFrom: number, briefId: number): Promise<BriefResponse | null> => {
  try {
    const response = await API.patch<BriefResponse>(`/briefs/${briefId}/`, { cloneFrom: copyFrom });
    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const exportBriefToPdf = async (briefId: ReactText, filename: string): Promise<void | null> => {
  try {
    const response = await API.get(`/briefs/${briefId}/export-to-pdf/`, { responseType: 'blob' });
    const file = new Blob([response.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = filename;
    document.body.append(link);
    link.click();
    link.remove();
  } catch (err) {
    return null;
  }
};

export default {
  exportBriefToPdf,
  cloneBrief,
  deleteBlanking,
  deletePhoto,
  getBriefById,
  acceptBrief,
  rejectBrief,
  patchBriefById,
  publishBrief,
};
