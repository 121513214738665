import { useState } from 'react';

export enum RequestState {
  IS_LOADING = 'IS_LOADING',
  HAS_ERROR = 'HAS_ERROR',
  DEFAULT = 'DEFAULT',
}

const useRequestState = (): [RequestState, () => void, () => void, () => void] => {
  const [status, setStatus] = useState(RequestState.DEFAULT);

  const setError = (): void => {
    setStatus(RequestState.HAS_ERROR);
  };
  const setLoading = (): void => {
    setStatus(RequestState.IS_LOADING);
  };
  const setDefault = (): void => {
    setStatus(RequestState.DEFAULT);
  };
  return [status, setError, setLoading, setDefault];
};
export default useRequestState;
