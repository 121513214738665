import { createAction, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';
import draftApi from 'API/drafts';
import ActivityLogActions, { ActivityLogActionTypes } from 'modules/activityLog/actions';
import {
  DesignStageResponse,
  DesignStageStore,
  DesignStageFileRequest,
  DesignStageFileResponse,
} from 'interfaces/API/draft';
import { ProductActionTypes, updateProductOnSuccess } from 'modules/products/actions';
import { toast } from 'react-toastify';

export type DraftState = {
  draftList: DesignStageStore[];
};

export enum actionTypes {
  ADD_DRAFT = 'drafts/ADD_DRAFT',
  UPDATE_DRAFT = 'drafts/UPDATE_DRAFT',
  ADD_DRAFT_FILE = 'drafts/ADD_DRAFT_FILE',
  UPDATE_DRAFT_OPENED_BY = 'drafts/UPDATE_DRAFT_OPENED_BY',
}

const externalActions = {};

const internalActions = {
  addDraft: createAction(actionTypes.ADD_DRAFT)<{ draftId: number; response: DesignStageResponse }>(),
  updateDraft: createAction(actionTypes.UPDATE_DRAFT)<{ draftId: number; response: DesignStageResponse }>(),
  addDraftFile: createAction(actionTypes.ADD_DRAFT_FILE)<{ draftId: number; response: DesignStageFileResponse }>(),
  updateOpenedFile: createAction(actionTypes.UPDATE_DRAFT_OPENED_BY)<{
    draftId: number;
    response: number[];
    fileId: number;
  }>(),
};

export type DraftActionTypes = ActionType<typeof internalActions | typeof externalActions>;
type DraftFile = DraftActionTypes | ProductActionTypes | ActivityLogActionTypes;

// export const updateOpenDraftFile = createAction(actionTypes.UPDATE_DRAFT_OPENED_BY)<{ id: number; data: number[] }>();

export const getDraftById = async (draftId: number, dispatch: Dispatch<DraftActionTypes>): Promise<boolean> => {
  const response = await draftApi.getDraftById(draftId);
  if (!response) {
    return false;
  }
  dispatch(internalActions.addDraft({ draftId, response }));
  return true;
};

export const addDraftFile = async (
  dispatch: Dispatch<DraftFile>,
  draftId: number,
  data: DesignStageFileRequest
): Promise<void> => {
  const response = await draftApi.addDraftFile(draftId, data);
  if (response) {
    dispatch(internalActions.addDraftFile({ draftId, response }));
    const draftResponse = await draftApi.getDraftById(draftId);
    if (draftResponse) {
      dispatch(internalActions.updateDraft({ draftId, response: draftResponse }));
      await ActivityLogActions.getProductMessages(dispatch, draftId);
    }
    await updateProductOnSuccess(draftId, dispatch);
    toast.success('Dodano draft');
  }
};

export const acceptDraftFile = async (dispatch: Dispatch<DraftFile>, draftId: number): Promise<void> => {
  const response = await draftApi.acceptDraft(draftId);
  if (response) {
    dispatch(internalActions.updateDraft({ draftId, response }));
    await updateProductOnSuccess(draftId, dispatch);
    await ActivityLogActions.getProductMessages(dispatch, draftId);
    toast.success('Zaakceptowano draft');
  }
};

// export const openDraftFile = async (dispatch: Dispatch<DraftFile>, draftId: number): Promise<void> => {
//   const response = await draftApi.openDraft(draftId);
//   if (response) {
//   }
// };

export const updateOpenDraftFile = (
  dispatch: Dispatch<DraftActionTypes>,
  draftId: number,
  openedBy: number[],
  fileId: number
) => {
  dispatch(internalActions.updateOpenedFile({ draftId, response: openedBy, fileId }));
};
