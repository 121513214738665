import React from 'react';
import { FieldCheckboxInterface } from 'interfaces';
import ButtonCheckbox from 'atoms/ButtonCheckbox';
import cx from 'classnames';

const FieldButtonCheckbox: React.FC<FieldCheckboxInterface> = ({ field, form, meta, label, ...props }) => {
  return (
    <ButtonCheckbox
      className={cx(
        {
          'button-checkbox--is-active': field.checked,
        },
        'button-checkbox'
      )}
      {...field}
      {...props}
    >
      {label}
    </ButtonCheckbox>
  );
};

export default FieldButtonCheckbox;
