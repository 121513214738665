export const handleFileSelect = (e: any, push: any): void => {
  const { files } = e.target;
  Array.from<File>(files).forEach((file: File): void => {
    push({
      file,
      filename: file.name,
      size: file.size,
      url: URL.createObjectURL(file),
    });
  });
};

export const handleImageSelect = (e: any, push: any): void => {
  const { files } = e.target;

  Array.from<File>(files).forEach((file: File): void => {
    push({
      file,
      url: URL.createObjectURL(file),
      filename: file.name,
    });
  });
};

export const downloadItem = (file: any): void => {
  const downloadUrl = file.url;
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', file.file ? file.file.name : 'file.pdf');
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadByURL = (url: string, filename: string): void => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename ? filename : 'file.pdf');
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  link.remove();
};
