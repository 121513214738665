import { ActivityLogState, ActivityLogActionTypes, actionTypes } from './actions';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';

const initialState: ActivityLogState = {
  messages: [],
};

const activityLogReducer = createReducer<ActivityLogState, ActivityLogActionTypes>(initialState)
  .handleType(actionTypes.SAVE_MESSAGE, (state, action) =>
    produce(state, (draftState) => {
      draftState.messages.push(action.payload);
    })
  )
  .handleType(actionTypes.SAVE_MESSAGES, (state, action) =>
    produce(state, (draftState) => {
      draftState.messages = action.payload;
    })
  );

export default activityLogReducer;
