import { PhotoBriefPhotoFormModel, PhotoBriefPhotoRequest } from 'interfaces/API/photoBrief';

export const isProperPhoto = (
  maybeProperPhoto: PhotoBriefPhotoFormModel
): maybeProperPhoto is PhotoBriefPhotoFormModel & { file: File } => {
  return typeof maybeProperPhoto['file'] !== 'undefined' && maybeProperPhoto['file'] instanceof File;
};

export const castToPhotoRequest = ({ file }: PhotoBriefPhotoFormModel & { file: File }): PhotoBriefPhotoRequest => {
  return { file };
};
