import { createAction, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';
import printingFilesApi from 'API/printingFiles';
import {
  DesignStageResponse,
  DesignStageStore,
  DesignStageFileRequest,
  DesignStageFileResponse,
} from 'interfaces/API/draft';
import { updateProductOnSuccess, ProductActionTypes } from 'modules/products/actions';
import ActivityLogActions, { ActivityLogActionTypes } from 'modules/activityLog/actions';
import { toast } from 'react-toastify';
import { DraftActionTypes } from 'modules/draft/actions';

export type DraftState = {
  printingFilesList: DesignStageStore[];
};

export enum actionTypes {
  ADD_PRINTING_FILE = 'printingFiles/ADD_PRINTING_FILES',
  ADD_PRINTING_FILES_FILE = 'printingFiles/ADD_PRINTING_FILES_FILE',
  UPDATE_PRINTING_FILE = 'printingFiles/UPDATE_PRINTING_FILES',
  UPDATE_PRINTING_FILE_OPENED_BY = 'printingFiles/UPDATE_PRINTING_FILE_OPENED_BY',
}

const externalActions = {};

const internalActions = {
  addPrintingFile: createAction(actionTypes.ADD_PRINTING_FILE)<{
    printingFilesId: number;
    response: DesignStageResponse;
  }>(),
  addPrintingFilesFile: createAction(actionTypes.ADD_PRINTING_FILES_FILE)<{
    printingFilesId: number;
    response: DesignStageFileResponse;
  }>(),
  updatePrintingFile: createAction(actionTypes.UPDATE_PRINTING_FILE)<{
    printingFilesId: number;
    response: DesignStageResponse;
  }>(),
  updateOpenPrintingFile: createAction(actionTypes.UPDATE_PRINTING_FILE_OPENED_BY)<{
    printingFilesId: number;
    response: number[];
    fileId: number;
  }>(),
};

export type PrintingFilesActionTypes = ActionType<typeof internalActions | typeof externalActions>;

type DispatchTypes = ActivityLogActionTypes | PrintingFilesActionTypes | ProductActionTypes;

export const getPrintingFileById = async (
  printingFilesId: number,
  dispatch: Dispatch<DispatchTypes>
): Promise<boolean> => {
  const response = await printingFilesApi.getPrintingFileById(printingFilesId);
  if (response) {
    dispatch(internalActions.addPrintingFile({ printingFilesId, response }));
    return true;
  }
  return false;
};

export const addPrintingFilesFile = async (
  dispatch: Dispatch<DispatchTypes>,
  printingFilesId: number,
  data: DesignStageFileRequest
): Promise<void> => {
  const response = await printingFilesApi.addPrintingFilesFile(printingFilesId, data);
  if (response) {
    dispatch(internalActions.addPrintingFilesFile({ printingFilesId, response }));
    const firstFinalResponse = await printingFilesApi.getPrintingFileById(printingFilesId);
    if (firstFinalResponse) {
      dispatch(internalActions.updatePrintingFile({ printingFilesId, response: firstFinalResponse }));
      await ActivityLogActions.getProductMessages(dispatch, printingFilesId);
    }
    await updateProductOnSuccess(printingFilesId, dispatch);
    toast.success('Dodano PF');
  }
};

export const acceptPrintingFilesFile = async (
  dispatch: Dispatch<DispatchTypes>,
  printingFilesId: number
): Promise<void> => {
  const response = await printingFilesApi.acceptPrintingFilesFile(printingFilesId);
  if (response) {
    dispatch(internalActions.updatePrintingFile({ printingFilesId, response }));
    await updateProductOnSuccess(printingFilesId, dispatch);
    await ActivityLogActions.getProductMessages(dispatch, printingFilesId);
    toast.success('Zaakceptowano PF');
  }
};

export const updateOpenPrintingFile = (
  dispatch: Dispatch<DispatchTypes>,
  printingFileId: number,
  openedBy: number[],
  fileId: number
) => {
  dispatch(internalActions.updateOpenPrintingFile({ printingFilesId: printingFileId, response: openedBy, fileId }));
};
