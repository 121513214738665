import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useNumberParams from './useNumberParams';
import photoBriefActions from 'modules/photoBrief/actions';
import photoBriefSelectors from 'modules/photoBrief/selectors';
import { PhotoBriefStore } from 'interfaces/API/photoBrief';
import useRequestState, { RequestState } from './useRequestState';

type CurrentPhotoBrief = [RequestState, PhotoBriefStore | null, () => void];

const useCurrentPhotoBrief = (): CurrentPhotoBrief => {
  const { productId } = useNumberParams();
  const dispatch = useDispatch();
  const [status, setError, setLoading, setDefault] = useRequestState();
  const photoBrief = useSelector(photoBriefSelectors.photoBriefById(productId));
  const [retry, setRetry] = useState(0);

  const handleRetry = (): void => {
    setDefault();
    setRetry(retry + 1);
  };

  useEffect(() => {
    if (!photoBrief && status === RequestState.DEFAULT) {
      setLoading();
      (async (): Promise<void> => {
        const response = await photoBriefActions.getPhotoBriefById(productId, dispatch);
        if (!response) {
          setError();
          return;
        }
        setDefault();
      })();
    }
  }, [dispatch, photoBrief, productId, setLoading, setDefault, setError, status]);

  return [status, photoBrief ? photoBrief : null, handleRetry];
};
export default useCurrentPhotoBrief;
