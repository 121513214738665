import React from 'react';
import ActivityLog from './ActivityLog';
import ProjectMembers from './ProjectMembers';

const RightSideBar: React.FC = (props) => {
  return (
    <>
      <ActivityLog />
      <ProjectMembers />
    </>
  );
};

export default RightSideBar;
