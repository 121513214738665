import { useParams } from 'react-router';
import { useMemo } from 'react';

type ResultType = {
  projectId: number;
  productId: number;
};

const useNumberParams = (): ResultType => {
  const { id, pid } = useParams<{ id: string; pid: string }>();

  return useMemo(() => ({ projectId: +id, productId: +pid }), [id, pid]);
};
export default useNumberParams;
