import React from 'react';

interface DropdownItemProps {
  text: string;
  icon: JSX.Element;
  logout?: any;
}

const DropdownItem: React.FC<DropdownItemProps> = ({ text, icon, logout = () => {} }) => {
  return (
    <a className="navbar-item" onClick={() => logout()}>
      {icon}
      <span className="ml-1">{text}</span>
    </a>
  );
};

export default DropdownItem;
