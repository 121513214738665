import React from 'react';

type ProjectMemberProps = {
  email: string;
  phoneNumber: string;
  jobTitle: string;
  fullName: string;
  photo: string | null;
  organizationName: string;
};

const ProjectMemberItem: React.FC<ProjectMemberProps> = ({
  email,
  phoneNumber,
  organizationName,
  jobTitle,
  fullName,
  photo,
}) => {
  return (
    <div className="row columns project-member-item">
      <div className="column is-narrow project-member-item__image">{photo && <img src={photo} />}</div>
      <div className="column">
        <div className="rows ">
          <div className="row columns">
            <div className="column">
              <span className="project-member-item__full-name">{fullName}</span>
            </div>
            <div className="column">
              <span className="project-member-item__phoneNumber">{phoneNumber}</span>
            </div>
          </div>
          <div className="row columns">
            <div className="column">
              <span className="project-member-item__jobTitle">
                {jobTitle} @ {organizationName}
              </span>
            </div>
            <div className="column">
              <span className="project-member-item__email">{email}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProjectMemberItem;
