import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';

const CustomLightbox: React.FC<any> = ({ mainSrc, length, toggle, initialInternalIndex }) => {
  const [internalIndex, setInternalIndex] = useState(initialInternalIndex || 0);

  return (
    <Lightbox
      mainSrc={mainSrc[internalIndex].url}
      nextSrc={mainSrc[(internalIndex + 1) % length].url}
      prevSrc={mainSrc[(internalIndex + length - 1) % length].url}
      onMoveNextRequest={(): void => setInternalIndex((internalIndex + 1) % length)}
      onMovePrevRequest={(): void => setInternalIndex((internalIndex + length - 1) % length)}
      onCloseRequest={(): void => toggle(false)}
      imageTitle={mainSrc[internalIndex].filename || ''}
    />
  );
};
export default CustomLightbox;
