import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentStage } from 'modules/products/actions';

const useCurrentStage = (value: number): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentStage(value));
  }, [dispatch, value]);
};

export default useCurrentStage;
