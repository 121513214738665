import { AppState } from 'modules/rootReducer';
import { createSelector, Selector } from 'reselect';
import { PhotoBriefStore, PhotoBriefStage, PhotoBriefCategoryStore } from 'interfaces/API/photoBrief';

//TODO: CREATESELECTORS

const photoBriefs = (state: AppState): PhotoBriefStore[] => state.photoBrief.photoBriefList;

const photoBriefById = (photoBriefId: number): Selector<AppState, PhotoBriefStore | undefined> =>
  createSelector(photoBriefs, (list) => list.find((item) => item.id === photoBriefId));

const stage = (photoBriefId: number): Selector<AppState, PhotoBriefStage | undefined> =>
  createSelector(photoBriefById(photoBriefId), (photobrief) => photobrief?.stage);

const categories = (state: AppState): PhotoBriefCategoryStore[] => state.photoBrief.photoBriefCategories;
// export const labelToStageSelector = (stage: string): Selector<AppState, ProductStageResponse | undefined> =>
//   createSelector(productStages, (list) => list.find((item) => item.id === stage));
export default {
  stage,
  categories,
  photoBriefs,
  photoBriefById,
};
