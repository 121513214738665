import { actionTypes, PhotoBriefState, FinalPhotoBriefActionTypes } from './actions';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';

const initialState: PhotoBriefState = {
  finalPhotoBriefList: [],
};

const photoBriefReducer = createReducer<PhotoBriefState, FinalPhotoBriefActionTypes>(initialState)
  .handleType(actionTypes.ADD_FINAL_PHOTO_BRIEF, (state, action) =>
    produce(state, (draftState) => {
      const photoBrief = { ...action.payload.response, id: action.payload.finalPhotoBriefId };
      draftState.finalPhotoBriefList.push(photoBrief);
    })
  )
  .handleType(actionTypes.UPDATE_FINAL_PHOTO_BRIEF, (state, action) =>
    produce(state, (draftState) => {
      const finalPhotoBrief = draftState.finalPhotoBriefList.find(
        (item) => item.id === action.payload.finalPhotoBriefId
      );
      if (finalPhotoBrief) {
        finalPhotoBrief.photos = action.payload.response.photos;
      }
    })
  )
  .handleType(actionTypes.REMOVE_FINAL_BRIEF_PHOTO, (state, action) =>
    produce(state, (draftState) =>
      produce(state, (draftState) => {
        const finalPhotoBrief = draftState.finalPhotoBriefList.find(
          (item) => item.id === action.payload.finalPhotoBriefId
        );
        if (finalPhotoBrief) {
          const index = finalPhotoBrief.photos.findIndex((item) => item.url === action.payload.url);
          if (index > -1) {
            finalPhotoBrief.photos.splice(index, 1);
          }
        }
      })
    )
  );

export default photoBriefReducer;
