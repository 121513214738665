import React from 'react';
import { withFormik, Field, Form, FormikProps } from 'formik';
import FieldTextArea from 'molecules/fields/FieldTextArea';
import FieldTextInput from 'molecules/fields/FieldTextInput';
import { ImagePickerWithPreviews } from 'molecules/ImagePickerWithPreviews';
import cx from 'classnames';
import photoBriefActions from 'modules/photoBrief/actions';
import * as yup from 'yup';
import { Dispatch } from 'redux';
import { PhotoBriefCategoryEnum, PhotoBriefPhotoFormModel } from 'interfaces/API/photoBrief';
import { FinalPhotoBriefPhotoType } from 'interfaces/API/finalPhotoBrief';

type ManagementProps = {
  photoBriefId: number;
  dispatch: Dispatch;
  category: PhotoBriefCategoryEnum;
  toggleModal: () => void;
};

type AddPhotoBriefValues = {
  title: string;
  description: string;
  photos: PhotoBriefPhotoFormModel[];
};

const AddPhotoBriefItemSchema = yup.object().shape({
  photos: yup.mixed().required('Plik jest wymagany.'),
  description: yup.string().required(),
  title: yup.string().required(),
});

const AddPhotoBriefItem: React.FC<FormikProps<AddPhotoBriefValues>> = (props) => {
  return (
    <Form className="modal-content rows">
      <div className="row">
        <Field name="title" component={FieldTextInput} placeholder="Dodaj tytuł" className="brief__input" />
      </div>
      <div className="row">
        <Field name="description" component={FieldTextArea} placeholder="Dodaj opis" className="brief__input" />
      </div>
      <div className="row">
        <ImagePickerWithPreviews name="photos" />
      </div>
      <div className="row">
        <button
          disabled={props.isSubmitting}
          className={cx('button is-orange-lighten', { 'is-loading': props.isSubmitting })}
        >
          Zapisz
        </button>
      </div>
    </Form>
  );
};
export default withFormik<ManagementProps, AddPhotoBriefValues>({
  enableReinitialize: true,
  validationSchema: AddPhotoBriefItemSchema,
  mapPropsToValues: () => {
    return { title: '', description: '', photos: [] };
  },
  handleSubmit: async (values, { props: { photoBriefId, dispatch, category, toggleModal } }) => {
    await photoBriefActions.addPhotoBriefItem(values, category, photoBriefId, dispatch);
    toggleModal();
  },
})(AddPhotoBriefItem);
