import { SimpleProject, Project, AnyProject } from 'interfaces/API/projects';

const isSimpleProject = (x: AnyProject): x is SimpleProject => {
  return typeof (x as Project).productsAmount === 'undefined';
};

const isFullProject = (x: AnyProject): x is Project => {
  return typeof (x as Project).productsAmount !== 'undefined';
};

export { isSimpleProject, isFullProject };
