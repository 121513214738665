import React from 'react';
import { VerticalLine, DoneIcon, CommentIcon } from 'assets';
import { formatDistanceToNow } from 'date-fns';
import pl from 'date-fns/locale/pl';
//FAILED TEST INVESTIGATE
// import { pl } from 'date-fns/esm/locale';
import { MessageActivityLogItem, MessageType } from 'API/messages';
import { useSelector } from 'react-redux';
import userSelectors from 'modules/users/selectors';
import { AppState } from 'modules/rootReducer';
import { StageStamp } from 'molecules/StageStamp';
import { labelToStageSelector } from 'modules/data/selectors';
import { GroupStageStamp } from './StageStamp';

type MessageProps = {
  message: MessageActivityLogItem;
};

const Message: React.FC<MessageProps> = ({ message }) => {
  const fullName = useSelector((state: AppState) => userSelectors.getUserFullNameById(message.createdBy)(state));
  const stage = useSelector((state: AppState) => labelToStageSelector(message.stage)(state));

  return (
    <div key={message.createdAt.toLocaleString()} className="activity-log__item ">
      <div className="columns is-marginless">
        <div className="column is-narrow has-items-centered">
          {message.messageType === MessageType.SYSTEM_SUCCESS ? <DoneIcon /> : <CommentIcon />}
        </div>
        <div className="rows column">
          <div className="row columns is-marginless">
            <div className="column is-8">
              <span className="activity-log__author">{fullName}</span>
            </div>
            <div className="column is-4">
              <span className="activity-log__date">
                {formatDistanceToNow(new Date(message.createdAt), { addSuffix: true, locale: pl })}
              </span>
            </div>
          </div>

          <div className="row columns is-marginless has-items-centered">
            <div className="column is-narrow">
              {stage && <GroupStageStamp group={stage.group} label={stage.label} />}
            </div>
            <div className="column">
              <span className="activity-log__description">{message.message} </span>
            </div>
          </div>
        </div>
      </div>

      <div className="columns is-marginless" style={{ justifyContent: 'center' }}>
        <div className="column is-narrow has-items-centered">
          <VerticalLine />
        </div>
      </div>
    </div>
  );
};

export default Message;
