import { createAction, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { BriefStore, UpdateBriefForm, BriefResponse } from 'interfaces/API/products';
import briefApi from 'API/briefs';
import { filterPhotos, filterBlankings } from 'helpers/briefFileHelpers';
import { toast } from 'react-toastify';
import ActivityLogActions, { ActivityLogActionTypes } from 'modules/activityLog/actions';
import { ProductActionTypes, updateProductOnSuccess } from 'modules/products/actions';
import actionTypes from './actionTypes';
export interface BriefState {
  briefs: BriefStore[];
  redirectTo: string | null;
}

export const updateBrief = createAction(actionTypes.UPDATE_BRIEF)<{ briefId: number; response: BriefResponse }>();

export const redirectTo = createAction(actionTypes.REDIRECT_TO)<string | null>();

const externalActions = {
  updateBrief,
  redirectTo,
};

const internalActions = {
  saveBrief: createAction(actionTypes.SAVE_BRIEF)<{ briefId: number; response: BriefResponse }>(),
  deleteBlanking: createAction(actionTypes.DELETE_BRIEF_BLANKING)<{ briefId: number; blankingId: number }>(),
  deletePhoto: createAction(actionTypes.DELETE_BRIEF_PHOTO)<{ briefId: number; photoId: number }>(),
};

export type BriefActionTypes = ActionType<typeof internalActions | typeof externalActions>;

type ModifyProductActionTypes = BriefActionTypes | ActivityLogActionTypes | ProductActionTypes;

const getBriefById = async (dispatch: Dispatch<BriefActionTypes>, briefId: number): Promise<boolean> => {
  const response = await briefApi.getBriefById(briefId);
  if (response !== null) {
    dispatch(internalActions.saveBrief({ briefId, response }));
    return true;
  }
  return false;
};

const patchBriefAction = async (
  dispatch: Dispatch<ModifyProductActionTypes>,
  briefId: number,
  { photos, blankings, ...brief }: UpdateBriefForm
): Promise<void> => {
  const filteredPhotos = filterPhotos(photos);
  const filteredBlankings = filterBlankings(blankings);
  const response = await briefApi.patchBriefById(briefId, {
    photos: filteredPhotos,
    blankings: filteredBlankings,
    ...brief,
  });
  if (response !== null) {
    dispatch(updateBrief({ briefId, response }));
    await updateProductOnSuccess(briefId, dispatch);
    toast.success('Zapisano produkt.');
  }
};

const acceptBriefAction = async (dispatch: Dispatch<ModifyProductActionTypes>, briefId: number): Promise<void> => {
  const response = await briefApi.acceptBrief(briefId);
  if (response) {
    dispatch(updateBrief({ briefId, response }));
    await updateProductOnSuccess(briefId, dispatch);
    await ActivityLogActions.getProductMessages(dispatch, briefId);
    toast.success('Zaakceptowano produkt.');
  }
};

const rejectBriefAction = async (dispatch: Dispatch<ModifyProductActionTypes>, briefId: number): Promise<void> => {
  const response = await briefApi.rejectBrief(briefId);
  if (response) {
    dispatch(updateBrief({ briefId, response }));
    await ActivityLogActions.getProductMessages(dispatch, briefId);
    await updateProductOnSuccess(briefId, dispatch);
    await ActivityLogActions.getProductMessages(dispatch, briefId);
    toast.success('Odrzucono produkt.');
  }
};

const publishBriefAction = async (dispatch: Dispatch<ModifyProductActionTypes>, briefId: number): Promise<void> => {
  const response = await briefApi.publishBrief(briefId);
  if (response) {
    dispatch(updateBrief({ briefId, response }));
    await updateProductOnSuccess(briefId, dispatch);
    await ActivityLogActions.getProductMessages(dispatch, briefId);
    toast.success('Opublikowano produkt.');
  }
};

const deleteBlanking = async (
  dispatch: Dispatch<BriefActionTypes>,
  blankingId: number,
  briefId: number
): Promise<void> => {
  const response = await briefApi.deleteBlanking(blankingId);
  if (response === 200 || response === 204) {
    dispatch(internalActions.deleteBlanking({ briefId, blankingId }));
  }
};

const deletePhoto = async (dispatch: Dispatch<BriefActionTypes>, photoId: number, briefId: number): Promise<void> => {
  const response = await briefApi.deletePhoto(photoId);
  if (response === 200 || response === 204) {
    dispatch(internalActions.deletePhoto({ briefId, photoId }));
  }
};
const cloneBrief = async (dispatch: Dispatch<BriefActionTypes>, briefId: number, cloneFrom: number): Promise<void> => {
  const response = await briefApi.cloneBrief(cloneFrom, briefId);
  if (response) {
    dispatch(updateBrief({ briefId, response }));
  }
};

export default {
  cloneBrief,
  deleteBlanking,
  deletePhoto,
  getBriefById,
  patchBriefAction,
  acceptBriefAction,
  rejectBriefAction,
  publishBriefAction,
};
