import { WithId } from 'helpers/typesHelpers';

export type PhotoBriefPhotoRequest = {
  file: File;
};

type TimeStamps = {
  createdAt: Date;
  updatedAt: Date;
};

export type PhotoBriefPhotoResponse = TimeStamps & {
  url: string;
  filename: string;
};

type PhotoBriefItem = {
  category: string;
  title: string;
  description: string;
};

export type PhotoBriefPhotoFormModel = {
  file?: File;
  url: string;
  filename: string;
};

export type PhotoBriefItemFormModel = Omit<PhotoBriefItem, 'category'> & {
  photos: PhotoBriefPhotoFormModel[];
};

export type PhotoBriefCategoryFormModel = {
  name: PhotoBriefCategoryEnum;
  description: string;
  items: Omit<PhotoBriefItemResponse, 'category'>[];
};

export type PhotoBriefItemResponse = WithId<PhotoBriefItem> &
  TimeStamps & {
    photos: PhotoBriefPhotoResponse[];
  };

export type PhotoBriefChoiceResponse = {
  category: string;
  item: number;
  role: string;
};

export type PhotoBriefChoiceRequest = {
  category: string;
  item: number;
};

export type PhotoBriefItemRequest = PhotoBriefItem & {
  photos: PhotoBriefPhotoRequest[];
};

export type PhotoBriefRequest = {
  sessionDate: Date;
  contentDescription: string;
  items: PhotoBriefItemRequest[];
};

export enum PhotoBriefStage {
  PUBLISHED = 'published',
  CREATED = 'created',
  COMPLETED = 'completed',
}

export type PhotoBriefResponse = TimeStamps & {
  editable: boolean;
  acceptable: boolean;
  publishable: boolean;
  completable: boolean;
  sessionDate: Date;
  conceptDescription: string;
  stage: PhotoBriefStage;
  items: PhotoBriefItemResponse[];
  choices: PhotoBriefChoiceResponse[];
};

export type PhotoBriefCategoryResponse = {
  label: PhotoBriefCategoryEnum;
  description: string;
};
export type PhotoBriefCategoryStore = PhotoBriefCategoryResponse;

export enum PhotoBriefCategoryEnum {
  MODELS = 'models',
  STYLING = 'styling',
  SCENERY = 'scenery',
}

export type PhotoBriefStore = WithId<PhotoBriefResponse>;
