import { AppState } from 'modules/rootReducer';
import { UserDataResponse, UserListItem } from 'interfaces/API/userAuthorization';
import { Selector, createSelector } from 'reselect';

const currentUserPhotoSelector = (state: AppState): string | null => {
  if (!state.users.currentUser) {
    return null;
  }
  return state.users.currentUser.photo;
};

const currentUserRole = (state: AppState): string | null => {
  const user = state.users.currentUser;

  if (user && user.organization) {
    return user.organization.type;
  }

  return null;
};

const getUserFullNameById = (userId: number) => (state: AppState): string | null => {
  const user = state.users.usersList.find((user) => user.id === userId);
  if (user) {
    return user.fullName;
  }
  return null;
};

const users = (state: AppState): UserListItem[] => state.users.usersList;

const userById = (userId: number): Selector<AppState, UserListItem | undefined> =>
  createSelector(users, (list) => list.find((item) => item.id === userId));

const isCurrentUserObserver = (state: AppState): boolean => {
  return state.users.currentUser?.isObserver || false;
};

const userId = (state: AppState): number | null => {
  const userId = state.users.currentUser && state.users.currentUser.id;
  if (userId) {
    return userId;
  }
  return null;
};

export default {
  userId,
  userById,
  currentUserPhotoSelector,
  currentUserRole,
  getUserFullNameById,
  isCurrentUserObserver,
};
