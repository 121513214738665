import React, { useContext, useState } from 'react';
import cx from 'classnames';
import Modal from 'molecules/modals/Modal';
import useToggler from 'hooks/useToggler';
import AddPhotoBriefItem from './AddPhotoBriefItem';
import { useDispatch, useSelector } from 'react-redux';
import useNumberParams from 'hooks/useNumberParams';
import {
  PhotoBriefCategoryEnum,
  PhotoBriefPhotoFormModel,
  PhotoBriefPhotoResponse,
  PhotoBriefStage,
} from 'interfaces/API/photoBrief';
import HorizontalLine from 'atoms/HorizontalLine';
import Checkbox from 'atoms/Checkbox';
import { polishPlurals } from 'polish-plurals';
import CustomLightbox from 'molecules/CustomLightbox';
import { AppState } from 'modules/rootReducer';
import userSelectors from 'modules/users/selectors';
import photoBriefActions from 'modules/photoBrief/actions';
import { Delete } from 'assets';

type ContextValues = {
  categoryName: PhotoBriefCategoryEnum;
  description: string;
};

const PhotoBriefSectionContext = React.createContext<ContextValues>({
  categoryName: PhotoBriefCategoryEnum.MODELS,
  description: '',
});

const usePhotoBriefSectionContext = (): ContextValues => {
  const context = useContext(PhotoBriefSectionContext);
  if (!context) {
    throw new Error(`NOPE`);
  }
  return context;
};

type CompoundComponents = {
  Header: React.FC;
  Content: React.FC;
  Item: React.FC<PhotoBriefSectionItemProps>;
  Footer: React.FC;
};

type PhotoBriefSectionItemProps = {
  id: number;
  editable: boolean;
  photos: PhotoBriefPhotoResponse[];
  stage: PhotoBriefStage;
  description: string;
  title: string;
  isAcceptedByClient: boolean;
  isAcceptedBySupplier: boolean;
};

const PhotoBriefSectionHeader: React.FC = () => {
  const { description } = usePhotoBriefSectionContext();
  return (
    <div className="row columns has-items-centered">
      <div className="column is-narrow ">
        <span className="bolder">{description}:</span>
      </div>
      <div className="column">
        <HorizontalLine />
      </div>
    </div>
  );
};

const PhotoBriefSectionContent: React.FC = ({ children }) => {
  return <div className="">{children}</div>;
};

const PhotoBriefSectionItem: React.FC<PhotoBriefSectionItemProps> = ({
  photos,
  title,
  description,
  id,
  isAcceptedByClient,
  isAcceptedBySupplier,
  stage,
  editable,
}) => {
  const { categoryName } = usePhotoBriefSectionContext();
  const { productId } = useNumberParams();
  const dispatch = useDispatch();
  const role = useSelector((state: AppState) => userSelectors.currentUserRole(state));
  const [lightboxOpen, toggleLightbox] = useToggler();
  const [deleteModalOpen, toggleDeleteModal] = useToggler();
  const [deleting, setDeleting] = useState(false);
  const handleCheckboxOnClick = async (): Promise<void> => {
    await photoBriefActions.acceptPhotoBrief({ category: categoryName, item: id }, productId, dispatch);
  };
  return (
    <>
      <div className="row columns">
        {stage !== PhotoBriefStage.CREATED && (
          <div className="column is-narrow has-items-centered">
            <Checkbox
              checked={isAcceptedByClient}
              onChange={handleCheckboxOnClick}
              disabled={['supplier', 'agency'].some((x): boolean => x === role)}
              name={`${categoryName}.${id}.client`}
            />
            <Checkbox
              checked={isAcceptedBySupplier}
              onChange={handleCheckboxOnClick}
              disabled={['agency', 'client'].some((x): boolean => x === role)}
              name={`${categoryName}.${id}.supplier`}
            />
          </div>
        )}
        <div className="column is-narrow">
          <div
            className="image__box image__box--big"
            onClick={(): void => {
              toggleLightbox();
            }}
          >
            <img src={photos.length > 0 ? photos[0].url : ''} />
            <div className="image__box--big-hoverable">
              <span>
                {photos.length} {polishPlurals('zdjęcie', 'zdjęcia', 'zdjęć', photos.length)}
              </span>
            </div>
          </div>
        </div>
        <div className="column ml-2">
          <div className="photo-file__title">{title}</div>
          <div className="photo-file__description">{description}</div>
        </div>
        <div className="column is-narrow has-items-centered ">{editable && <Delete onClick={toggleDeleteModal} />}</div>
      </div>
      {lightboxOpen ? <CustomLightbox mainSrc={photos} length={photos.length} toggle={toggleLightbox} /> : null}
      <Modal open={deleteModalOpen} setOpen={toggleDeleteModal}>
        <Modal.Root>
          <Modal.Header>
            <span>Usuń</span>
          </Modal.Header>
          <Modal.Content>
            Czy na pewno chcesz usunąć <span className="bolder">{title}</span> ?
          </Modal.Content>
          <Modal.Footer>
            <button
              className={cx('button is-orange-lighten mr-1', { 'is-loading': deleting })}
              disabled={deleting}
              type="button"
              onClick={async (): Promise<void> => {
                setDeleting(true);
                await photoBriefActions.deletePhotoBriefItem(productId, id, dispatch);
                setDeleting(false);
                toggleDeleteModal();
              }}
            >
              <span>Usuń</span>
            </button>
            <button type="button" className="button is-light-grey" onClick={toggleDeleteModal}>
              <span>Anuluj</span>
            </button>
          </Modal.Footer>
        </Modal.Root>
      </Modal>
    </>
  );
};

const PhotoBriefSectionFooter: React.FC = () => {
  const { categoryName, description } = usePhotoBriefSectionContext();
  const dispatch = useDispatch();
  const { productId } = useNumberParams();
  const [open, toggleOpen] = useToggler();
  return (
    <>
      <div className="row items-right">
        <button className={cx('button is-orange-lighten')} onClick={toggleOpen}>
          Dodaj
        </button>
      </div>
      <Modal open={open} setOpen={toggleOpen}>
        <Modal.Root>
          <Modal.Header>
            <span>{`Prześlij ${description}`}</span>
          </Modal.Header>
          <Modal.Content>
            <AddPhotoBriefItem
              photoBriefId={productId}
              dispatch={dispatch}
              category={categoryName}
              toggleModal={toggleOpen}
            />
          </Modal.Content>
        </Modal.Root>
      </Modal>
      {/* MODAL */}
    </>
  );
};

type PhotoBriefSectionProps = {
  categoryName: PhotoBriefCategoryEnum;
  description: string;
};

const PhotoBriefSection: React.FC<PhotoBriefSectionProps> & CompoundComponents = ({
  children,
  categoryName,
  description,
}) => {
  return (
    <PhotoBriefSectionContext.Provider
      value={{
        categoryName,
        description,
      }}
    >
      <div className="row rows">{children}</div>
    </PhotoBriefSectionContext.Provider>
  );
};

PhotoBriefSection.Header = PhotoBriefSectionHeader;
PhotoBriefSection.Content = PhotoBriefSectionContent;
PhotoBriefSection.Item = PhotoBriefSectionItem;
PhotoBriefSection.Footer = PhotoBriefSectionFooter;

export default PhotoBriefSection;
