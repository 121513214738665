import React, { useMemo } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router';
import useLocationParams from '../hooks/useLocationParams';

type StageBarLinkProps = {
  target: string;
  currentStage: number;
  group: number;
  index: number;
};

export const StageBarLink: React.FC<StageBarLinkProps> = ({ target, index, currentStage, group }) => {
  const disabled = useMemo(() => group < index, [group, index]);
  const active = useMemo(() => currentStage === index, [currentStage, index]);
  const history = useHistory();
  const [params, location] = useLocationParams();
  return (
    <div
      onClick={!disabled ? (): void => history.push({ pathname: target, search: params.toString() }) : (): void => {}}
      className={cx('stage-bar__box', {
        'stage-bar__box--active': active,
      })}
    >
      <span
        className={cx('stage-bar__box__item', {
          'stage-bar__box__item--active': active,
          'stage-bar__box__item--disabled': disabled,
        })}
      >
        {index}
      </span>
    </div>
  );
};
