import { actionTypes, PhotoBriefState, PhotoBriefActionTypes } from './actions';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';

const initialState: PhotoBriefState = {
  photoBriefList: [],
  photoBriefCategories: [],
};

const photoBriefReducer = createReducer<PhotoBriefState, PhotoBriefActionTypes>(initialState)
  .handleType(actionTypes.ADD_PHOTO_BRIEF, (state, action) =>
    produce(state, (draftState) => {
      const photoBrief = { ...action.payload.response, id: action.payload.photoBriefId };
      draftState.photoBriefList.push(photoBrief);
    })
  )
  .handleType(actionTypes.SET_CATEGORIES, (state, { payload: { response } }) =>
    produce(state, (drafState) => {
      drafState.photoBriefCategories = response;
    })
  )
  .handleType(actionTypes.ADD_PHOTO_BRIEF_ITEM, (state, { payload: { response, photoBriefId } }) =>
    produce(state, (draftState) => {
      const temp = draftState.photoBriefList.findIndex((item) => item.id === photoBriefId);
      //WIERD
      if (temp >= 0) {
        draftState.photoBriefList[temp].items.push(response);
      }
    })
  )
  .handleType(actionTypes.UPDATE_PHOTO_BRIEF, (state, { payload: { response, photoBriefId } }) =>
    produce(state, (draftState) => {
      const index = draftState.photoBriefList.findIndex((pb) => pb.id === photoBriefId);
      // TODO: nested produce? how?
      draftState.photoBriefList[index] = { ...draftState.photoBriefList[index], ...response };
    })
  )
  .handleType(actionTypes.DELETE_PHOTO_BRIEF_ITEM, (state, { payload: { itemId, photoBriefId } }) =>
    produce(state, (draftState) => {
      const photoBriefIndex = draftState.photoBriefList.findIndex((items) => items.id === photoBriefId);
      if (draftState.photoBriefList[photoBriefIndex]?.items) {
        const itemIndex = draftState.photoBriefList[photoBriefIndex].items.findIndex((item) => item.id === itemId);
        draftState.photoBriefList[photoBriefIndex].items.splice(itemIndex, 1);
      }
    })
  );

export default photoBriefReducer;
