import React, { InputHTMLAttributes } from 'react';
import cx from 'classnames';

const ButtonCheckbox: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({ children, className, ...props }) => (
  <div className="button-checkbox">
    <input type="checkbox" id={props.name} {...props} />
    <label
      htmlFor={props.id || props.name}
      className={cx([
        'button is-light-grey',
        'button-checkbox',
        { 'button-checkbox--is-active': props.checked },
        className,
      ])}
    >
      <span>{children}</span>
    </label>
  </div>
);

export default ButtonCheckbox;
