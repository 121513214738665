import React from 'react';
import StepBar from '../molecules/StepBar';
import StageBar from '../molecules/StageBar';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router';
import projectSelectors from 'modules/projects/selectors';
import briefSelectors from 'modules/brief/selectors';
import StoreProvider from 'components/StoreProvider';
import { BriefActionTypes } from 'modules/brief/actions';
import { Dispatch } from 'redux';
import { ActivityLogActionTypes } from 'modules/activityLog/actions';
import DraftList from './DraftList';
import FirstFinalList from './FirstFinalList';
import PrintingFilesList from './PrintingFilesList';
import { ProductActionTypes } from 'modules/products/actions';
import ProjectBrief from './ProjectBrief/ProjectBrief';
import ProductBrief from './ProductBrief';
import PhotoBrief from './PhotoBrief/PhotoBrief';
import { ProjectActionTypes } from 'modules/projects/actions';
import { FinalPhotoBrief } from './FinalPhotoBrief/FinalPhotoBrief';
import briefs from 'API/briefs';
// https://forbeslindesay.github.io/express-route-tester/
const Dashboard: React.FC = (props) => {
  const dispatch = useDispatch<
    Dispatch<ProjectActionTypes | BriefActionTypes | ActivityLogActionTypes | ProductActionTypes>
  >();

  return (
    <div className="brief-container">
      <Route
        component={StepBar}
        exact
        path={[
          '/projects/:id',
          '/projects/:id/products/:pid',
          '/projects/:id/products/:pid/draft',
          '/projects/:id/products/:pid/ff',
          '/projects/:id/products/:pid/pf',
          '/projects/:id/products/:pid/photobrief',
          '/projects/:id/products/:pid/finalphotobrief',
        ]}
      />

      <div className="brief">
        <Route
          component={StageBar}
          exact
          path={[
            '/projects/new',
            '/projects/:id',
            '/projects/:id/products/:pid',
            '/projects/:id/products/:pid/draft',
            '/projects/:id/products/:pid/ff',
            '/projects/:id/products/:pid/pf',
            '/projects/:id/products/:pid/photobrief',
            '/projects/:id/products/:pid/finalphotobrief',
          ]}
        />

        <div className="brief__form">
          <Switch>
            <Route
              component={(props: any) => {
                const { id } = props.match.params;
                return (
                  <StoreProvider
                    selectors={{
                      currentProject: projectSelectors.projectById(Number(id)),
                    }}
                    component={(componentProps) => {
                      return <ProjectBrief {...props} {...componentProps} dispatch={dispatch} />;
                    }}
                  />
                );
              }}
              exact
              path={['/projects/new', '/projects/:id']}
            />

            <Route
              component={(props: any) => {
                const { pid } = props.match.params;
                return (
                  <StoreProvider
                    selectors={{
                      currentBrief: briefSelectors.briefById(Number(pid)),
                    }}
                    component={(componentProps) => {
                      return <ProductBrief {...props} {...componentProps} dispatch={dispatch} />;
                    }}
                  />
                );
              }}
              exact
              path="/projects/:id/products/:pid"
            />
            <Route exact path="/projects/:id/products/:pid/draft">
              <DraftList />
            </Route>
            <Route exact path="/projects/:id/products/:pid/ff">
              <FirstFinalList />
            </Route>
            <Route exact path="/projects/:id/products/:pid/pf">
              <PrintingFilesList />
            </Route>
            <Route exact path="/projects/:id/products/:pid/photobrief">
              <PhotoBrief />
            </Route>
            <Route exact path="/projects/:id/products/:pid/finalphotobrief">
              <FinalPhotoBrief />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
