import React, { useCallback, useMemo } from 'react';
import { withFormik, FormikProps, Form, Field, ErrorMessage } from 'formik';
import Datepicker, { DatePickerChangeHandler } from 'atoms/Datepicker';
import projectActions from 'modules/projects/actions';
import { format } from 'date-fns';
import { RouteComponentProps } from 'react-router';
import cx from 'classnames';
import BriefHeader from 'atoms/BriefHeader';
import HorizontalLine from 'atoms/HorizontalLine';
import FieldTextInput from 'molecules/fields/FieldTextInput';
import FieldTextArea from 'molecules/fields/FieldTextArea';
import Modal from 'molecules/modals/Modal';
import Select from 'atoms/Select';
import DropdownField from 'molecules/fields/DropdownField';
import FieldInputError from 'atoms/FieldInputError';
import { ProjectResponse } from 'interfaces/API/projects';
import useToggler from 'hooks/useToggler';
import { useSelector } from 'react-redux';
import userSelectors from 'modules/users/selectors';
import { categoriesSelector, brandsSelector, labelToStageSelector } from 'modules/data/selectors';
import useCurrentProject from 'hooks/useCurrentProject';
import { AppState } from 'modules/rootReducer';
import { isFullProject } from 'helpers/isSimpleProject';
import { ProjectBriefManagement, ProjectBriefFormValues } from './ProjectBrief.interfaces';
import ProjectBriefSchema from './ProjectBrief.schema';
import useCurrentStage from 'hooks/useCurrentStage';
import { RequestState } from 'hooks/useRequestState';
import { ClimbingLoader } from 'atoms/ClimbingLoader';
import RetryButton from 'molecules/RetryButton';
import useNumberParams from 'hooks/useNumberParams';

type DatePickerChangeHandlerCreator = (name: keyof ProjectBriefFormValues) => DatePickerChangeHandler;

const ProjectBriefForm: React.FC<ProjectBriefManagement & FormikProps<ProjectBriefFormValues>> = ({
  dispatch,
  ...props
}) => {
  const [open, toggleModal] = useToggler(false);
  const [status, currentProject, retry] = useCurrentProject();
  const [userRole, brands, categories] = useSelector((state: AppState) => [
    userSelectors.currentUserRole(state),
    brandsSelector(state),
    categoriesSelector(state),
  ]);

  const handleRetry = (): void => {
    retry();
  };

  const isEditable = useMemo(() => (currentProject ? false : true), [currentProject]);
  const publishable = useMemo(() => (currentProject ? currentProject.publishable : false), [currentProject]);
  const isPublished = useMemo(() => (currentProject ? currentProject.published : false), [currentProject]);

  useCurrentStage(0);

  const numberArray = Array.from(Array(20), (x, i) => i);
  const params = useNumberParams();
  const onChange: DatePickerChangeHandlerCreator = useCallback(
    (name) => {
      const onChangeInternal: DatePickerChangeHandler = (value) => {
        if (value !== null) {
          props.setFieldValue(name, format(value, 'yyyy-MM-dd'));
        } else {
          props.setFieldValue(name, null);
        }
      };
      return onChangeInternal;
    },
    [props]
  );
  return status === RequestState.IS_LOADING ? (
    <ClimbingLoader />
  ) : status === RequestState.HAS_ERROR ? (
    <div className="retry-dashboard">
      <RetryButton onRetry={handleRetry} />
    </div>
  ) : (
    <Form className="form" data-testid="projectBrief">
      <div className="rows">
        <div className="row columns has-items-centered">
          <div className="column is-narrow">
            <BriefHeader>Informacje o projekcie {currentProject ? currentProject.slug : ''}</BriefHeader>
          </div>
          <div className="column">
            <HorizontalLine />
          </div>
          <div className="column is-narrow">
            {isEditable && (
              <button
                data-testid="projectSaveButton"
                type="submit"
                className={cx('button is-orange-lighten', { 'is-loading': props.isSubmitting })}
                disabled={props.isSubmitting}
              >
                <span>Zapisz</span>
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="field-section">
        <div className="rows">
          <div className="row columns">
            <div className="column is-8">
              <Field
                name="name"
                component={FieldTextInput}
                type="text"
                placeholder="Nazwa projektu"
                className="brief__input"
                disabled={!isEditable}
                data-testid="projectName"
              />
              <ErrorMessage name="name" component={FieldInputError} />
            </div>
            <div className="column is-4">
              <Field
                component={Select}
                name="productsAmount"
                disabled={!isEditable}
                dataTestid="productsAmount"
                title={'Ilość produktów'}
              >
                {numberArray.map((el) => (
                  <option value={Number(el)} key={el}>
                    {el}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="productsAmount" component={FieldInputError} />
            </div>
          </div>

          <div className="row rows">
            <div className="row">
              <Field
                name="description"
                component={FieldTextArea}
                placeholder="Opis projektu"
                rows="3"
                className="brief__input"
                disabled={!isEditable}
                data-testid="description"
              />
            </div>
            <div className="row">
              <ErrorMessage name="description" component={FieldInputError} />
            </div>
          </div>
          {/* //KATEGORIE */}
          <div className="row columns">
            <div className="column mr-1 is-3">
              <Field
                placeholder="Kategoria"
                fullwidth={true}
                items={categories}
                name="category"
                component={DropdownField}
                disabled={!isEditable}
                dataTestid="categoryDropdown"
                valueDataTestid="categoryValue"
              />
              <ErrorMessage name="category" component={FieldInputError} />
            </div>
            <div className="column ml-1 is-3">
              <Field
                placeholder="Marka"
                fullwidth={true}
                items={brands}
                name="brand"
                component={DropdownField}
                disabled={!isEditable}
                dataTestid="brandDropdown"
                valueDataTestid="brandValue"
              />
              <ErrorMessage name="brand" component={FieldInputError} />
            </div>
            <div className="rows column">
              <div className="row columns mb-1">
                <div className="column is-7 mr-1 has-items-right">
                  <span>Dostarczenie Printing Files:</span>
                </div>
                <div className="column is-5 has-items-right">
                  <Field
                    type="date"
                    component={Datepicker}
                    name="printingFilesDate"
                    onChange={onChange('printingFilesDate')}
                    selected={currentProject?.printingFilesDate || props.values.printingFilesDate}
                    disabled={!isEditable}
                    dataTestid="printingFilesDatePicker"
                    title="Dostarczenie Printing Files"
                  />
                  <ErrorMessage name="printingFilesDate" component={FieldInputError} />
                </div>
              </div>

              <div className="row columns">
                <div className="column is-7 mr-1 has-items-right">
                  <span>Data promocji:</span>
                </div>
                <div className="column is-5  has-items-right">
                  <Field
                    type="date"
                    name="poromoDateDatePicker"
                    component={Datepicker}
                    onChange={onChange('promoDate')}
                    selected={props.values.promoDate}
                    disabled={!isEditable}
                    dataTestid="poromoDateDatePicker"
                    title="Data promocji"
                  />
                  <ErrorMessage name="promoDate" component={FieldInputError} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {!isPublished && currentProject && userRole === 'supplier' && (
          <div className="columns is-marginless">
            <div className="column has-items-centered">
              <button
                className={cx('button is-orange-lighten', { 'button-disabled': !publishable })}
                disabled={!publishable}
                type="button"
                onClick={toggleModal}
                data-testid="publishButton"
              >
                <span>Opublikuj</span>
              </button>
            </div>
            <Modal open={open} setOpen={toggleModal}>
              <Modal.Root>
                <Modal.Header>Publikacja projektu</Modal.Header>
                <Modal.Content>
                  <span>Czy chcesz opublikować projekt</span>
                </Modal.Content>
                <Modal.Footer>
                  <button
                    className={cx('button is-orange-lighten mr-1', { 'is-loading': props.isSubmitting })}
                    disabled={props.isSubmitting}
                    type="button"
                    data-testid="modalSubmit"
                    onClick={async (): Promise<void> => {
                      props.setSubmitting(true);
                      await projectActions.publishProjectAction(dispatch, currentProject.id);
                      props.setSubmitting(false);
                      toggleModal();
                    }}
                  >
                    <span>Opublikuj</span>
                  </button>
                  <button
                    type="button"
                    className="button is-light-grey"
                    onClick={toggleModal}
                    data-testid="modalCancel"
                  >
                    <span>Anuluj</span>
                  </button>
                </Modal.Footer>
              </Modal.Root>
            </Modal>
          </div>
        )}
        {!publishable && !isPublished && !Number.isNaN(params.projectId) && (
          <span className="ml-2">Niektóre produkty wymagają uzupełnienia</span>
        )}
      </div>
    </Form>
  );
};

export default withFormik<
  ProjectBriefManagement & RouteComponentProps & { currentProject?: ProjectResponse },
  ProjectBriefFormValues
>({
  enableReinitialize: true,
  validationSchema: ProjectBriefSchema,
  // Transform outer props into form values
  mapPropsToValues: ({ currentProject }) => {
    if (currentProject && isFullProject(currentProject)) {
      const { id, slug, products, published, publishable, ...initialValues } = currentProject;
      return { ...initialValues };
    }
    return {
      name: '',
      description: '',
      productsAmount: 0,
      category: null,
      brand: null,
      printingFilesDate: format(new Date(), 'yyyy-MM-dd'),
      promoDate: format(new Date(), 'yyyy-MM-dd'),
      // ...props,
    };
  },

  handleSubmit: async (values, { props, resetForm }) => {
    await projectActions.createProjectAction(props.dispatch, resetForm, props.history, {
      ...values,
    });
    // do submitting things
  },
})(ProjectBriefForm);
