import { createAction, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';
import {
  MessageActivityLogItem,
  fetchProductActivityLog,
  postNewProductMessage,
  fetchProjectActivityLog,
  postNewProjectMessage,
} from 'API/messages';

export type ActivityLogState = {
  messages: MessageActivityLogItem[];
};

export enum actionTypes {
  SAVE_MESSAGE = 'activityLog/SAVE_MESSAGE',
  SAVE_MESSAGES = 'activityLog/SAVE_MESSAGES',
}

const saveMessage = createAction(actionTypes.SAVE_MESSAGE)<MessageActivityLogItem>();
const saveMessages = createAction(actionTypes.SAVE_MESSAGES)<MessageActivityLogItem[]>();

export type ActivityLogActionTypes = ActionType<typeof saveMessage | typeof saveMessages>;

//TODO CHANGE NAME?
const getProductMessages = async (dispatch: Dispatch<ActivityLogActionTypes>, productId: number): Promise<void> => {
  const response = await fetchProductActivityLog(productId);

  dispatch(saveMessages(response));
};

const getProjectMessages = async (dispatch: Dispatch<ActivityLogActionTypes>, projectId: number): Promise<void> => {
  const response = await fetchProjectActivityLog(projectId);

  dispatch(saveMessages(response));
};

const sendProductMessage = async (
  dispatch: Dispatch<ActivityLogActionTypes>,
  productId: number,
  message: string,
  resetForm: () => void
): Promise<void> => {
  const response = await postNewProductMessage(message, productId);
  if (response) {
    dispatch(saveMessage(response));
    resetForm();
  }
};

const sendProjectMessage = async (
  dispatch: Dispatch<ActivityLogActionTypes>,
  projectId: number,
  message: string,
  resetForm: () => void
): Promise<void> => {
  const response = await postNewProjectMessage(message, projectId);
  if (response) {
    dispatch(saveMessage(response));
    resetForm();
  }
};

export default {
  getProductMessages,
  getProjectMessages,
  saveMessages,
  saveMessage,
  sendProjectMessage,
  sendProductMessage,
};
