import React, { InputHTMLAttributes } from 'react';
import cx from 'classnames';

const Checkbox: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({ className, children, ...props }) => (
  <>
    <input type="checkbox" className={cx('form-checkbox', className)} id={props.name} {...props} />
    <label htmlFor={props.id || props.name}>{children}</label>
  </>
);

export default Checkbox;
