import React, { useMemo, useState, useCallback } from 'react';
import { withFormik, Form, Field, FieldArray, ErrorMessage, FormikProps, ArrayHelpers } from 'formik';
import { Dispatch } from 'redux';
import CustomLightbox from '../../molecules/CustomLightbox';
import BriefHeader from 'atoms/BriefHeader';
import HorizontalLine from 'atoms/HorizontalLine';
import { BriefHeaderWithLength } from 'molecules/BriefHeaderWithLength';
import { WithId } from 'helpers/typesHelpers';
import { useHistory } from 'react-router';
import {
  FinalPhotoBriefResponse,
  PickedPhotoBriefConfig,
  FinalPhotoBriefPhotoType,
  FinalPhotoBriefFormModel,
} from 'interfaces/API/finalPhotoBrief';

import FinalPhotoBriefSection from './FinalPhotoBriefSection';
import ButtonCheckbox from 'atoms/ButtonCheckbox';
import finalPhotoBriefActions from 'modules/finalPhotoBrief/actions';
import Modal from 'molecules/modals/Modal';
import useToggler from 'hooks/useToggler';
import useNumberParams from 'hooks/useNumberParams';
import { useLocation } from 'react-use';
import useLocationParams from 'hooks/useLocationParams';

type PhotoBriefCompletedProps = {
  currentFinalPhotoBrief: WithId<FinalPhotoBriefResponse>;
  config: PickedPhotoBriefConfig;
};
type ManagementProps = {
  dispatch: Dispatch;
  productId: number;
  role: string | null;
};

type ItemToRemove = {
  type: FinalPhotoBriefPhotoType | null;
  index: number | null;
};

type LightboxItem = {
  index: number | null;
  type: FinalPhotoBriefPhotoType | null;
};

type FinalPhotoBriefCompletedValues = {
  photosWithType: FinalPhotoBriefFormModel[];
};

const PhotoBriefCompleted: React.FC<PhotoBriefCompletedProps &
  ManagementProps &
  FormikProps<FinalPhotoBriefCompletedValues>> = ({ productId, dispatch, config, role, ...props }) => {
  const [itemToRemove, setItemToRemove] = useState<ItemToRemove>({ index: null, type: null });
  const [lightboxType, setLightboxType] = useState<LightboxItem>({ index: null, type: null });
  const [isPhotoRemoveModalOpen, togglePhotoRemoveModal] = useToggler();
  const [isLightboxOpen, toggleLightbox] = useToggler();

  const isEditable = useMemo(() => role === 'agency', [role]);
  const history = useHistory();
  const [params, location] = useLocationParams();

  const { projectId } = useNumberParams();

  const handleRemovePhotoModalOpen = (index: number, type: FinalPhotoBriefPhotoType) => {
    setItemToRemove({ index, type });
    togglePhotoRemoveModal();
  };

  const handleLightboxOpen = (index: number, type: FinalPhotoBriefPhotoType) => {
    setLightboxType({ index, type });
    toggleLightbox();
  };
  const shouldRender = (currentType: FinalPhotoBriefPhotoType, config: PickedPhotoBriefConfig): boolean => {
    const ab = Object.keys(config);
    const a = ab.find((item) => item === currentType);
    if (currentType === FinalPhotoBriefPhotoType.ARRANGEMENT) {
      return true;
    } else if (a) {
      return (config as any)[a];
    }
    return false;
  };

  const genereteUrl = (photosWithType: FinalPhotoBriefFormModel[], index: number, type: FinalPhotoBriefPhotoType) => {
    return photosWithType.find((item) => item.type === type)?.photosForType[index].url as string;
  };

  const renderLightbox = useCallback(
    (photosWithType: any[]) => {
      const items = photosWithType.find((item: any) => item.type === lightboxType.type)?.photosForType;
      return (
        isLightboxOpen && (
          <CustomLightbox
            mainSrc={items}
            length={items.length}
            toggle={toggleLightbox}
            initialInternalIndex={lightboxType.index}
          />
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLightboxOpen, toggleLightbox]
  );
  const { clipping, extraPhoto, packshot } = useMemo(() => config, [config]);
  return (
    <>
      <Form>
        <div className="rows">
          <div className="row">
            <BriefHeaderWithLength briefType={'PhotoBrief'} />
          </div>
          <div className="row columns pt-1">
            <div className="column">
              <div
                className="button is-orange-lighten"
                onClick={(): void => {
                  history.push({
                    pathname: `/projects/${projectId}/products/${productId}/draft`,
                    search: params.toString(),
                  });
                }}
              >
                <span>Draft</span>
              </div>
            </div>
          </div>
        </div>
        <div className="field-section">
          <div className="rows pb-0">
            <div className="row columns has-items-centered">
              <div className="column is-narrow">
                <BriefHeader>foto brief</BriefHeader>
              </div>
              <div className="column">
                <HorizontalLine />
              </div>
            </div>
          </div>
        </div>
        <div className="field-section">
          <FieldArray name="photosWithType">
            {({
              replace,
              remove,
              form: {
                values: { photosWithType },
              },
            }: ArrayHelpers & { form: FormikProps<FinalPhotoBriefCompletedValues> }) => {
              return (
                <>
                  {renderLightbox(photosWithType)}

                  {photosWithType.map((typeWithPhotos, index) => {
                    if (shouldRender(typeWithPhotos.type, config)) {
                      return (
                        <React.Fragment key={typeWithPhotos.type}>
                          <FinalPhotoBriefSection
                            key={index}
                            categoryName={typeWithPhotos.type}
                            description={typeWithPhotos.type}
                            index={index}
                          >
                            {typeWithPhotos.type !== FinalPhotoBriefPhotoType.ARRANGEMENT && (
                              <FinalPhotoBriefSection.Header />
                            )}
                            <FinalPhotoBriefSection.Content
                              replace={replace}
                              value={typeWithPhotos}
                              submit={props.submitForm}
                            >
                              {typeWithPhotos.photosForType.map((photos, index) => {
                                return (
                                  <FinalPhotoBriefSection.Item
                                    key={index}
                                    url={photos.url}
                                    setLightboxItems={handleLightboxOpen}
                                    removeItem={handleRemovePhotoModalOpen}
                                    type={typeWithPhotos.type}
                                    photoIndex={index}
                                    isEditable={isEditable}
                                  />
                                );
                              })}
                            </FinalPhotoBriefSection.Content>
                            {typeWithPhotos.type === FinalPhotoBriefPhotoType.ARRANGEMENT && (
                              <FinalPhotoBriefSection.Setup>
                                {config &&
                                  Object.entries({ clipping, extraPhoto, packshot }).map(([key, value]) => {
                                    return (
                                      <ButtonCheckbox key={key} checked={value} onChange={(): void => {}}>
                                        {key}
                                      </ButtonCheckbox>
                                    );
                                  })}
                              </FinalPhotoBriefSection.Setup>
                            )}

                            {isPhotoRemoveModalOpen && itemToRemove.index !== null && itemToRemove.type !== null && (
                              <Modal open={isPhotoRemoveModalOpen} setOpen={togglePhotoRemoveModal}>
                                <Modal.Root>
                                  <Modal.Header>Czy chcesz usunąć zdjęcie</Modal.Header>
                                  <Modal.Content>
                                    <div className="modal-content__image">
                                      <img src={genereteUrl(photosWithType, itemToRemove.index, itemToRemove.type)} />
                                    </div>
                                  </Modal.Content>
                                  <Modal.Footer>
                                    <button
                                      className="button is-orange-lighten mr-1"
                                      type="button"
                                      onClick={async (): Promise<void> => {
                                        if (itemToRemove.index !== null && itemToRemove.type !== null) {
                                          await finalPhotoBriefActions.removeFinalPhotoBrief(
                                            genereteUrl(photosWithType, itemToRemove.index, itemToRemove.type),
                                            productId,
                                            dispatch
                                          );
                                        }
                                        togglePhotoRemoveModal();
                                      }}
                                    >
                                      <span>Usuń</span>
                                    </button>
                                    <button
                                      type="button"
                                      className="button is-light-grey"
                                      onClick={togglePhotoRemoveModal}
                                    >
                                      <span>Anuluj</span>
                                    </button>
                                  </Modal.Footer>
                                </Modal.Root>
                              </Modal>
                            )}
                          </FinalPhotoBriefSection>
                        </React.Fragment>
                      );
                    }
                    return null;
                  })}
                </>
              );
            }}
          </FieldArray>
        </div>
      </Form>
    </>
  );
};

export default withFormik<
  PhotoBriefCompletedProps & ManagementProps & Partial<FinalPhotoBriefCompletedValues>,
  FinalPhotoBriefCompletedValues
>({
  enableReinitialize: true,
  mapPropsToValues: ({ currentFinalPhotoBrief, config }) => {
    //VALUES FROM API

    const result: FinalPhotoBriefFormModel[] = Object.values(FinalPhotoBriefPhotoType).reduce((acc, curr) => {
      const photosForType = currentFinalPhotoBrief.photos
        .filter((i) => i.type === curr)
        .map(({ type, ...item }) => item);
      acc.push({ type: curr, photosForType });

      return acc;
    }, [] as FinalPhotoBriefFormModel[]);

    return {
      photosWithType: result,
    };
  },
  handleSubmit: async (values, { props: { dispatch, productId }, resetForm }) => {
    const response = await finalPhotoBriefActions.addFinalPhotoBriefItems(productId, values.photosWithType, dispatch);
    if(response){
      resetForm();
    }
  },
})(PhotoBriefCompleted);
