import React from 'react';
import { CirclePlus, PuzzelIcon } from 'assets';
import { useSelector } from 'react-redux';
import userSelectors from 'modules/users/selectors';
import { FilterIcon } from 'assets';
import cx from 'classnames';
import { ProjectsQueryParams } from '../interfaces/API/projects';
import { OrderByEnum } from 'interfaces/API/projects';

type LeftSidebarHeaderProps = {
  createNewProject: () => void;
  orderListBy: (orderby: OrderByEnum) => void;
  orderBy: ProjectsQueryParams['order_by'];
  loading: boolean;
};

const LeftSidebarHeader: React.FC<LeftSidebarHeaderProps> = ({ createNewProject, orderListBy, orderBy, loading }) => {
  const userRole = useSelector(userSelectors.currentUserRole);
  return (
    <div className="rows left-header">
      <div className="row columns is-marginless">
        <div className="column has-items-centered">
          <PuzzelIcon />
          <span className="left-header__title">Projekty</span>
          <button
            className={cx('button order-button', orderBy === OrderByEnum.MOD ? 'is-orange-lighten' : 'is-medium-grey')}
            onClick={() => orderListBy(OrderByEnum.MOD)}
            disabled={loading}
          >
            <span className="order-button__label">
              MOD
              <FilterIcon />
            </span>
          </button>
          <button
            className={cx('button order-button', orderBy === OrderByEnum.PF ? 'is-orange-lighten' : 'is-medium-grey')}
            onClick={() => orderListBy(OrderByEnum.PF)}
            disabled={loading}
          >
            <span className="order-button__label">
              PF
              <FilterIcon />
            </span>
          </button>
        </div>
        {userRole === 'supplier' && (
          <div className="column is-narrow has-items-centered">
            <CirclePlus onClick={createNewProject} className="scale-on-hover" />
          </div>
        )}
      </div>
    </div>
  );
};

export default LeftSidebarHeader;
