import React, { useRef, useCallback } from 'react';
import { FieldArray, Field } from 'formik';
import CustomImage from 'molecules/ProductBriefImage';
import { CirclePlus } from 'assets';

type ImagePickerWithPreviewsProps = {
  name: string;
};

export const ImagePickerWithPreviews: React.FC<ImagePickerWithPreviewsProps> = ({ name }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onImagePickerClick = (): void => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileSelect = useCallback((e, push) => {
    const { files } = e.target;

    Array.from<File>(files).forEach((file: File): void => {
      const fileObject = {
        file,
        url: URL.createObjectURL(file),
        filename: file.name,
      };
      push(fileObject);
    });
  }, []);

  return (
    <>
      <FieldArray name={name}>
        {({ push, remove, form: { values } }) => {
          const photos =
            values.photos && values.photos.length > 0
              ? values.photos.map((photo: any, index: number) => (
                  <Field
                    key={index}
                    component={CustomImage}
                    removeItem={(): void => remove(index)}
                    name={`photos.${index}`}
                    className="image image__box image__box--small image__with-remove pointer"
                    alt={`photos.${index}`}
                  />
                ))
              : null;

          return (
            <>
              <input
                type="file"
                ref={fileInputRef}
                className="image-picker__input pointer"
                accept="image/*"
                onChange={(e): void => handleFileSelect(e, push)}
                multiple
              />
              <div className="row wrap">
                <div className="image-picker image__box image__box--small pointer" onClick={onImagePickerClick}>
                  <CirclePlus />
                </div>
                {photos}
              </div>
            </>
          );
        }}
      </FieldArray>
    </>
  );
};
