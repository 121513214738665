import React, { useCallback, useState } from 'react';
import { FieldArray, Field, ErrorMessage, Form, withFormik, FormikProps, ArrayHelpers } from 'formik';
import { format } from 'date-fns';
import Datepicker, { DatePickerChangeHandler } from '../../atoms/Datepicker';
import { Dispatch } from 'redux';
import * as yup from 'yup';
import BriefHeader from 'atoms/BriefHeader';
import HorizontalLine from 'atoms/HorizontalLine';
import FieldTextArea from 'molecules/fields/FieldTextArea';
import Modal from 'molecules/modals/Modal';
import { ModalFooter } from 'atoms/ModalFooter';
import FieldInputError from 'atoms/FieldInputError';
import cx from 'classnames';
import { BriefHeaderWithLength } from 'molecules/BriefHeaderWithLength';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useNumberParams from 'hooks/useNumberParams';
import {
  PhotoBriefResponse,
  PhotoBriefStage,
  PhotoBriefCategoryStore,
  PhotoBriefCategoryEnum,
  PhotoBriefCategoryFormModel,
  PhotoBriefChoiceResponse,
} from 'interfaces/API/photoBrief';
import PhotoBriefSection from './PhotoBriefSection';
import { WithId } from 'helpers/typesHelpers';
import ButtonCheckbox from 'atoms/ButtonCheckbox';
import useToggler from 'hooks/useToggler';
import photoBriefActions from 'modules/photoBrief/actions';
import { useLocation } from 'react-use';
import useLocationParams from 'hooks/useLocationParams';

type PhotoBriefProps = {
  currentPhotoBrief: WithId<PhotoBriefResponse> | null;
};
type ManagementProps = {
  dispatch: Dispatch;
  productId: number;
  allCategories: PhotoBriefCategoryStore[];
  choices: PhotoBriefChoiceResponse[];
  stage: PhotoBriefStage;
  acceptable: boolean;
  editable: boolean;
  publishable: boolean;
  completable: boolean;
  userRole: string | null;
};
type PhotoBriefValues = {
  conceptDescription: string;
  sessionDate: string;
  categoriesWithItems: PhotoBriefCategoryFormModel[];
};

const PhotoBriefNotCompletedSchema = yup.object().shape({
  conceptDescription: yup.string().required('Ogólny opis koncepcji jest wymagany.'),
  hasModels: yup.bool(),
  hasStylings: yup.bool(),
  hasSceneries: yup.bool(),
  models: yup.string().when('hasModels', { is: true, then: (m: any) => m.required() }),
  styling: yup.string().when('hasStylings', { is: true, then: (m: any) => m.required() }),
  scenery: yup.string().when('hasSceneries', { is: true, then: (m: any) => m.required() }),
});

const PhotoBriefNotCompleted: React.FC<ManagementProps & FormikProps<PhotoBriefValues>> = ({
  allCategories,
  choices,
  stage,
  acceptable,
  completable,
  editable,
  publishable,
  userRole,
  ...props
}) => {
  type DatePickerChangeHandlerCreator = (name: keyof PhotoBriefValues) => DatePickerChangeHandler;

  const [isPublishModalOpen, togglePublishModal] = useToggler();
  const [isFinalModalOpen, toggleFinalModal] = useToggler();
  const [loading, setLoading] = useState(false);
  const [params, location] = useLocationParams();

  const onButtonCheckboxChange = (
    push: (value: any) => void,
    remove: (index: number) => void,
    categoryName: PhotoBriefCategoryEnum,
    description: string,
    count: number,
    index: number
  ) => (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      push({ name: categoryName, items: [], description });
    } else if (count === 0) {
      remove(index);
    }
  };

  const onChange: DatePickerChangeHandlerCreator = useCallback(
    (name) => {
      const onChangeInternal: DatePickerChangeHandler = (value) => {
        if (value !== null) {
          props.setFieldValue(name, format(value, 'yyyy-MM-dd'));
        } else {
          props.setFieldValue(name, null);
        }
      };
      return onChangeInternal;
    },
    [props]
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const { projectId, productId } = useNumberParams();
  // const handlePhotoBriefAccept = async (category: PhotoBriefCategory, id: number | null): Promise<void> => {
  //   if (currentPhotoBrief) {
  //     await acceptPhotoBriefItem(dispatch, productId, projectId, currentPhotoBrief.id, category, id);
  //   }
  //   await Promise.resolve();
  // };
  return (
    <Form>
      <div className="rows">
        <div className="row">
          <BriefHeaderWithLength briefType={'PhotoBrief'} />
        </div>
        <div className="row columns pt-1">
          <div className="column">
            <div
              className="button is-orange-lighten"
              onClick={(): void => {
                history.push({
                  pathname: `/projects/${projectId}/products/${productId}/draft`,
                  search: params.toString(),
                });
              }}
            >
              <span>Draft</span>
            </div>
          </div>
          {[PhotoBriefStage.CREATED, PhotoBriefStage.PUBLISHED].some((x) => x === stage) ? (
            <div className="column is-narrow">
              <button
                type="button"
                className="button is-orange-lighten"
                disabled={!completable}
                onClick={toggleFinalModal}
              >
                <span>Zakończ fotobriefing</span>
              </button>
            </div>
          ) : (
            <div className="column is-narrow">
              <button
                type="button"
                className="button is-orange-lighten"
                onClick={(): void =>
                  history.push({
                    pathname: `/projects/${projectId}/products/${productId}/finalphotobrief`,
                    search: params.toString(),
                  })
                }
              >
                <span>Zakończony fotobrief</span>
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="field-section">
        <div className="rows">
          <div className="row columns has-items-centered">
            <div className="column is-narrow">
              <BriefHeader>foto brief</BriefHeader>
            </div>
            <div className="column">
              <HorizontalLine />
            </div>
          </div>
          <div className="row columns has-items-centered">
            <div className="column is-narrow mr-2">
              <span className="bolder">Data sesji zdjęciowej</span>
            </div>
            <div className="column rows">
              <div className="row">
                <Field
                  type="date"
                  component={Datepicker}
                  onChange={onChange('sessionDate')}
                  selected={props.values.sessionDate}
                  disabled={!editable}
                />
              </div>
              {/* <div className="column is-6 form__to-right">
           <div className="button is-orange-lighten">Potwierdz otrzymanie próbek</div>
         </div> */}
              <div className="row">
                <ErrorMessage name="printingFilesDate" component={FieldInputError} />
              </div>
            </div>
          </div>
          <div className="row rows">
            <div className="row">
              <Field
                name="conceptDescription"
                component={FieldTextArea}
                placeholder="Ogolny opis koncepcji"
                className="form__input--textarea"
                disabled={!editable}
              />
            </div>
            <div className="row">
              <ErrorMessage name="conceptDescription" component={FieldInputError} />
            </div>
          </div>

          <div className="row columns">
            <FieldArray name="categoriesWithItems">
              {({
                push,
                remove,
                form: {
                  values: { categoriesWithItems },
                },
              }: ArrayHelpers & { form: FormikProps<PhotoBriefValues> }) => {
                return (
                  allCategories &&
                  allCategories.map((category) => {
                    const index = categoriesWithItems.findIndex((c) => c.name === category.label);
                    const length = index >= 0 ? categoriesWithItems[index].items.length : 0;
                    return (
                      <div className="column is-narrow" key={category.label}>
                        <ButtonCheckbox
                          disabled={!editable}
                          name={category.label}
                          checked={index >= 0}
                          onChange={onButtonCheckboxChange(
                            push,
                            remove,
                            category.label,
                            category.description,
                            length,
                            index
                          )}
                        >
                          {category.description}
                        </ButtonCheckbox>
                      </div>
                    );
                  })
                );
              }}
            </FieldArray>
          </div>
          {props.values.categoriesWithItems.map((categoryItem) => (
            <PhotoBriefSection
              categoryName={categoryItem.name}
              description={categoryItem.description}
              key={categoryItem.name}
            >
              <PhotoBriefSection.Header />
              <PhotoBriefSection.Content>
                {categoryItem.items.map((item) => {
                  const isAcceptedBySupplier = choices.find(
                    (choice) => choice.item === item.id && choice.role === 'supplier'
                  )
                    ? true
                    : false;
                  const isAcceptedByClient = choices.find(
                    (choice) => choice.item === item.id && choice.role === 'client'
                  )
                    ? true
                    : false;
                  return (
                    <PhotoBriefSection.Item
                      key={item.id}
                      id={item.id}
                      photos={item.photos}
                      title={item.title}
                      stage={stage}
                      description={item.description}
                      isAcceptedByClient={isAcceptedByClient}
                      isAcceptedBySupplier={isAcceptedBySupplier}
                      editable={editable}
                    />
                  );
                })}
              </PhotoBriefSection.Content>
              {editable && <PhotoBriefSection.Footer />}
            </PhotoBriefSection>
          ))}
          <div className="field-section">
            <div className="rows">
              <div className="row items-right">
                {/* {currentPhotoBrief && currentPhotoBrief.stage === PhotoBriefStage.CREATED && ( */}

                {stage === PhotoBriefStage.CREATED && userRole === 'agency' && (
                  <button
                    disabled={!publishable}
                    type="button"
                    onClick={togglePublishModal}
                    className={cx({ 'is-loading': props.isSubmitting }, 'button is-orange-lighten')}
                  >
                    <span>Opublikuj fotobriefing</span>
                  </button>
                )}
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isFinalModalOpen && (
        <Modal open={isFinalModalOpen} setOpen={toggleFinalModal}>
          <Modal.Root>
            <Modal.Header>Zakończenie fotobriefingu</Modal.Header>
            <Modal.Content>
              Czy zakończyć fotobriefing produktu? &nbsp;
              <BriefHeader />
              {/* <span className="bolder">{` ${currentProduct?.displayName}`}</span> */}
            </Modal.Content>
            <Modal.Footer>
              <ModalFooter
                text="Zakończ"
                action={async (): Promise<void> => {
                  setLoading(true);
                  const response = await photoBriefActions.finishPhotoBrief(productId, dispatch);
                  toggleFinalModal();
                  if (response) {
                    history.push({
                      pathname: `/projects/${projectId}/products/${productId}/finalphotobrief`,
                      search: params.toString(),
                    });
                  }
                  setLoading(false);
                }}
                cancelAction={toggleFinalModal}
                isSubmitting={loading}
              />
            </Modal.Footer>
          </Modal.Root>
        </Modal>
      )}
      {isPublishModalOpen && (
        <Modal open={isPublishModalOpen} setOpen={togglePublishModal}>
          <Modal.Root>
            <Modal.Header>Publikacja fotobriefingu</Modal.Header>
            <Modal.Content>
              Czy opublikować fotobriefing produktu? &nbsp;
              <BriefHeader />
              {/* <span className="bolder">{` ${currentProduct?.displayName}`}</span> */}
            </Modal.Content>
            <Modal.Footer>
              <ModalFooter
                text="Opublikuj"
                action={(): void => {
                  props.handleSubmit();
                  togglePublishModal();
                }}
                cancelAction={togglePublishModal}
                isSubmitting={props.isSubmitting}
              />
            </Modal.Footer>
          </Modal.Root>
        </Modal>
      )}
    </Form>
  );
};

export default withFormik<ManagementProps & PhotoBriefProps, PhotoBriefValues>({
  enableReinitialize: true,
  validationSchema: PhotoBriefNotCompletedSchema,

  mapPropsToValues: ({ currentPhotoBrief, allCategories, ...props }) => {
    if (currentPhotoBrief) {
      const result: PhotoBriefCategoryFormModel[] = allCategories.reduce((acc, curr) => {
        const itemsForCategory = currentPhotoBrief.items
          .filter((i) => i.category === curr.label)
          .map(({ category, ...item }) => item);
        if (itemsForCategory.length > 0) {
          acc.push({ name: curr.label, items: itemsForCategory, description: curr.description });
        }
        return acc;
      }, [] as PhotoBriefCategoryFormModel[]);
      return {
        conceptDescription: currentPhotoBrief.conceptDescription,
        categoriesWithItems: result,
        sessionDate: currentPhotoBrief.sessionDate.toString(),
      };
    }

    //INITIAL VALUES
    return {
      conceptDescription: '',
      categoriesWithItems: [] as PhotoBriefCategoryFormModel[],
      sessionDate: format(new Date(), 'yyyy-MM-dd'),
    };
  },

  handleSubmit: async (values, { props: { dispatch, productId }, setStatus }) => {
    await photoBriefActions.publishPhotoBrief(productId, dispatch);
  },
})(PhotoBriefNotCompleted);
