import React from 'react';
import { SearchIcon } from 'assets';
import { Field, Formik } from 'formik';
import SearchInput from 'atoms/SearchInput';
import useLocationParams from 'hooks/useLocationParams';

interface SearchBar {
  searchFunc: (searchValue: string) => void;
}

const SearchBar: React.FC<SearchBar> = ({ searchFunc }) => {
  const [params, location] = useLocationParams();
  const initialValues = {
    searchValue: params.get('searchValue') || '',
  };

  return (
    <Formik initialValues={initialValues} onSubmit={(values) => searchFunc(values.searchValue)}>
      {({ values }) => (
        <div className="search-bar">
          <div className="control has-icons-right">
            <Field
              id={'searchField'}
              name="searchValue"
              component={SearchInput}
              type="text"
              placeholder="Wyszukaj projekt"
              className="search-bar__input input is-large"
              searchFunc={searchFunc}
            />
            <span className="icon is-right" onClick={() => searchFunc(values.searchValue)}>
              <SearchIcon />
            </span>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default SearchBar;
