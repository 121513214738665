import { AppState } from 'modules/rootReducer';
import userSelectors from 'modules/users/selectors';
import { createSelector, Selector } from 'reselect';
import productSelectors from 'modules/products/selectors';
import { AnyProject, ProjectMember, MemberData } from 'interfaces/API/projects';
import max from 'lodash.max';
import { UserDataResponse } from 'interfaces/API/userAuthorization';
import { boolean } from 'yup';
// TODO TYPES
const projects = (state: AppState): AnyProject[] => state.projects.projectsList;

const projectListWithProducts = createSelector(
  projects,
  (state: AppState) => state,
  productSelectors.productsByProjectId,
  (projects, state) =>
    projects.map((project) => ({ ...project, products: productSelectors.productsByProjectId(project.id)(state) }))
);

//TODO: Types
const projectById = (projectId: number): Selector<AppState, AnyProject | undefined> =>
  createSelector(projects, (list) => list.find((item) => item.id === projectId));

const shouldUpdateProjectById = (projectId: number): Selector<AppState, boolean> =>
  createSelector(productSelectors.productsByProjectId(projectId), projectById(projectId), (products, project) => {
    if (!project) {
      return true;
    }
    const maxUpdated = max(products.map((x) => x.updatedAt));
    if (maxUpdated && maxUpdated >= project.updatedAt) {
      return true;
    }
    return false;
  });

const projectMembers = (projectId: number): Selector<AppState, ProjectMember[]> =>
  createSelector(
    projectById(projectId),
    (state: AppState) => state,
    (project, state) => {
      if (!project) {
        return [];
      }
      //TODO: INVESTIGATE RETURN
      const projectMembers = project.members.map((member: MemberData) => {
        const user = userSelectors.userById(member.user)(state);
        if (user !== undefined) {
          return { user, role: member.role } as ProjectMember;
        }
        return;
      });

      return projectMembers.filter((x) => x !== undefined) as ProjectMember[];
    }
  );
// export const currentProjectSelector = (projectId: number) => (state: AppState): ProjectResponse | undefined => {
//   return state.projects.projectsList.find((project) => project.id === projectId);
// };

export default {
  projectMembers,
  projectListWithProducts,
  projects,
  projectById,
  shouldUpdateProjectById,
};
