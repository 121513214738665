import React from 'react';
import useNumberParams from 'hooks/useNumberParams';
import { useSelector } from 'react-redux';
import productSelectors from 'modules/products/selectors';
import BriefHeader from 'atoms/BriefHeader';
import { AppState } from 'modules/rootReducer';

type BriefHeaderProps = {
  briefType: string;
};

export const BriefHeaderWithLength: React.FC<BriefHeaderProps> = ({ briefType }) => {
  const { projectId, productId } = useNumberParams();
  const [product, productIndex, productsLength] = useSelector((state: AppState) => [
    productSelectors.productById(productId)(state),
    productSelectors.projectProductIndex(projectId, productId)(state),
    productSelectors.projectProductsLength(projectId)(state),
  ]);

  return (
    <BriefHeader>
      {productIndex + 1} / {productsLength} - {briefType} - {product?.displayName}
    </BriefHeader>
  );
};
