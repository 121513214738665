import { createAction, ActionType } from 'typesafe-actions';
import { Dispatch } from 'react';
import userApi from 'API/userAuthorization';
import { UserDataResponse, UserListItem } from 'interfaces/API/userAuthorization';
import LogRocket from 'logrocket';
// import { fetchCurrentUser, UserDataResponse, fetchAllUsers, logout } from 'API/userAuthorization';

export interface UserState {
  currentUser: UserDataResponse | null;
  usersList: UserListItem[];
}

export enum actionTypes {
  SET_CURRENT_USER = 'user/SET_CURRENT_USER',
  REMOVE_CURRENT_USER = 'user/REMOVE_CURRENT_USER',
  SET_ALL_USERS = 'user/SET_ALL_USERS',
  CLEAR_STORE = 'CLEAR_STORE',
}

export const setCurrentUser = createAction(actionTypes.SET_CURRENT_USER)<UserDataResponse>();
export const removeCurrentUser = createAction(actionTypes.REMOVE_CURRENT_USER)<void>();
export const setAllUsers = createAction(actionTypes.SET_ALL_USERS)<UserListItem[]>();
export const clearStore = createAction(actionTypes.CLEAR_STORE)<void>();

export type UserActionTypes = ActionType<
  typeof setCurrentUser | typeof removeCurrentUser | typeof setAllUsers | typeof clearStore
>;

export const logoutUser = async (dispatch: Dispatch<UserActionTypes>): Promise<void> => {
  const response = await userApi.logout();
  if (response) {
    dispatch(clearStore());
  }
};

export const getCurrentUser = async (dispatch: Dispatch<UserActionTypes>): Promise<boolean> => {
  const response = await userApi.fetchCurrentUser();
  if (response) {
    LogRocket.identify(response.email);
    dispatch(setCurrentUser(response));

    return true;
  }
  return false;
};

export const getAllUsers = async (dispatch: Dispatch<UserActionTypes>): Promise<boolean> => {
  const response = await userApi.fetchAllUsers();
  if (response) {
    dispatch(setAllUsers(response));
    return true;
  }
  return false;
};

// export const handleStageChange = (currentStage: BriefState): BriefActionTypes => ({
//   type: SET_CURRENT_STAGE,
//   payload: currentStage,
// });
