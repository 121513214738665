import React, { useContext, useRef } from 'react';
import { FinalPhotoBriefPhotoType, FinalPhotoBriefPhotoFormModel } from 'interfaces/API/finalPhotoBrief';
import { CirclePlus, Delete } from 'assets';
import useNumberParams from 'hooks/useNumberParams';
import { useDispatch } from 'react-redux';
import HorizontalLine from 'atoms/HorizontalLine';

type ContextValues = {
  categoryName: FinalPhotoBriefPhotoType;
  description: string;
  index: number;
};

const FinalPhotoBriefSectionContext = React.createContext<ContextValues>({
  categoryName: FinalPhotoBriefPhotoType.ARRANGEMENT,
  description: '',
  index: 0,
});

const useFinalPhotoBriefContext = (): ContextValues => {
  const context = useContext(FinalPhotoBriefSectionContext);
  if (!context) {
    throw new Error(`NOPE`);
  }
  return context;
};

type FinalPBSectionProps = {
  categoryName: FinalPhotoBriefPhotoType;
  description: string;
  index: number;
};

type CompoundComponents = {
  Header: React.FC;
  Content: React.FC<FinalPhotoBriefContentProps>;
  Item: React.FC<FinalPhotoBriefItemProps>;
  Setup: React.FC;
  Footer: React.FC;
};

const FinalPhotoBriefHeader: React.FC = () => {
  const { description } = useFinalPhotoBriefContext();
  return (
    <div className="row columns has-items-centered">
      <div className="column is-narrow ">
        <span className="bolder">{description}:</span>
      </div>
      <div className="column">
        <HorizontalLine />
      </div>
    </div>
  );
};

type FinalPhotoBriefContentProps = {
  value: any;
  replace: (x: number, y: any) => void;
  submit: () => void;
};

const FinalPhotoBriefContent: React.FC<FinalPhotoBriefContentProps> = ({ children, replace, value, submit }) => {
  const { index } = useFinalPhotoBriefContext();
  const { productId } = useNumberParams();
  const dispatch = useDispatch();
  const imageInputRef = useRef<HTMLInputElement>(null);

  const handleImageSelect = async (e: any) => {
    const { files } = e.target;
    const data = [] as FinalPhotoBriefPhotoFormModel[];
    Array.from<File>(files).forEach((file: File) => {
      data.push({ file, url: URL.createObjectURL(file), filename: file.name });
      // replace(index, {
      //   ...value,
      //   photosForType: [{ file, url: URL.createObjectURL(file), filename: file.name }, ...value.photosForType],
      // });
      //   push({ file, url: URL.createObjectURL(file), filename: file.name });
    });
    replace(index, {
      ...value,
      photosForType: [...data, ...value.photosForType],
    });
    submit();
    // await finalPhotoBriefActions.addFinalPhotoBriefItems(
    //   productId,
    //   [{ type: FinalPhotoBriefPhotoType.ARRANGEMENT, photosForType: data }],
    //   dispatch
    // );
  };
  const onImagePickerClick = (): void => {
    if (imageInputRef && imageInputRef.current) {
      imageInputRef.current.click();
    }
  };
  return (
    <div className="row wrap">
      <input
        type="file"
        ref={imageInputRef}
        className="image-picker__input pointer"
        accept="image/*"
        multiple
        onChange={(e): Promise<void> => handleImageSelect(e)}
      />

      <div className="image-picker image-picker__box pointer" onClick={onImagePickerClick}>
        <CirclePlus />
      </div>
      {children}
    </div>
  );
};

type FinalPhotoBriefItemProps = {
  url: string;
  removeItem: (x: number, y: FinalPhotoBriefPhotoType) => void;
  type: FinalPhotoBriefPhotoType;
  photoIndex: number;
  setLightboxItems: (x: number, y: FinalPhotoBriefPhotoType) => void;
  isEditable: boolean;
};

const FinalPhotoBriefItem: React.FC<FinalPhotoBriefItemProps> = ({
  url,
  removeItem,
  type,
  photoIndex,
  setLightboxItems,
  isEditable,
}) => {
  const { index } = useFinalPhotoBriefContext();
  return (
    <div className="image image__box pointer image__with-remove">
      <img src={url} alt="" onClick={(): void => setLightboxItems(photoIndex, type)} />

      {isEditable && <Delete className="not-transparent-on-hover" onClick={(): void => removeItem(photoIndex, type)} />}
    </div>
  );
};
const FinalPhotoBriefSetup: React.FC = ({ children }) => {
  return (
    <>
      <div className="row columns has-items-centered">
        <div className="column is-narrow ">
          <span className="bolder">Dodatkowe:</span>
        </div>
      </div>
      <div className="row wrap">{children}</div>
    </>
  );
};

const FinalPhotoBriefFooter: React.FC = () => {
  return <div></div>;
};

const FinalPhotoBriefSection: React.FC<FinalPBSectionProps> & CompoundComponents = ({
  children,
  categoryName,
  description,
  index,
}) => {
  return (
    <FinalPhotoBriefSectionContext.Provider
      value={{
        categoryName,
        description,
        index,
      }}
    >
      <div className="rows">{children}</div>
    </FinalPhotoBriefSectionContext.Provider>
  );
};

FinalPhotoBriefSection.Header = FinalPhotoBriefHeader;
FinalPhotoBriefSection.Content = FinalPhotoBriefContent;
FinalPhotoBriefSection.Item = FinalPhotoBriefItem;
FinalPhotoBriefSection.Setup = FinalPhotoBriefSetup;
FinalPhotoBriefSection.Footer = FinalPhotoBriefFooter;

export default FinalPhotoBriefSection;
