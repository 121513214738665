import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import CreateMessage from '../molecules/CreateMessage';
import { useDispatch, useSelector } from 'react-redux';
import ActivityLogActions from 'modules/activityLog/actions';
import Message from '../molecules/Message';
import userSelectors from 'modules/users/selectors';
import projectSelectors from 'modules/projects/selectors';
import productSelectors from 'modules/products/selectors';
import { messagesSelecor } from 'modules/activityLog/selectors';
import { AppState } from 'modules/rootReducer';
import StoreProvider from 'components/StoreProvider';
import RightSidebarHeader from 'molecules/RightSidebarHeader';
import useNumberParams from 'hooks/useNumberParams';

const ActivityLog: React.FC = () => {
  const { projectId, productId } = useNumberParams();

  const dispatch = useDispatch();

  //SELECTORS
  const currentProject = useSelector((state: AppState) => projectSelectors.projectById(Number(projectId))(state));
  const currentProduct = useSelector((state: AppState) => productSelectors.productById(Number(productId))(state));
  const isObserver = useSelector(userSelectors.isCurrentUserObserver)

  const messages = useSelector((state: AppState) => messagesSelecor()(state));
  useEffect(() => {
    (async (): Promise<void> => {
      if (productId) {
        await ActivityLogActions.getProductMessages(dispatch, productId);
      } else if (projectId) {
        await ActivityLogActions.getProjectMessages(dispatch, projectId);
      } else {
        dispatch(ActivityLogActions.saveMessages([]));
      }
    })();
  }, [dispatch, productId, projectId]);

  return (
    <div className="activity-log">
      <RightSidebarHeader
        slug={currentProject ? currentProject.slug : '---'}
        displayName={currentProduct ? currentProduct.displayName : '---'}
      />

      <div className="activity-log__content">
        {messages && messages.map((message, index) => <Message key={index} message={message} />)}
      </div>
      {!isObserver && 
        <div className="activity-log__footer">
          <StoreProvider
            selectors={{
              currentUserPhoto: userSelectors.currentUserPhotoSelector,
            }}
            component={(componentProps): JSX.Element => {
              return (
                <CreateMessage productId={productId} projectId={projectId} dispatch={dispatch} {...componentProps} />
              );
            }}
          />
        </div>
      }
    </div>
  );
};

export default ActivityLog;
