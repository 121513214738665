import React from 'react';
import { FieldProps } from 'formik';

type SelectProps = {
  disabled: boolean;
  dataTestid?: string;
  title?: string;
};

const Select: React.FC<FieldProps & SelectProps> = ({ field, form, children, dataTestid, title, ...props }) => {
  return (
    <div className="row columns">
      <div className="column has-items-right">
        <span>Ilość produktów: </span>
      </div>
      <div className="column has-items-right is-narrow ml-2">
        <div className="select is-input-border brief__input--select">
          <select
            value={field.value}
            disabled={props.disabled}
            onChange={(event: React.FormEvent<HTMLSelectElement>): void =>
              form.setFieldValue(field.name, parseInt((event.target as HTMLInputElement).value, 10))
            }
            data-testid={dataTestid}
            title={title}
          >
            {children}
          </select>
        </div>
      </div>
    </div>
  );
};

export default Select;
