import { AppState } from 'modules/rootReducer';
import { PackagingType, Category, Brand, ProductStageResponse } from 'API/projectData';
import { createSelector, Selector } from 'reselect';
import { ProductStageGroup } from 'interfaces/API/projects';

//TODO: CREATESELECTORS

export const packagingTypeSelector = (state: AppState): PackagingType[] | null => {
  return state.projectData.packagingTypes;
};
export const categoriesSelector = (state: AppState): Category[] | null => {
  return state.projectData.categories;
};
export const brandsSelector = (state: AppState): Brand[] | null => {
  return state.projectData.brands;
};

export const productStages = (state: AppState): ProductStageResponse[] => state.projectData.productStages;

export const labelToStageSelector = (stage: string): Selector<AppState, ProductStageResponse | undefined> =>
  createSelector(productStages, (list) => list.find((item) => item.id === stage));

export const groupByStageId = (stageId: string | undefined): Selector<AppState, ProductStageGroup> =>
  createSelector(productStages, (list) => list.find((item) => item.id === stageId)?.group || ProductStageGroup.INVALID);

export const descriptionByStageId = (stageId: string): Selector<AppState, string> =>
  createSelector(productStages, (list) => list.find((item) => item.id === stageId)?.description || '');
