import React, { HTMLAttributes } from 'react';
import cx from 'classnames';

type StageStampProps = {
  label: string;
};

type GroupStageStampProps = StageStampProps & {
  group: number;
};

type CompoundComponents = {
  Label: React.FC<HTMLAttributes<HTMLSpanElement>>;
};

const Stamp: React.FC<HTMLAttributes<HTMLDivElement>> & CompoundComponents = ({ className, ...props }) => (
  <div className={cx('stage-stamp', className)} {...props} />
);

const Label: React.FC<HTMLAttributes<HTMLSpanElement>> = ({ className, ...props }) => (
  <span className={cx('stage-stamp__label', className)} {...props} />
);

Stamp.Label = Label;

//StageStamp with default background
export const StageStamp: React.FC<StageStampProps> = ({ label }) => {
  return (
    <Stamp className={cx('stage-stamp__light-bg')}>
      <Stamp.Label>{label}</Stamp.Label>
    </Stamp>
  );
};

//StageStamp with colorable background
export const GroupStageStamp: React.FC<GroupStageStampProps> = ({ group, label }) => {
  return (
    <Stamp className={cx({ [`is-background-stage-${group}`]: group })}>
      <Stamp.Label>{label}</Stamp.Label>
    </Stamp>
  );
};
