import React from 'react';
import DatePicker from 'react-datepicker';
import cx from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarIcon } from 'assets';
import { parse } from 'date-fns';
export type DatePickerChangeHandler = (
  date: Date | null,
  event: React.SyntheticEvent<Element, Event> | undefined
) => void;

interface DatePickerProps {
  onChange: DatePickerChangeHandler;
  selected: string;
  disabled: boolean;
  dataTestid?: string;
  title?: string;
}

const Datepicker: React.FC<DatePickerProps> = ({ onChange, selected, disabled, dataTestid, title }) => {
  return (
    <div className="datepicker" data-testid={dataTestid}>
      <DatePicker
        onChange={onChange}
        selected={parse(selected, 'yyyy-MM-dd', new Date())}
        dateFormat="yyyy-MM-dd"
        className={cx(['input', 'is-input-border'])}
        disabled={disabled}
        title={title}
      />
      <CalendarIcon />
    </div>
  );
};

export default Datepicker;
