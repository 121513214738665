import React from 'react';
import { useSelector } from 'react-redux';
import { progressSelector } from 'modules/progress/selectors';

const ProgressBar: React.FC = () => {
  const progress = useSelector(progressSelector);
  return progress !== null ? (
    <div className="progress-bar">
      <div className="progress-bar__progress" style={{ width: `${progress * 100}%` }}></div>
    </div>
  ) : null;
};
export default ProgressBar;
