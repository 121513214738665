import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useNumberParams from './useNumberParams';
import { draftById } from 'modules/draft/selectors';
import { DesignStageStore } from 'interfaces/API/draft';
import { getDraftById } from 'modules/draft/actions';
import useRequestState, { RequestState } from './useRequestState';

type CurrentDraft = [RequestState, DesignStageStore | null, () => void];

const useCurrentDraft = (): CurrentDraft => {
  const { productId } = useNumberParams();
  const dispatch = useDispatch();
  const [status, setError, setLoading, setDefault] = useRequestState();
  const draft = useSelector(draftById(productId));
  const [retry, setRetry] = useState(0);

  const handleRetry = (): void => {
    setDefault();
    setRetry(retry + 1);
  };

  useEffect(() => {
    if (!draft && status === RequestState.DEFAULT) {
      setLoading();
      (async (): Promise<void> => {
        const response = await getDraftById(productId, dispatch);
        if (!response) {
          setError();
          return;
        }
        setDefault();
      })();
    }
  }, [dispatch, draft, productId, setDefault, setError, setLoading, status]);

  // return product && isFullProduct(product) ? product : null;
  return [status, draft ? draft : null, handleRetry];
};
export default useCurrentDraft;
