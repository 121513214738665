// import projectActions from './actionTypes';
import { createAction, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';
import projectApi from 'API/projects';
import { ProjectResponse, ProjectRequest, AnyProject, SimpleProject } from 'interfaces/API/projects';
import { History } from 'history';
import actionTypes from './actionTypes';
import productApi from 'API/products';
import ActivityLogActions, { ActivityLogActionTypes } from 'modules/activityLog/actions';
import { addProducts, ProductActionTypes, updateProducts } from '../products/actions';
import { toast } from 'react-toastify';
import { ProjectsQueryParams } from '../../interfaces/API/projects';
import { history } from '../../App';
import useLocationParams from 'hooks/useLocationParams';
export interface ProjectState {
  projectsList: Array<AnyProject>;
  orderBy: Required<ProjectsQueryParams['order_by']>;
}

const externalActions = {};

const internalActions = {
  updateProjectList: createAction(actionTypes.UPDATE_PROJECT_LIST)<SimpleProject[]>(),
  addProject: createAction(actionTypes.ADD_PROJECT)<ProjectResponse>(),
  updateProject: createAction(actionTypes.UPDATE_PROJECT)<{ projectId: number; response: ProjectResponse }>(),
  updateListOrder: createAction(actionTypes.UPDATE_LIST_ORDER)<ProjectsQueryParams['order_by']>(),
};

export type ProjectActionTypes = ActionType<typeof externalActions | typeof internalActions>;

const publishProjectAction = async (
  dispatch: Dispatch<ProjectActionTypes | ProductActionTypes | ActivityLogActionTypes>,
  projectId: number
): Promise<void> => {
  const response = await projectApi.publishProject(projectId);
  if (response) {
    dispatch(internalActions.updateProject({ projectId, response }));
    const productsResponse = await productApi.getProductsByURL(response.products);
    if (productsResponse) {
      dispatch(updateProducts(productsResponse));
      await ActivityLogActions.getProjectMessages(dispatch, projectId);
      toast.success('Projekt został opublikowany.');
    } else {
      toast.error('coś poszło nie tak.');
    }
  }
};

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

const getProjectsListAction = async (
  dispatch: Dispatch<ProjectActionTypes>,
  params: RequireAtLeastOne<ProjectsQueryParams>
): Promise<boolean> => {
  const response = await projectApi.getProjects(params);
  if (response !== null) {
    dispatch(internalActions.updateProjectList(response));
    if (params.order_by) {
      dispatch(internalActions.updateListOrder(params.order_by));
    }
    return true;
  }
  return false;
};

const getProjectByIdAction = async (dispatch: Dispatch<ProjectActionTypes>, projectId: number): Promise<boolean> => {
  const response = await projectApi.getProjectById(projectId);
  if (response !== null) {
    dispatch(internalActions.updateProject({ projectId, response }));
    return true;
  }
  return false;
};

const createProjectAction = async (
  dispatch: Dispatch<ProjectActionTypes | ProductActionTypes>,
  resetForm: () => void,
  history: History,
  projectData: ProjectRequest
): Promise<void> => {
  const response = await projectApi.createNewProject(projectData);
  if (response) {
    const productResponse = await productApi.getProductsByURL(response.products);
    if (productResponse) {
      dispatch(internalActions.addProject(response));
      dispatch(addProducts(productResponse));
      resetForm();
      toast.success('Projekt został stworzony.');
      history.push({
        pathname: `/projects/${response.id}/products/${productResponse[0].id}`,
      });
    }

    // history.push(`/projects/${response.id}/products/${response.products[0].id}`);
  }
};
export default {
  publishProjectAction,
  getProjectsListAction,
  getProjectByIdAction,
  createProjectAction,
};
