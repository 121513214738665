import React from 'react';
import { OptionDots, OutboLogo, DocIcon } from 'assets';
import DropdownItem from './DropdownItem';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'modules/rootReducer';
import { UserActionTypes, logoutUser } from 'modules/users/actions';
import { Dispatch } from 'redux';

const Navbar: React.FC = () => {
  const dispatch = useDispatch<Dispatch<UserActionTypes>>();
  const user = useSelector((state: AppState) => state.users.currentUser);
  return (
    <nav className="navbar has-shadow" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item navbar-item__logo">
          <OutboLogo style={{ maxHeight: '2.75rem' }} className="navbar-item__logo" />
        </a>
      </div>

      <div className="navbar-menu">
        <div className="navbar-end">
          {user && (
            <div className="navbar-item">
              <div className="avatar">{user.photo && <img src={user.photo} alt={'avatar'} />}</div>
              <div className="navbar-item rows" style={{ paddingTop: 0, paddingBottom: 0 }}>
                <div>{user.fullName}</div>
                <div className="font-small">{user.organization.name}</div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="navbar-item has-dropdown is-hoverable">
        <div className="navbar-item">
          <div className="navbar-item__dots">
            <OptionDots />
          </div>
          <div className="navbar-dropdown is-right">
            <DropdownItem
              text="Logout"
              icon={<DocIcon />}
              logout={(): void => {
                logoutUser(dispatch);
              }}
            />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
