import API from './axiosConfig';
import {
  PhotoBriefResponse,
  PhotoBriefCategoryResponse,
  PhotoBriefItemRequest,
  PhotoBriefItemResponse,
  PhotoBriefChoiceRequest,
  PhotoBriefChoiceResponse,
} from 'interfaces/API/photoBrief';
import objectToFormDataConverter from 'helpers/objectToFormDataConverter';

const getPhotoBriefById = async (photoBriefId: number): Promise<PhotoBriefResponse | null> => {
  try {
    const response = await API.get<PhotoBriefResponse>(`/photobriefing/${photoBriefId}/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

const getPhotoBriefCategories = async (): Promise<PhotoBriefCategoryResponse[]> => {
  try {
    const response = await API.get<PhotoBriefCategoryResponse[]>('/photobriefing-categories/');
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const addPhotoBriefItem = async (
  data: PhotoBriefItemRequest,
  photoBriefId: number
): Promise<PhotoBriefItemResponse | null> => {
  try {
    const formData = objectToFormDataConverter(data);
    const response = await API.post<PhotoBriefItemResponse>(`/photobriefing/${photoBriefId}/items/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const acceptPhotoBriefItem = async (
  data: PhotoBriefChoiceRequest,
  photoBriefId: number
): Promise<PhotoBriefResponse | null> => {
  try {
    const response = await API.patch<PhotoBriefResponse>(`/photobriefing/${photoBriefId}/`, { choice: data });
    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const publishPhotoBrief = async (photoBriefId: number): Promise<PhotoBriefResponse | null> => {
  try {
    const response = await API.patch<PhotoBriefResponse>(`/photobriefing/${photoBriefId}/`, { publish: true });
    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const deletePhotoBriefItem = async (itemId: number): Promise<number | null> => {
  try {
    const response = await API.delete<number>(`/photobriefing-items/${itemId}/`);
    return response.status;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const finishPhotoBrief = async (photoBriefId: number): Promise<PhotoBriefResponse | null> => {
  try {
    const response = await API.patch<PhotoBriefResponse>(`/photobriefing/${photoBriefId}/`, { complete: true });
    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export default {
  finishPhotoBrief,
  deletePhotoBriefItem,
  publishPhotoBrief,
  acceptPhotoBriefItem,
  addPhotoBriefItem,
  getPhotoBriefById,
  getPhotoBriefCategories,
};
