import { actionTypes, DraftState, DraftActionTypes } from './actions';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import { DesignStageStore } from 'interfaces/API/draft';

const initialState: DraftState = {
  draftList: [],
};

const draftReducer = createReducer<DraftState, DraftActionTypes>(initialState)
  .handleType(actionTypes.ADD_DRAFT, (state, action) =>
    produce(state, (draftState) => {
      const draft: DesignStageStore = { ...action.payload.response, id: action.payload.draftId };

      draftState.draftList.push(draft);
    })
  )
  .handleType(actionTypes.ADD_DRAFT_FILE, (state, { payload: { draftId, response } }) =>
    produce(state, (draftState) => {
      const index = draftState.draftList.findIndex((item) => item.id === draftId);
      draftState.draftList[index].files.unshift(response);
    })
  )
  .handleType(actionTypes.UPDATE_DRAFT, (state, { payload: { draftId, response } }) =>
    produce(state, (draftState) => {
      const index = draftState.draftList.findIndex((draft) => draft.id === draftId);
      draftState.draftList[index] = { ...draftState.draftList[index], ...response };
    })
  )
  //TODO: ALMOST THE SAME AS UPDATE
  .handleType(actionTypes.UPDATE_DRAFT_OPENED_BY, (state, { payload: { draftId, response, fileId } }) =>
    produce(state, (draftState) => {
      const index = draftState.draftList.findIndex((draft) => draft.id === draftId);
      const files = draftState.draftList[index].files;
      const fileIndex = files.findIndex((file) => file.id === fileId);
      draftState.draftList[index].files[fileIndex].openedBy = response;
    })
  );

export default draftReducer;
