import API from './axiosConfig';
import { DesignStageResponse, DesignStageFileRequest, DesignStageFileResponse } from 'interfaces/API/draft';
import objectToFormDataConverter from 'helpers/objectToFormDataConverter';
import { AxiosResponse } from 'axios';

const getFirstFinalById = async (firstFinalId: number): Promise<DesignStageResponse | null> => {
  try {
    const response = await API.get<DesignStageResponse>(`/ffs/${firstFinalId}/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

const addFirstFinalFile = async (
  firstFinalId: number,
  data: DesignStageFileRequest
): Promise<DesignStageFileResponse | null> => {
  try {
    const formData = objectToFormDataConverter(data);
    const response = await API.post<DesignStageFileResponse>(`/ffs/${firstFinalId}/files/`, formData);
    return response.data;
  } catch (err) {
    return null;
  }
};

const acceptFirstFinalFile = async (firstFinalId: number): Promise<DesignStageResponse | null> => {
  try {
    const response = await API.patch<DesignStageResponse>(`/ffs/${firstFinalId}/`, { accept: true });
    return response.data;
  } catch (err) {
    return null;
  }
};

const openFirstFinal = async (draftId: number) => {
  try {
    const response = await API.get<AxiosResponse>(`/first-final-files/${draftId}/open-file/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

export default {
  openFirstFinal,
  getFirstFinalById,
  addFirstFinalFile,
  acceptFirstFinalFile,
};
// export const fetchDrafts = async (productId: number): Promise<DraftResponse[] | []> => {
//   try {
//     const response = await API.get<DraftResponse[]>('');
//     return response.data;
//   } catch (err) {
//     console.error(err);
//     return [];
//   }
// };
