import { actionTypes, ProgressActionTypes, ProgressState } from './actions';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';

const initialState: ProgressState = {
  progress: null,
};

const progressReducer = createReducer<ProgressState, ProgressActionTypes>(initialState)
  .handleType(actionTypes.SET_PROGRESS, (state, action) =>
    produce(state, (draftState) => {
      draftState.progress = action.payload;
    })
  )
  .handleType(actionTypes.CLEAR_PROGRESS, (state) =>
    produce(state, (draftState) => {
      draftState.progress = null;
    })
  );

export default progressReducer;
