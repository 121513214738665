import React from 'react';
import { ClimbingBoxLoader } from 'react-spinners';

export const ClimbingLoader = (): JSX.Element => {
  return (
    <div className="hero is-fullheight">
      <div className="container columns has-items-centered">
        <div className="column">
          <ClimbingBoxLoader color="#f58c39" size={1.5} sizeUnit="rem" />
        </div>
      </div>
    </div>
  );
};
