import { combineReducers } from 'redux';
import briefReducer from './brief/reducer';
import userReducer from './users/reducer';
import projectReducer from './projects/reducer';
import photoBriefReducer from './photoBrief/reducer';
import { BriefActionTypes } from './brief/actions';
import { ActionType } from 'typesafe-actions';
import { UserActionTypes } from './users/actions';
import { ActivityLogActionTypes } from './activityLog/actions';
import activityLogReducer from './activityLog/reducer';
import finalPhotoBriefReducer from './finalPhotoBrief/reducer';
import dataReducer from './data/reducer';
import productReducer from './products/reducer';
import draftReducer from './draft/reducer';
import { DataActionTypes } from './data/actions';
import { ProductActionTypes } from './products/actions';
import { ProjectActionTypes } from './projects/actions';
import { ProgressActionTypes } from './progress/actions';
import progressReducer from './progress/reducer';
import firstFinalReducer from './firstFinal/reducer';
import printingFilesReducer from './printingFiles/reducer';
import { DraftActionTypes } from './draft/actions';
import { PhotoBriefActionTypes } from './photoBrief/actions';
import { FinalPhotoBriefActionTypes } from './finalPhotoBrief/actions';

const rootReducer = (state: any, action: any) => {
  if (action.type === 'CLEAR_STORE') {
    state = undefined;
  }
  return combineReducers({
    users: userReducer,
    projects: projectReducer,
    products: productReducer,
    projectData: dataReducer,
    progress: progressReducer,
    activityLog: activityLogReducer,
    brief: briefReducer,
    drafts: draftReducer,
    firstFinals: firstFinalReducer,
    printingFiles: printingFilesReducer,
    photoBrief: photoBriefReducer,
    finalPhotoBrief: finalPhotoBriefReducer,
  })(state, action);
};

export default rootReducer;

export type AppState = ReturnType<typeof rootReducer>;

type actions =
  | BriefActionTypes
  | ProjectActionTypes
  | UserActionTypes
  | ActivityLogActionTypes
  | DataActionTypes
  | ProductActionTypes
  | ProgressActionTypes
  | DraftActionTypes
  | PhotoBriefActionTypes
  | FinalPhotoBriefActionTypes;

export type RootAction = ActionType<actions>;
