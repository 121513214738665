import { UserListItem } from './userAuthorization';

export type MemberData = {
  id: number;
  user: number;
  role: string;
};

export type ProjectMember = {
  user: UserListItem;
  role: string;
};

export interface ProjectRequest {
  name: string;
  description: string;
  category: number | null;
  brand: number | null;
  printingFilesDate: string;
  promoDate: string;
  productsAmount: number;
}

export type SimpleProject = {
  id: number;
  slug: string;
  name: string;
  products: string;
  maxProductStage: string;
  members: MemberData[];
  published: boolean;
  updatedAt: Date;
  createdAt: Date;
  printingFilesDate: string;
};

export type Project = SimpleProject & {
  publishable: boolean;
  description: string;
  category: number;
  brand: number;
  // printingFilesDate: string;
  promoDate: string;
  productsAmount: number;
};

export type ProjectsQueryParams = {
  order_by: OrderByEnum;
  query: string;
};

export enum OrderByEnum {
  MOD = 'MOD',
  PF = 'PF',
}

export type ProjectResponse = Project;

export type AnyProject = SimpleProject | Project;

export enum ProductStageGroup {
  INVALID = 0,
  GROUP_1 = 1,
  GROUP_2 = 2,
  GROUP_3 = 3,
  GROUP_4 = 4,
  GROUP_5 = 5,
}

export enum ProductStage {
  STAGE_1 = 'waiting_for_brief',
  STAGE_1A = 'waiting_for_brief_accept_by_agency',
  STAGE_1B = 'waiting_for_brief_accept_by_client',
  STAGE_2A = 'waiting_for_draft',
  STAGE_2B = 'waiting_for_draft_accept_by_supplier',
  STAGE_2C = 'waiting_for_draft_accept_by_client',
  STAGE_3A = 'waiting_for_ff',
  STAGE_3B = 'waiting_for_ff_accept_by_supplier',
  STAGE_3C = 'waiting_for_ff_accept_by_client',
  STAGE_4A = 'waiting_for_pf',
  STAGE_4B = 'waiting_for_pf_accept_by_supplier',
  STAGE_4C = 'waiting_for_pf_accept_by_client',
  STAGE_5 = 'completed',
}
