import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useNumberParams from './useNumberParams';
import { DesignStageStore } from 'interfaces/API/draft';
import { firstFinalById } from 'modules/firstFinal/selectors';
import { getFirstFinalById } from 'modules/firstFinal/actions';
import useRequestState, { RequestState } from './useRequestState';

type CurrentFF = [RequestState, DesignStageStore | null, () => void];

const useCurrentFF = (): CurrentFF => {
  const { productId } = useNumberParams();
  const dispatch = useDispatch();
  const [status, setError, setLoading, setDefault] = useRequestState();
  const [retry, setRetry] = useState(0);
  const ff = useSelector(firstFinalById(productId));
  const handleRetry = (): void => {
    setDefault();
    setRetry(retry + 1);
  };
  useEffect(() => {
    if (!ff && status === RequestState.DEFAULT) {
      setLoading();
      (async (): Promise<void> => {
        const response = await getFirstFinalById(productId, dispatch);
        if (!response) {
          setError();
          return;
        }
        setDefault();
      })();
    }
  }, [dispatch, ff, productId, setDefault, setError, setLoading, status]);

  return [status, ff ? ff : null, handleRetry];
};
export default useCurrentFF;
