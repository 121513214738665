import { ProductState, ProductActionTypes } from './actions';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import actionTypes from './actionTypes';
const initialState: ProductState = {
  currentStage: 0,
  productsList: [],
  redirectTo: null,
};

const productsReducer = createReducer<ProductState, ProductActionTypes>(initialState)
  //PROJECT PUBLISHING
  .handleType(actionTypes.UPDATE_PRODUCTS, (state, { payload }) =>
    produce(state, (draftState) => {
      const newIds = payload.map((x) => x.id);
      draftState.productsList = draftState.productsList.filter((p) => !newIds.includes(p.id)).concat(payload);
    })
  )
  //PROJECT PUBLISHING
  .handleType(actionTypes.UPDATE_PRODUCT, (state, { payload: { productId, response } }) =>
    produce(state, (draftState) => {
      const index = draftState.productsList.findIndex((product) => product.id === productId);
      // TODO: nested produce? how?
      draftState.productsList[index] = { ...draftState.productsList[index], ...response };
    })
  )

  //PROJECT CREATING
  .handleType(actionTypes.ADD_PRODUCTS, (state, action) =>
    produce(state, (draftState) => {
      draftState.productsList = [...draftState.productsList, ...action.payload];
    })
  )
  .handleType(actionTypes.SET_CURRENT_STAGE, (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.currentStage = payload;
    })
  )
  .handleType(actionTypes.SET_PRODUCTS, (state, action) =>
    produce(state, (draftState) => {
      draftState.productsList = action.payload;
    })
  );

export default productsReducer;
