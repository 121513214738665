import API from './axiosConfig';
import {
  FinalPhotoBriefResponse,
  FinalPhotoBriefRequest,
  FinalPhotoBriefPhotoType,
} from 'interfaces/API/finalPhotoBrief';
import objectToFormDataConverter from 'helpers/objectToFormDataConverter';
import { FinalPhotoBrief } from 'organisms/FinalPhotoBrief/FinalPhotoBrief';

const getFinalPhotoBriefById = async (finalPhotoBriefId: number): Promise<FinalPhotoBriefResponse | null> => {
  try {
    const response = await API.get<FinalPhotoBriefResponse>(`/final-photobriefing/${finalPhotoBriefId}/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

const removeFinalPhotoBriefPhoto = async (url: string): Promise<number | null> => {
  try {
    const response = await API.delete<void>(url);
    return response.status;
  } catch (err) {
    return null;
  }
};

const addFinalPhotoBriefItems = async (
  data: FinalPhotoBriefRequest,
  finalPhotoBriefId: number
): Promise<FinalPhotoBriefResponse | null> => {
  try {
    const formData = objectToFormDataConverter(data);
    const response = await API.patch<FinalPhotoBriefResponse>(`/final-photobriefing/${finalPhotoBriefId}/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

export default {
  removeFinalPhotoBriefPhoto,
  getFinalPhotoBriefById,
  addFinalPhotoBriefItems,
};
