import { WithId } from 'helpers/typesHelpers';

import { BriefConfigStore } from './products';

export enum FinalPhotoBriefPhotoType {
  ARRANGEMENT = 'arrangement',
  EXTRA = 'extraPhoto',
  CLIPPING = 'clipping',
  PACKSHOT = 'packshot',
}

export type PickedPhotoBriefConfig = Pick<BriefConfigStore, 'packshot' | 'clipping' | 'extraPhoto'>;

export type FinalPhotoBriefResponse = {
  photos: FinalPhotoBriefPhotoResponse[];
};

export type FinalPhotoBriefRequest = {
  photos: FinalPhotoBriefPhotoRequest[];
};

export type FinalPhotoBriefPhotoResponse = {
  url: string;
  filename: string;
  type: FinalPhotoBriefPhotoType;
};

export type FinalPhotoBriefPhotoRequest = {
  file: File;
  type: FinalPhotoBriefPhotoType;
};

export type FinalPhotoBriefPhotoFormModel = {
  file?: File;
} & Omit<FinalPhotoBriefPhotoResponse, 'type'>;

export type FinalPhotoBriefFormModel = {
  type: FinalPhotoBriefPhotoType;
  photosForType: FinalPhotoBriefPhotoFormModel[];
};

export type FinalPhotoBriefStore = WithId<FinalPhotoBriefResponse>;
