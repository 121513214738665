import { createAction, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';
import firstFinalApi from 'API/firstFinal';
import {
  DesignStageResponse,
  DesignStageStore,
  DesignStageFileRequest,
  DesignStageFileResponse,
} from 'interfaces/API/draft';
import { updateProductOnSuccess, ProductActionTypes } from 'modules/products/actions';
import ActivityLogActions, { ActivityLogActionTypes } from 'modules/activityLog/actions';
import { toast } from 'react-toastify';
import { DraftActionTypes } from 'modules/draft/actions';

export type DraftState = {
  firstFinalList: DesignStageStore[];
};

export enum actionTypes {
  ADD_FIRST_FINAL = 'firstFinal/ADD_FIRST_FINAL',
  ADD_FIRST_FINAL_FILES = 'firstFinal/ADD_FIRST_FINAL_FILES',
  UPDATE_FIRST_FINAL = 'firstFinal/UPDATE_FIRST_FINAL',
  UPDATE_FIRST_FINAL_OPENED_BY = 'firstFinal/UPDATE_DRAFT_OPENED_BY',
}

const externalActions = {};

const internalActions = {
  addFirstFinal: createAction(actionTypes.ADD_FIRST_FINAL)<{ firstFinalId: number; response: DesignStageResponse }>(),
  addFirstFinalFile: createAction(actionTypes.ADD_FIRST_FINAL_FILES)<{
    firstFinalId: number;
    response: DesignStageFileResponse;
  }>(),
  updateFirstFinal: createAction(actionTypes.UPDATE_FIRST_FINAL)<{
    firstFinalId: number;
    response: DesignStageResponse;
  }>(),
  updateOpenedFile: createAction(actionTypes.UPDATE_FIRST_FINAL_OPENED_BY)<{
    firstFinalId: number;
    response: number[];
    fileId: number;
  }>(),
};

export type FirstFinalActionTypes = ActionType<typeof internalActions | typeof externalActions>;

export const getFirstFinalById = async (
  firstFinalId: number,
  dispatch: Dispatch<FirstFinalActionTypes>
): Promise<boolean> => {
  const response = await firstFinalApi.getFirstFinalById(firstFinalId);
  if (response) {
    dispatch(internalActions.addFirstFinal({ firstFinalId, response }));
    return true;
  }
  return false;
};

type DispatchTypes = FirstFinalActionTypes | ProductActionTypes | ActivityLogActionTypes;

export const addFirstFinalFile = async (
  dispatch: Dispatch<DispatchTypes>,
  firstFinalId: number,
  data: DesignStageFileRequest
): Promise<void> => {
  const response = await firstFinalApi.addFirstFinalFile(firstFinalId, data);
  if (response) {
    dispatch(internalActions.addFirstFinalFile({ firstFinalId, response }));
    const firstFinalResponse = await firstFinalApi.getFirstFinalById(firstFinalId);
    if (firstFinalResponse) {
      dispatch(internalActions.updateFirstFinal({ firstFinalId, response: firstFinalResponse }));
      await ActivityLogActions.getProductMessages(dispatch, firstFinalId);
    }
    await updateProductOnSuccess(firstFinalId, dispatch);
    toast.success('Dodano FF');
  }
};

export const acceptFirstFinalFile = async (dispatch: Dispatch<DispatchTypes>, firstFinalId: number): Promise<void> => {
  const response = await firstFinalApi.acceptFirstFinalFile(firstFinalId);
  if (response) {
    dispatch(internalActions.updateFirstFinal({ firstFinalId, response }));
    await updateProductOnSuccess(firstFinalId, dispatch);
    await ActivityLogActions.getProductMessages(dispatch, firstFinalId);
    toast.success('Zaakceptowano FF');
  }
};

export const updateOpenFirstFinal = (
  dispatch: Dispatch<DispatchTypes>,
  firstFinalId: number,
  openedBy: number[],
  fileId: number
): void => {
  dispatch(internalActions.updateOpenedFile({ firstFinalId, response: openedBy, fileId }));
};
