import React, { InputHTMLAttributes } from 'react';
import cx from 'classnames';
import { FieldTextInputInterface } from '../interfaces/InputInterfaces';

const TextInput: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({ className, ...props }) => {
  return (
    <input
      className={cx('input', 'is-input-border', className)}
      {...props}
      title={props.value ? props.placeholder : undefined}
    />
  );
};

export default TextInput;
