import { useLocation } from 'react-router';
import { Location } from 'history';

const useLocationParams = (): [URLSearchParams, Location<any>] => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  return [params, location];
};

export default useLocationParams;
