import React, { useState, useEffect } from 'react';
import LeftSidebarHeader from 'molecules/LeftSidebarHeader';
import { useSelector } from 'react-redux';
import Project from 'molecules/SidebarProject';
import { useHistory, useParams } from 'react-router';
import SearchBar from 'molecules/SearchBar';
import { AnyProductStore } from 'interfaces/API/products';
import projectSelectors from 'modules/projects/selectors';
import { useLocation } from 'react-use';
import Routes from '../helpers/routes';
import { AppState } from 'modules/rootReducer';
import { ClipLoader } from 'react-spinners';
import { OrderByEnum, Project as ProjectModel } from 'interfaces/API/projects';
import debounce from 'lodash.debounce';
import useLocationParams from 'hooks/useLocationParams';

interface LeftSidebarInterface {
  isLoading: boolean;
}

const LeftSidebar: React.FC<LeftSidebarInterface> = ({ isLoading }) => {
  const [selectedProject, setSelectedProject] = useState<any | null>(null);
  const projects = useSelector(projectSelectors.projectListWithProducts);
  const [projectsList, setProjectsList] = useState<any[] | null>(projects);
  const orderBy = useSelector((state: AppState) => state.projects.orderBy);

  const { id: projectId, pid: productId } = useParams();

  const history = useHistory();
  const [params, location] = useLocationParams();

  const createNewProject = (): void => {
    history.push({ pathname: Routes.NEW_PROJECT, search: params.toString() });
  };

  const orderListBy = async (orderBy: OrderByEnum) => {
    params.set('orderBy', orderBy);
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const searchFunc = debounce(async (searchValue: string) => {
    params.set('searchValue', searchValue);
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  }, 300);

  useEffect(() => {
    if (projectId) {
      const findSelected = projects.find((project) => project.id === +projectId);
      const index = projects.findIndex((project) => project.id === findSelected?.id);
      setSelectedProject(findSelected || selectedProject);
      findSelected && projects.splice(index, 1);
      setProjectsList(projects);
    } else {
      setSelectedProject(null);
      setProjectsList(projects);
    }
  }, [projectId, productId, orderBy, projects]);

  return (
    <>
      <SearchBar searchFunc={searchFunc} />
      <LeftSidebarHeader
        orderBy={orderBy}
        createNewProject={createNewProject}
        orderListBy={orderListBy}
        loading={isLoading}
      />
      {selectedProject && (
        <div className="selected-project-container" data-testid="leftSidebarProjects">
          <Project key={selectedProject.id} id={selectedProject.id}>
            <Project.Slug
              isActive={Number(projectId) === selectedProject.id}
              productCount={selectedProject.products.length}
            >
              {selectedProject.slug}
            </Project.Slug>
            <Project.Name>{selectedProject.name}</Project.Name>
            <Project.ProductList>
              {selectedProject.products &&
                selectedProject.products.map((product: AnyProductStore) => (
                  <Project.Product
                    key={product.id}
                    projectId={selectedProject.id}
                    productId={product.id}
                    displayName={product.displayName}
                    isActive={Number(productId) === product.id}
                  />
                ))}
            </Project.ProductList>
          </Project>
        </div>
      )}
      {!isLoading ? (
        <div className="full-height-column" data-testid="leftSidebarProjects">
          {projectsList &&
            projectsList.map((project) => {
              return (
                <Project key={project.id} id={project.id}>
                  <Project.Slug isActive={Number(projectId) === project.id} productCount={project.products.length}>
                    {project.slug}
                  </Project.Slug>
                  <Project.Name>{project.name}</Project.Name>
                  <Project.ProductList>
                    {project.products &&
                      project.products.map((product: AnyProductStore) => (
                        <Project.Product
                          key={product.id}
                          projectId={project.id}
                          productId={product.id}
                          displayName={product.displayName}
                          isActive={Number(productId) === product.id}
                        />
                      ))}
                  </Project.ProductList>
                </Project>
              );
            })}
        </div>
      ) : (
        <div className="hero" style={{ backgroundColor: '#fff' }}>
          <div className="container columns has-items-centered">
            <div className="column">
              <ClipLoader color="#f58c39" size={2} sizeUnit="rem" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LeftSidebar;
