interface FlattenOptions {
  delimiter?: string;
  safe?: boolean;
  maxDepth?: number;
  transformKey?: (x: string) => string;
}

interface Flatten {
  <TTarget, TResult>(target: TTarget, options?: FlattenOptions): TResult;
}

function isBuffer(val: any): boolean {
  // eslint-disable-next-line no-sparse-arrays
  return ![, null].includes(val) && val.constructor === Buffer;
}

function keyIdentity(key: string): string {
  return key;
}

const flatten: Flatten = (target, opts = {}) => {
  const objDelimiter = opts.delimiter || '.';
  const arrDelimiter = opts.delimiter || '-';
  const transformKey = opts.transformKey || keyIdentity;
  const output: any = {};

  function step(object: any, prev?: any, currentDepth = 1): any {
    Object.keys(object).forEach(function(key) {
      const value = object[key];
      const isarray = opts.safe && Array.isArray(value);
      const type = Object.prototype.toString.call(value);
      const isbuffer = isBuffer(value);
      const isobject = type === '[object Object]' || type === '[object Array]';

      const newKey = prev
        ? prev + (Array.isArray(object) ? arrDelimiter : objDelimiter) + transformKey(key)
        : transformKey(key);

      if (!isarray && !isbuffer && isobject && (!opts.maxDepth || currentDepth < opts.maxDepth)) {
        return step(value, newKey, currentDepth + 1);
      }

      output[newKey] = value;
    });
  }
  step(target);

  return output;
};

const objectToFormDataConverter = (object: any) => {
  const flattenedObject: any = flatten(object);

  const formData = new FormData();
  Object.keys(flattenedObject).forEach((item) => {
    formData.append(item, flattenedObject[item]);
  });
  return formData;
};
export default objectToFormDataConverter;
