import productApi from 'API/products';
import { createAction, ActionType, action } from 'typesafe-actions';
import { ProductResponse, SimpleProductResponse, AnyProductStore } from 'interfaces/API/products';
import { Dispatch } from 'redux';
import actionTypes from './actionTypes';

export type ProductState = {
  productsList: AnyProductStore[];
  currentStage: number;
  redirectTo: string | null;
};

export const setCurrentStage = createAction(actionTypes.SET_CURRENT_STAGE)<number>();
export const addProducts = createAction(actionTypes.ADD_PRODUCTS)<SimpleProductResponse[]>();
export const setProducts = createAction(actionTypes.SET_PRODUCTS)<SimpleProductResponse[]>();
export const updateProducts = createAction(actionTypes.UPDATE_PRODUCTS)<SimpleProductResponse[]>();
export const updateProduct = createAction(actionTypes.UPDATE_PRODUCT)<{
  productId: number;
  response: ProductResponse;
}>();

const externalActions = {
  setProducts,
  addProducts,
  updateProducts,
  setCurrentStage,
  updateProduct,
};

const internalActions = {};

export type ProductActionTypes = ActionType<typeof internalActions | typeof externalActions>;

export const getProductsList = async (dispatch: Dispatch<ProductActionTypes>): Promise<boolean> => {
  const response = await productApi.getProducts();
  if (response !== null) {
    // dispatch(addProducts(response));
    dispatch(setProducts(response));
    return true;
  }
  return false;
};

export const getProductByIdAction = async (
  dispatch: Dispatch<ProductActionTypes>,
  productId: number
): Promise<void> => {
  const response = await productApi.getProductById(productId);
  if (response !== null) {
    dispatch(updateProduct({ productId, response }));
  }
};

export const updateProductOnSuccess = async (
  productId: number,
  dispatch: Dispatch<ProductActionTypes>
): Promise<void> => {
  const productResponse = await productApi.getProductById(productId);
  if (productResponse) {
    dispatch(updateProduct({ productId, response: productResponse }));
  }
};
