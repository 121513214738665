import React from 'react';
import Checkbox from 'atoms/Checkbox';
import { FieldCheckboxInterface } from 'interfaces';

const FieldCheckbox: React.FC<FieldCheckboxInterface> = ({ field, form, meta, label, ...props }) => (
  <Checkbox {...field} {...props}>
    {label}
  </Checkbox>
);

export default FieldCheckbox;
