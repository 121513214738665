import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { OutboRocket } from 'assets';
import { useDispatch, useSelector } from 'react-redux';

import productSelectors from 'modules/products/selectors';
import { StageBarLink } from 'atoms/StageBarLink';
import { AppState } from 'modules/rootReducer';
import useNumberParams from 'hooks/useNumberParams';
import { groupByStageId } from 'modules/data/selectors';
import useLocationParams from 'hooks/useLocationParams';
import { history } from '../App';

const StageBar: React.FC = () => {
  const { projectId, productId } = useNumberParams();
  const [previousProductId, setPreviousProductId] = useState<number | null>(null);
  const [previousProjectId, setPreviousProjectId] = useState<number | null>(null);

  useEffect(() => {
    if (productId && projectId === previousProjectId) {
      setPreviousProductId(productId);
    } else if (projectId !== previousProjectId) {
      setPreviousProductId(null);
      setPreviousProjectId(projectId);
    }
  }, [previousProjectId, productId, projectId]);

  const [product, currentStage] = useSelector((state: AppState) => [
    productId
      ? productSelectors.productById(productId)(state)
      : !productId && previousProductId
      ? productSelectors.productById(previousProductId)(state)
      : productSelectors.productsByProjectId(projectId)(state)[0],

    productSelectors.currentStageSelector(state),
  ]);

  const group = useSelector((state: AppState) => groupByStageId(product?.stage)(state));

  const routeArr: string[] = ['', 'draft', 'ff', 'pf', ''];

  const baseRoute = product ? `/projects/${projectId}/products/${product.id}` : '';
  const [params, location] = useLocationParams();

  const dispatch = useDispatch();

  const matchIfProduct = (): void => {
    const regex = /\/projects\/[0-9]+\/products\/[0-9]+$\//;
    const isProductBrief = location.pathname.match(regex);
    const redirectUrl = projectId ? `/projects/${projectId}` : `/projects/new`;
    if (isProductBrief) {
      history.push({
        pathname: projectId ? `/projects/${projectId}` : `/projects/new?${params.toString()}`,
        search: params.toString(),
      });
    } else {
      history.push({
        pathname: redirectUrl,
        search: params.toString(),
      });
    }
  };

  return (
    <div className="stage-bar ">
      {/* <Link to={projectId ? `/projects/${projectId}?${params.toString()}` : `/projects/new?${params.toString()}`}> */}
      <div
        className={cx('stage-bar__box', { 'stage-bar__box--active-icon': currentStage === 0 })}
        onClick={() => matchIfProduct()}
      >
        <span className={cx('stage-bar__box__item', { 'stage-bar__box__item--active': currentStage === 0 })}>
          <OutboRocket />
        </span>
      </div>
      {/* </Link> */}
      {routeArr.map((route, index) => {
        return (
          <StageBarLink
            key={index}
            target={`${baseRoute}/${route}`}
            index={index + 1}
            currentStage={currentStage}
            group={group}
          />
        );
      })}
    </div>
  );
};

export default StageBar;

/*

0 <- /projects/(new|:id)
1 <- /projects/:id/products/:pid
2 <- /projects/:id/products/:pid/draft
3 <- /projects/:id/products/:pid/first-final
4 <- /projects/:id/products/:pid/printing-files
5 <- /projects/:id/products/:pid/completed

*/
