import React from 'react';
import { BlankingIcon, Delete } from 'assets';
import filesize from 'filesize';
const Punch: React.FC<any> = ({
  field: { name, value, onChange, onBlur },
  form: { touched, errors, setFieldError },
  removeItem,
  downloadItem,
  ...props
}) => {
  return (
    <div className="row columns has-items-centered file" {...props}>
      <div className="column is-narrow ml-1">
        <BlankingIcon />
      </div>
      <div className="column rows mr-1 ml-1">
        <div className="row">
          <span>{value.filename}</span>
        </div>
        <div className="row">
          <span className="file__size">{filesize(value.size, { base: 10 })}</span>
        </div>
      </div>
      <div className="column is-narrow has-items-centered">
        <div className="button is-orange-lighten mr-1" onClick={(): void => downloadItem(value)}>
          <span>Pobierz Plik</span>
        </div>
        <Delete onClick={removeItem} className="pointer" />
      </div>
    </div>
  );
};

export default Punch;
