import React from 'react';
import { Delete } from 'assets';
import cx from 'classnames';
const CustomImage: React.FC<any> = ({
  field: { value },
  form,
  removeItem,
  disabled,
  toggleLightBox,
  alt = '',
  ...props
}) => {
  const { className, ...rest } = props;
  return (
    <div className={cx(className)} {...rest} onClick={toggleLightBox}>
      <img src={value.url} alt={alt} />
      {!disabled && <Delete onClick={removeItem} className="not-transparent-on-hover" />}
    </div>
  );
};

export default CustomImage;
