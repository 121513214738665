import { AppState } from 'modules/rootReducer';
import { createSelector, Selector } from 'reselect';
import { AnyProductStore } from 'interfaces/API/products';
import { productStages } from 'modules/data/selectors';
import { ProductStage } from 'interfaces/API/projects';

// import { isSimpleProduct } from 'helpers/isSimpleProduct';
const currentStageSelector = (state: AppState): number => state.products.currentStage;

const productsSelector = (state: AppState): AnyProductStore[] => state.products.productsList;

const productsByProjectId = (projectId: number): Selector<AppState, AnyProductStore[]> =>
  createSelector([productsSelector], (list) => list.filter((item) => item.project === projectId));

const productById = (productId: number): Selector<AppState, AnyProductStore | undefined> =>
  createSelector([productsSelector], (list) => list.find((item) => item.id === productId));

const projectProductsLength = (projectId: number): Selector<AppState, number> =>
  createSelector([productsByProjectId(projectId)], (list) => list.length);

const projectProductIndex = (projectId: number, productId: number): Selector<AppState, number> =>
  createSelector(productsByProjectId(projectId), (list) => list.findIndex((item) => item.id === productId));

const newProductId = (
  projectId: number,
  productId: number
): Selector<AppState, { nextProductId: number; previousProductId: number }> =>
  createSelector(
    productsByProjectId(projectId),
    projectProductsLength(projectId),
    projectProductIndex(projectId, productId),
    (list, length, index) => {
      const next = (index + 1) % length;
      const previous = index === 0 ? length - 1 : index - 1;
      return {
        nextProductId: list[next].id,
        previousProductId: list[previous].id,
      };
    }
  );

const productStage = (
  productId: number
): Selector<AppState, { label: string; group: number; id: ProductStage } | undefined> =>
  createSelector(productById(productId), productStages, (product, stages) => {
    const findResult = stages.find((stage) => stage.id === product?.stage);
    if (findResult) {
      const { label, group, id } = findResult;
      return { label, group, id };
    }
    return undefined;
  });

const productPhotoBrief = (productId: number): Selector<AppState, string | undefined | null> =>
  createSelector(productById(productId), (product) => product?.photoBrief);

export default {
  productPhotoBrief,
  currentStageSelector,
  productsSelector,
  productsByProjectId,
  productById,
  projectProductsLength,
  projectProductIndex,
  newProductId,
  productStage,
};
// export const productSelector = (id: number) =>
//   createSelector(
//     productListSelector,
//     (list: SimpleProductResponse[]) => list.find((item) => item.id === id)
// //   );
// const simpleProductsSelector = createSelector(
//   productsSelector,
//   (obj: { [key: number]: Product | SimpleProduct }) =>
//     Object.fromEntries(Object.entries(obj).filter(([key, value]) => isSimpleProduct(value)))
// );

// export const simpleProductSelector = (id: number): any =>
//   createSelector(
//     [(state: AppState): (SimpleProductResponse | ProductResponse)[] => state.products.productsList],
//     (list) => list.filter(isSimpleProduct).find((item) => item.id === id)
//   ) as (state: AppState) => SimpleProductResponse | undefined;
