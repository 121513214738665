import { AppState } from 'modules/rootReducer';
import { createSelector, Selector } from 'reselect';
import { DesignStageStore } from 'interfaces/API/draft';

//TODO: CREATESELECTORS

export const printingFiles = (state: AppState): DesignStageStore[] => state.printingFiles.printingFilesList;

export const printingFileById = (pfId: number): Selector<AppState, DesignStageStore | undefined> =>
  createSelector(printingFiles, (list) => list.find((item) => item.id === pfId));

// export const labelToStageSelector = (stage: string): Selector<AppState, ProductStageResponse | undefined> =>
//   createSelector(productStages, (list) => list.find((item) => item.id === stage));
