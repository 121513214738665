import { createAction, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';
import photoBriefApi from 'API/photoBrief';

import {
  PhotoBriefResponse,
  PhotoBriefStore,
  PhotoBriefCategoryResponse,
  PhotoBriefItemResponse,
  PhotoBriefItemRequest,
  PhotoBriefItemFormModel,
  PhotoBriefCategoryEnum,
  PhotoBriefChoiceRequest,
} from 'interfaces/API/photoBrief';
import { castToPhotoRequest, isProperPhoto } from 'helpers/photoBriefFileHelpers';
import { toast } from 'react-toastify';

export type PhotoBriefState = {
  photoBriefList: PhotoBriefStore[];
  photoBriefCategories: PhotoBriefCategoryResponse[];
};

export enum actionTypes {
  ADD_PHOTO_BRIEF = 'photoBrief/ADD_PHOTO_BRIEF',
  ADD_PHOTO_BRIEF_ITEM = 'photoBrief/ADD_PHOTO_BRIEF_ITEM',
  SET_CATEGORIES = 'photoBrief/SET_CATEGORIES',
  UPDATE_PHOTO_BRIEF = 'photoBrief/UPDATE_PHOTO_BRIEF',
  DELETE_PHOTO_BRIEF_ITEM = 'photoBrief/DELETE_PHOTO_BRIEF_ITEM',
}

const externalActions = {};

const internalActions = {
  addPhotoBrief: createAction(actionTypes.ADD_PHOTO_BRIEF)<{ photoBriefId: number; response: PhotoBriefResponse }>(),
  addPhotoBriefItem: createAction(actionTypes.ADD_PHOTO_BRIEF_ITEM)<{
    photoBriefId: number;
    response: PhotoBriefItemResponse;
  }>(),
  setCategories: createAction(actionTypes.SET_CATEGORIES)<{ response: PhotoBriefCategoryResponse[] }>(),
  updatePhotoBrief: createAction(actionTypes.UPDATE_PHOTO_BRIEF)<{
    photoBriefId: number;
    response: PhotoBriefResponse;
  }>(),
  deletePhotoBriefItem: createAction(actionTypes.DELETE_PHOTO_BRIEF_ITEM)<{ photoBriefId: number; itemId: number }>(),
};

export type PhotoBriefActionTypes = ActionType<typeof internalActions | typeof externalActions>;

const getPhotoBriefById = async (photoBriefId: number, dispatch: Dispatch<PhotoBriefActionTypes>): Promise<boolean> => {
  const response = await photoBriefApi.getPhotoBriefById(photoBriefId);
  if (response) {
    dispatch(internalActions.addPhotoBrief({ photoBriefId, response }));
    return true;
  }
  return false;
};

const getPhotoBriefCategories = async (dispatch: Dispatch<PhotoBriefActionTypes>): Promise<void> => {
  const response = await photoBriefApi.getPhotoBriefCategories();
  if (response) {
    dispatch(internalActions.setCategories({ response }));
  }
};

const addPhotoBriefItem = async (
  data: PhotoBriefItemFormModel,
  category: PhotoBriefCategoryEnum,
  photoBriefId: number,
  dispatch: Dispatch<PhotoBriefActionTypes>
): Promise<void> => {
  const castedPhotos = data.photos.filter(isProperPhoto).map(castToPhotoRequest);
  const response = await photoBriefApi.addPhotoBriefItem({ ...data, photos: castedPhotos, category }, photoBriefId);
  if (response) {
    dispatch(internalActions.addPhotoBriefItem({ photoBriefId, response }));
    const photoBriefResponse = await photoBriefApi.getPhotoBriefById(photoBriefId);
    if (photoBriefResponse) {
      dispatch(internalActions.updatePhotoBrief({ photoBriefId, response: photoBriefResponse }));
    }
    toast.success('Dodano pomyślnie');
  }
};

const acceptPhotoBrief = async (
  data: PhotoBriefChoiceRequest,
  photoBriefId: number,
  dispatch: Dispatch
): Promise<void> => {
  const response = await photoBriefApi.acceptPhotoBriefItem(data, photoBriefId);
  if (response) {
    dispatch(internalActions.updatePhotoBrief({ photoBriefId, response }));
    toast.success('Zaakceptowano pomyślnie.');
  }
};

const publishPhotoBrief = async (photoBriefId: number, dispatch: Dispatch): Promise<void> => {
  const response = await photoBriefApi.publishPhotoBrief(photoBriefId);
  if (response) {
    dispatch(internalActions.updatePhotoBrief({ photoBriefId, response }));
    toast.success('Fotobrief został opublikowany.');
  }
};

const finishPhotoBrief = async (photoBriefId: number, dispatch: Dispatch): Promise<boolean> => {
  const response = await photoBriefApi.finishPhotoBrief(photoBriefId);
  if (response) {
    dispatch(internalActions.updatePhotoBrief({ photoBriefId, response }));
    toast.success('Fotobrief został zakończony.');
    return true;
  }
  toast.error('Nie udało zakończyć się fotobriefu.');
  return false;
};

const deletePhotoBriefItem = async (photoBriefId: number, itemId: number, dispatch: Dispatch): Promise<void> => {
  const response = await photoBriefApi.deletePhotoBriefItem(itemId);
  if (response === 200 || response === 204) {
    dispatch(internalActions.deletePhotoBriefItem({ photoBriefId, itemId }));
    const photoBriefResponse = await photoBriefApi.getPhotoBriefById(photoBriefId);
    if (photoBriefResponse) {
      dispatch(internalActions.updatePhotoBrief({ photoBriefId, response: photoBriefResponse }));
    }
    toast.success('Usunięto pomyślnie.');
  }
};

export default {
  finishPhotoBrief,
  deletePhotoBriefItem,
  publishPhotoBrief,
  acceptPhotoBrief,
  addPhotoBriefItem,
  getPhotoBriefById,
  getPhotoBriefCategories,
};
