import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useNumberParams from './useNumberParams';
import briefActions from 'modules/brief/actions';
import finalPhotoBriefActions from 'modules/finalPhotoBrief/actions';
import briefSelectors from 'modules/brief/selectors';
import finalPhotoBriefSelectors from 'modules/finalPhotoBrief/selectors';
import { FinalPhotoBriefStore, PickedPhotoBriefConfig } from 'interfaces/API/finalPhotoBrief';
import { AppState } from 'modules/rootReducer';
import useRequestState, { RequestState } from './useRequestState';

type CurrentFinalPhotoBrief = [RequestState, FinalPhotoBriefStore | null, PickedPhotoBriefConfig | null, () => void];

const useFinalPhotoBrief = (): CurrentFinalPhotoBrief => {
  const { productId } = useNumberParams();
  const dispatch = useDispatch();

  const [finalPhotoBrief, briefConfig] = useSelector((state: AppState) => [
    finalPhotoBriefSelectors.finalPhotoBriefById(productId)(state),
    briefSelectors.briefConfig(productId)(state),
  ]);
  const [retry, setRetry] = useState(0);
  const [status, setError, setLoading, setDefault] = useRequestState();
  // const [statusConfig, setConfigError, setConfigLoading, setConfigDefault] = useRequestState();
  // const [statusFPB, setFPBError, setFPBLoading, setFPBDefault] = useRequestState();
  const handleRetry = (): void => {
    setDefault();
    setRetry(retry + 1);
  };

  useEffect(() => {
    if (!briefConfig && status === RequestState.DEFAULT) {
      setLoading();
      (async (): Promise<void> => {
        const response = await briefActions.getBriefById(dispatch, productId);
        if (!response) {
          setError();
          return;
        }
        setDefault();
      })();
    }
  }, [briefConfig, productId, dispatch, status, setLoading, setDefault, setError, retry]);

  useEffect(() => {
    if (!finalPhotoBrief && status === RequestState.DEFAULT) {
      setLoading();
      (async (): Promise<void> => {
        const response = await finalPhotoBriefActions.getFinalPhotoBriefById(productId, dispatch);
        if (!response) {
          setError();
          return;
        }
        setDefault();
      })();
    }
  }, [dispatch, productId, finalPhotoBrief, status, setLoading, setDefault, setError, retry]);

  return [status, finalPhotoBrief ? finalPhotoBrief : null, briefConfig ? briefConfig : null, handleRetry];
};
export default useFinalPhotoBrief;
