import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import {
  Field,
  FormikProps,
  withFormik,
  FieldArray,
  Form,
  ErrorMessage,
  setNestedObjectValues,
  FormikTouched,
} from 'formik';
import { CirclePlus, FileIcon, BlankingIcon, DownloadIcon } from 'assets';
import CustomImage from 'molecules/ProductBriefImage';
import Blanking from 'molecules/Blanking';
import ProductBriefNavigationButtons from 'molecules/ProductBriefNavigationButtons';
import cx from 'classnames';
import { RouteComponentProps } from 'react-router';
import { Photo } from 'API/projectsDeprecated';
import isEqual from 'lodash.isequal';
import CustomLightbox from 'molecules/CustomLightbox';
import BriefHeader from 'atoms/BriefHeader';
import HorizontalLine from 'atoms/HorizontalLine';
import FieldCheckbox from 'molecules/fields/FieldCheckbox';
import FieldButtonCheckbox from 'molecules/fields/FieldButtonCheckbox';
import FieldTextInput from 'molecules/fields/FieldTextInput';
import FieldTextArea from 'molecules/fields/FieldTextArea';
import DropdownField from 'molecules/fields/DropdownField';
import Modal from 'molecules/modals/Modal';
import FieldInputError from 'atoms/FieldInputError';
import useCurrentBrief from 'hooks/useCurrentBrief';
import useNumberParams from 'hooks/useNumberParams';
import { handleImageSelect, downloadItem, handleFileSelect, downloadByURL } from 'helpers/fileHelpers';
import { ProductBriefSelectorsProps, ProductBriefFormValues, ManagementProps } from './ProductBrief.interfaces';
import ProductBriefSchema from './ProductBrief.schema';
import briefActions, { redirectTo } from 'modules/brief/actions';
import useToggler from 'hooks/useToggler';
import productSelectors from 'modules/products/selectors';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/rootReducer';
import userSelectors from 'modules/users/selectors';
import { packagingTypeSelector } from 'modules/data/selectors';
import { BriefHeaderWithLength } from 'molecules/BriefHeaderWithLength';
import useCurrentStage from 'hooks/useCurrentStage';
import RetryButton from 'molecules/RetryButton';
import { ClimbingLoader } from 'atoms/ClimbingLoader';
import { RequestState } from 'hooks/useRequestState';
import useLocationParams from 'hooks/useLocationParams';
import { history } from '../../App';
import briefApi from 'API/briefs';

import briefSelectors from 'modules/brief/selectors';
import { ClipLoader } from 'react-spinners';
import projectSelectors from 'modules/projects/selectors';

let redirectFlag = false;

const ProductBriefForm: React.FC<ProductBriefSelectorsProps &
  ManagementProps &
  FormikProps<ProductBriefFormValues>> = ({ dispatch, ...props }) => {
  //TODO INVESTIGATE USEMEMO MAYBE?
  const [params, location] = useLocationParams();
  const [currentLocation, setCurrentLocation] = useState(
    useMemo(() => {
      return location.pathname;
    }, [location.pathname])
  );
  const { projectId, productId } = useNumberParams();
  const [status, currentBrief, handleRetry] = useCurrentBrief();
  const [
    currentProjectProductsLength,
    currentProductIndex,
    userRole,
    newId,
    packagingTypes,
    product,
    briefRedirectTo,
    currentProject,
  ] = useSelector((state: AppState) => [
    productSelectors.projectProductsLength(projectId)(state),
    productSelectors.projectProductIndex(projectId, productId)(state),
    userSelectors.currentUserRole(state),
    productSelectors.newProductId(projectId, productId)(state),
    packagingTypeSelector(state),
    productSelectors.productById(productId)(state),
    briefSelectors.briefRedirectTo(state),
    projectSelectors.projectById(Number(projectId))(state),
  ]);
  const [isSaveModalOpen, toggleSaveModal] = useToggler();
  const [isPhotoModalOpen, togglePhotoModal] = useToggler();
  const [isBlankingModalOpen, toggleBlankingModal] = useToggler();
  const [isPublishModalOpen, togglePublishModal] = useToggler();
  const [isAcceptModalOpen, toggleAcceptModal] = useToggler();
  const [isRejectModalOpen, toggleRejectModal] = useToggler();
  const [lightboxOpen, toggleLightBox] = useToggler();
  const [isCopying, setCopying] = useState(false);
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [pdfLoading, setPDFLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [counter, setCounter] = useState(0);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { isSubmitting, validateForm, values, setTouched } = props;

  //To Close submit modal on subbmit end
  useEffect(() => {
    if (isSubmitting === false && isAcceptModalOpen === true) {
      toggleAcceptModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleAcceptModal, isSubmitting]);

  const isEditable = useMemo(() => {
    return (currentBrief && currentBrief.editable) || false;
  }, [currentBrief]);
  const isPublishable = useMemo(() => {
    return (currentBrief && currentBrief.publishable) || false;
  }, [currentBrief]);

  const isAcceptable = useMemo(() => {
    return (currentBrief && currentBrief.acceptable) || false;
  }, [currentBrief]);

  const onImagePickerClick = (): void => {
    if (imageInputRef && imageInputRef.current) {
      imageInputRef.current.click();
    }
  };

  const onFilePickerClick = (): void => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleCloneProduct = async (previousId: number) => {
    setCopying(true);
    await briefActions.cloneBrief(dispatch, productId, previousId);
    setCopying(false);
  };

  useCurrentStage(1);

  useEffect(() => {
    if (counter > 0) {
      validateForm(values);
      setTouched(setNestedObjectValues<FormikTouched<ProductBriefFormValues>>(values, true));
    }
  }, [counter, setTouched, validateForm, values]);

  const isBriefEqual = (): boolean => {
    if (currentBrief) {
      const { id, acceptable, editable, publishable, config, createdAt, updatedAt, ...brief } = currentBrief;
      return !editable || isEqual({ ...brief, config }, values);
    }
    return true;
  };

  useEffect(() => {
    if (briefRedirectTo) {
      if (isBriefEqual()) {
        if (!redirectFlag) {
          redirectFlag = true;
          history.push({ pathname: briefRedirectTo, search: params.toString() });
        } else {
          redirectFlag = false;
        }
      } else if (briefRedirectTo !== location.pathname) {
        toggleSaveModal();
      }
    }
  }, [briefRedirectTo]);

  const onSaveAction = async () => {
    if (currentBrief) {
      const { id, acceptable, editable, publishable, config, ...brief } = currentBrief;
      if (!isBriefEqual()) {
        await briefActions.patchBriefAction(dispatch, Number(productId), values);
      } else if (acceptable) {
        await briefActions.acceptBriefAction(dispatch, Number(productId));
      }
    }
  };

  const generatePDF = async (): Promise<void> => {
    setPDFLoading(true);
    const url = await briefApi.exportBriefToPdf(
      currentBrief!.id,
      `BRIEF-${currentBrief?.modelNo}-${currentBrief?.namePol}.pdf`
    );
    if (url) {
      downloadByURL(url, 'test.pdf');
    }
    setPDFLoading(false);
  };

  const handleRejectProduct = async (): Promise<void> => {
    await briefActions.rejectBriefAction(dispatch, Number(productId));
  };

  const handlePublishProduct = async (): Promise<void> => {
    if (!isEqual(currentBrief, props.values)) {
      await briefActions.patchBriefAction(dispatch, productId, props.values);
    }
    await briefActions.publishBriefAction(dispatch, Number(productId));
  };

  return status === RequestState.IS_LOADING ? (
    <ClimbingLoader />
  ) : status === RequestState.HAS_ERROR ? (
    <div className="retry-dashboard">
      <RetryButton onRetry={handleRetry} isLoading={false} />
    </div>
  ) : (
    <Form className="form">
      <div className="field-section">
        <div className="rows">
          <div className="row">
            <BriefHeaderWithLength briefType="Brief Produktowy" />
          </div>
          <div className="row columns">
            <div className="column form__header--left">
              {currentProductIndex !== undefined && currentProductIndex >= 1 && newId && isEditable && (
                <button
                  className={cx('button is-orange-lighten', { 'is-loading': isCopying })}
                  disabled={isCopying}
                  onClick={async (): Promise<void> => handleCloneProduct(newId.previousProductId)}
                  type="button"
                >
                  <span>{`Kopiuj dane z ${currentProductIndex} / ${currentProjectProductsLength}`}</span>
                </button>
              )}
            </div>

            <div className="column items-right">
              <ProductBriefNavigationButtons
                stage={product ? product.stage : null}
                acceptable={isAcceptable}
                editable={isEditable}
                publishable={isPublishable}
                projectId={Number(projectId)}
                nextProductId={newId ? newId.nextProductId : null}
                previousProductId={newId ? newId.previousProductId : null}
                currentUserRole={userRole}
                rejectProductBrief={toggleRejectModal}
                publishProductBrief={togglePublishModal}
                acceptProductBrief={toggleAcceptModal}
                isSubmitting={isSubmitting}
                isPublishModalOpen={isPublishModalOpen}
                productsLength={currentProjectProductsLength}
                currentProductIndex={currentProductIndex}
                onSaveAction={onSaveAction}
              />
            </div>
          </div>
        </div>
      </div>
      {userRole === 'agency' && !['waiting_for_brief', 'waiting_for_brief_accept_by_agency'].includes(product!.stage) && (
        <div className="field-section">
          <div className="rows">
            <div className="row columns has-items-centered">
              <div className="column">
                <button
                  type="submit"
                  onClick={generatePDF}
                  className="button is-orange-lighten with-icon with-icon--right"
                  disabled={pdfLoading}
                >
                  {pdfLoading ? <ClipLoader color="#fff" size={2} sizeUnit="rem" /> : 'Eksport do pdf'}
                  <DownloadIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="field-section">
        <div className="rows">
          <div className="row rows">
            <div className="row">
              <Field
                name="namePol"
                component={FieldTextInput}
                type="text"
                placeholder="Nazwa produktu - PL"
                className="brief__input"
                disabled={!isEditable}
              />
            </div>
            <div className="row">
              <ErrorMessage name="namePol" component={FieldInputError} />
            </div>
          </div>
          <div className="row rows">
            <div className="row">
              <Field
                name="nameEng"
                component={FieldTextInput}
                type="text"
                placeholder="Nazwa produktu - EN"
                className="brief__input"
                disabled={!isEditable}
              />
            </div>
            <div className="row">
              <ErrorMessage name="nameEng" component={FieldInputError} />
            </div>
          </div>
          <div className="row columns stacked">
            <div className="column is-narrow">
              <Field
                name="modelNo"
                component={FieldTextInput}
                type="text"
                placeholder="Model nr"
                className="brief__input"
                disabled={!isEditable}
              />
              <ErrorMessage name="modelNo" component={FieldInputError} />
            </div>
            <div className="column  ml-1 mr-1 is-narrow mt-stacked-1 mb-stacked-1 ml-stacked-0 mr-stacked-0">
              <Field
                name="batchNo"
                component={FieldTextInput}
                type="text"
                placeholder="Nr. Partii"
                className="brief__input"
                disabled={!isEditable}
              />
              <ErrorMessage name="batchNo" component={FieldInputError} />
            </div>
            <div className="column is-narrow">
              <Field
                name="ean"
                component={FieldTextInput}
                type="text"
                placeholder="EAN"
                className="brief__input"
                disabled={!isEditable}
              />
              <ErrorMessage name="ean" component={FieldInputError} />
            </div>
          </div>
          <div className="row columns has-items-centered">
            <div className="column is-4">
              <Field
                placeholder="Rodzaj opakowania"
                fullwidth={true}
                items={packagingTypes}
                name="packagingType"
                component={DropdownField}
                disabled={!isEditable}
              />
              <ErrorMessage name="packagingType" component={FieldInputError} />
            </div>
            <div className="column is-6 items-spaced m-2">
              <Field
                type="checkbox"
                component={FieldCheckbox}
                name="config.photobrief"
                label="Zdjęcia"
                disabled={!isEditable}
              />

              <Field
                type="checkbox"
                component={FieldCheckbox}
                name="config.illustration"
                label="Ilustracje"
                disabled={!isEditable}
              />

              <Field
                type="checkbox"
                component={FieldCheckbox}
                name="config.manual"
                label="Instrukcja"
                disabled={!isEditable}
                // dependencies={[{ name: 'manual', value: '' }]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="field-section">
        <div className="rows">
          <FieldArray name="photos">
            {({ push, remove, form: { values } }): JSX.Element => {
              const photos =
                values.photos && values.photos.length > 0
                  ? values.photos.map((photo: Photo, index: number) => {
                      return (
                        <Field
                          key={photo.url}
                          component={CustomImage}
                          removeItem={(e: any): any => {
                            e.stopPropagation();
                            togglePhotoModal();
                            setCurrentIndex(index);
                          }}
                          name={`photos.${index}`}
                          className="image image__box pointer image__with-remove"
                          disabled={!isEditable}
                          toggleLightBox={(): void => {
                            setLightboxIndex(index);
                            toggleLightBox();
                          }}
                          alt={photo.filename}
                        />
                      );
                    })
                  : null;

              return (
                <>
                  <div className="row">
                    <span className="bolder">Zdjęcia produktu:</span>
                    <input
                      type="file"
                      ref={imageInputRef}
                      className="image-picker__input pointer"
                      accept="image/*"
                      multiple
                      onChange={(e): void => handleImageSelect(e, push)}
                    />
                  </div>
                  <div className="row wrap">
                    {isEditable && (
                      <div
                        className="image-picker image-picker__box pointer"
                        onClick={onImagePickerClick}
                        data-testid="productPhotoPicker"
                      >
                        <CirclePlus />
                      </div>
                    )}
                    {photos}
                    <ErrorMessage name="photos" component={FieldInputError} />
                  </div>
                  {isPhotoModalOpen && currentIndex !== null && (
                    <Modal open={isPhotoModalOpen} setOpen={togglePhotoModal}>
                      <Modal.Root>
                        <Modal.Header>Czy chcesz usunąć załącznik</Modal.Header>
                        <Modal.Content>
                          <div className="modal-content__image">
                            <img src={props.values.photos[currentIndex] && props.values.photos[currentIndex].url} />
                          </div>
                        </Modal.Content>
                        <Modal.Footer>
                          <button
                            className="button is-orange-lighten mr-1"
                            type="button"
                            onClick={async (): Promise<void> => {
                              if ('file' in props.values.photos[currentIndex]) {
                                remove(currentIndex);
                              } else if ('id' in props.values.photos[currentIndex]) {
                                await briefActions.deletePhoto(
                                  dispatch,
                                  props.values.photos[currentIndex].id as number,
                                  productId
                                );
                                // const response = await removeProductImage(
                                //   props.values.photos[currentIndex].id as number
                                // );
                                // if (response) {
                                //   remove(currentIndex);
                                // }
                              }
                              togglePhotoModal();
                            }}
                          >
                            <span>Usuń</span>
                          </button>
                          <button type="button" className="button is-light-grey" onClick={togglePhotoModal}>
                            <span>Anuluj</span>
                          </button>
                        </Modal.Footer>
                      </Modal.Root>
                    </Modal>
                  )}
                </>
              );
            }}
          </FieldArray>
        </div>
      </div>
      <div className="field-section">
        <div className="rows">
          <FieldArray name="blankings">
            {({ push, remove, form: { values } }) => {
              const blankings =
                values.blankings && values.blankings.length > 0
                  ? values.blankings.map((blanking: any, index: number) => (
                      <Field
                        key={index}
                        component={Blanking}
                        removeItem={(): void => {
                          toggleBlankingModal();
                          setCurrentIndex(index);
                        }}
                        downloadItem={downloadItem}
                        name={`blankings.${index}`}
                        disabled={!isEditable}
                      />
                    ))
                  : null;

              return (
                <>
                  <div className="row mb-1 items-spaced">
                    <input
                      type="file"
                      ref={fileInputRef}
                      className="picker__input"
                      accept=".pdf"
                      onChange={(e) => handleFileSelect(e, push)}
                      multiple
                    />

                    <span className="bolder">Wykrojnik:</span>
                    {isEditable && (
                      <button
                        type="button"
                        className="button is-orange-lighten with-icon--left"
                        onClick={onFilePickerClick}
                        data-testid="productBlankingPicker"
                      >
                        <FileIcon />
                        <span>dodaj wykrojnik</span>
                      </button>
                    )}
                  </div>

                  {blankings}
                  <ErrorMessage name="blankings" component={FieldInputError} />

                  {isBlankingModalOpen && currentIndex !== null && (
                    <Modal open={isBlankingModalOpen} setOpen={toggleBlankingModal}>
                      <Modal.Root>
                        <Modal.Header>Czy chcesz usunąć załącznik</Modal.Header>
                        <Modal.Content>
                          <div className="columns has-items-centered">
                            <div className="column is-narrow has-items-centered">
                              <BlankingIcon />
                            </div>
                            <div className="column">
                              <span className="properities__name">
                                {props.values.blankings[currentIndex] && props.values.blankings[currentIndex].filename}
                              </span>
                            </div>
                          </div>
                        </Modal.Content>
                        <Modal.Footer>
                          <button
                            className="button is-orange-lighten mr-1"
                            type="button"
                            onClick={async (): Promise<void> => {
                              if ('file' in props.values.blankings[currentIndex]) {
                                remove(currentIndex);
                              } else if ('id' in props.values.blankings[currentIndex]) {
                                await briefActions.deleteBlanking(
                                  dispatch,
                                  props.values.blankings[currentIndex].id as number,
                                  productId
                                );
                                // const response = await removeProductImage(
                                //   props.values.blankings[currentIndex].id as number
                                // );
                                // if (response) {
                                //   remove(currentIndex);
                                // }
                              }
                              toggleBlankingModal();
                            }}
                          >
                            <span>Usuń</span>
                          </button>
                          <button type="button" className="button is-light-grey" onClick={toggleBlankingModal}>
                            <span>Anuluj</span>
                          </button>
                        </Modal.Footer>
                      </Modal.Root>
                    </Modal>
                  )}
                </>
              );
            }}
          </FieldArray>
        </div>
      </div>
      <div className="field-section">
        <div className="rows">
          <div className="row columns has-items-centered">
            <div className="column is-narrow">
              <BriefHeader>Opis</BriefHeader>
            </div>
            <div className="column">
              <HorizontalLine />
            </div>
          </div>
          <div className="row columns">
            <div className="column is-2">
              <span className="bolder">Języki opisów</span>
            </div>
            <div className="column items-spaced is-8">
              <Field
                type="checkbox"
                component={FieldCheckbox}
                name="hasDescriptionPol"
                label="Polski"
                disabled={!isEditable}
              />
              <Field
                type="checkbox"
                component={FieldCheckbox}
                name="hasDescriptionRus"
                label="Rosyjski"
                disabled={!isEditable}
              />
              <Field
                type="checkbox"
                component={FieldCheckbox}
                name="hasDescriptionEng"
                label="Angielski"
                disabled={!isEditable}
              />
              <Field
                type="checkbox"
                component={FieldCheckbox}
                name="hasDescriptionPor"
                label="Portugalski"
                disabled={!isEditable}
              />
              <Field
                type="checkbox"
                component={FieldCheckbox}
                name="hasDescriptionSpa"
                label="Hiszpański"
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="row rows">
            <div className="row">
              <Field
                name="mainDescription"
                component={FieldTextArea}
                placeholder="Opis główny PL"
                rows="5"
                className="form__input--textarea"
                disabled={!isEditable}
              />
            </div>
            <div className="row">
              <ErrorMessage name="mainDescription" component={FieldInputError} />
            </div>
          </div>
          <div className="row rows">
            <div className="row">
              <Field
                name="extraDescription"
                component={FieldTextArea}
                placeholder="Opis dodatkowy PL"
                rows="5"
                className="form__input--textarea"
                disabled={!isEditable}
              />
            </div>
            <div className="row">
              <ErrorMessage name="extraDescription" component={FieldInputError} />
            </div>
          </div>
          <div className="row rows">
            <div className="row">
              <span className="bolder">Dodaj elementy opisu</span>
            </div>
            <div className="row columns stacked">
              <div className="column is-narrow">
                <Field
                  type="checkbox"
                  component={FieldButtonCheckbox}
                  name="config.material"
                  label="Materiał"
                  disabled={!isEditable}
                />
              </div>
              <div className="column is-narrow">
                <Field
                  type="checkbox"
                  component={FieldButtonCheckbox}
                  name="config.cleaningMethod"
                  label="Sposób czyszczenia"
                  disabled={!isEditable}
                />
              </div>
              <div className="column is-narrow">
                <Field
                  type="checkbox"
                  component={FieldButtonCheckbox}
                  name="config.countryOfOrigin"
                  label="Kraj produkcji"
                  disabled={!isEditable}
                />
              </div>
              <div className="column is-narrow">
                <Field
                  type="checkbox"
                  component={FieldButtonCheckbox}
                  name="config.warning"
                  label="Ostrzeżenia"
                  disabled={!isEditable}
                />
              </div>
            </div>
          </div>
          {props.values.config.material && (
            <div className="row rows">
              <div className="row">
                <Field
                  name="material"
                  component={FieldTextArea}
                  placeholder="Materiał"
                  className="form__input--textarea"
                  disabled={!isEditable}
                />
              </div>
              <div className="row">
                <ErrorMessage name="material" component={FieldInputError} />
              </div>
            </div>
          )}
          {props.values.config.cleaningMethod && (
            <div className="row rows">
              <div className="row">
                <Field
                  name="cleaningMethod"
                  component={FieldTextArea}
                  placeholder="Sposób czyszczenia"
                  className="form__input--textarea"
                  disabled={!isEditable}
                />
              </div>
              <div className="row">
                <ErrorMessage name="cleaningMethod" component={FieldInputError} />
              </div>
            </div>
          )}

          {props.values.config.countryOfOrigin && (
            <div className="row rows">
              <div className="row">
                <Field
                  name="countryOfOrigin"
                  component={FieldTextArea}
                  placeholder="Kraj produkcji"
                  className="form__input--textarea"
                  disabled={!isEditable}
                />
              </div>
              <div className="row">
                <ErrorMessage name="countryOfOrigin" component={FieldInputError} />
              </div>
            </div>
          )}

          {props.values.config.warning && (
            <div className="row rows">
              <div className="row">
                <Field
                  name="warning"
                  component={FieldTextArea}
                  placeholder="Ostrzeżenie"
                  className="form__input--textarea"
                  disabled={!isEditable}
                />
              </div>
              <div className="row">
                <ErrorMessage name="warning" component={FieldInputError} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="field-section">
        <div className="rows">
          <div className="row columns has-items-centered">
            <div className="column is-narrow">
              <BriefHeader>Oznaczenia</BriefHeader>
            </div>
            <div className="column">
              <HorizontalLine />
            </div>
          </div>
          <div className="row rows">
            <div className="row">
              <Field
                name="certificate"
                component={FieldTextArea}
                placeholder="Certyfikaty"
                className="form__input--textarea"
                disabled={!isEditable}
              />
            </div>
            <div className="row">
              <ErrorMessage name="certificate" component={FieldInputError} />
            </div>
          </div>
          <div className="row rows">
            <div className="row">
              <Field
                name="informationSigns"
                component={FieldTextArea}
                placeholder="Znaki informacyjne"
                className="form__input--textarea"
                disabled={!isEditable}
              />
            </div>
            <div className="row">
              <ErrorMessage name="informationSigns" component={FieldInputError} />
            </div>
          </div>
          <div className="row rows">
            <div className="row">
              <Field
                name="capabilitiesIcons"
                component={FieldTextArea}
                placeholder="Ikony kompetencji"
                className="form__input--textarea"
                disabled={!isEditable}
              />
            </div>
            <div className="row">
              <ErrorMessage name="capabilitiesIcons" component={FieldInputError} />
            </div>
          </div>
          <div className="row rows">
            <div className="row">
              <Field
                name="productIcons"
                component={FieldTextArea}
                placeholder="Ikony produktowe"
                className="form__input--textarea"
                disabled={!isEditable}
              />
            </div>
            <div className="row">
              <ErrorMessage name="productIcons" component={FieldInputError} />
            </div>
          </div>
        </div>
      </div>

      {props.values.config.photobrief && (
        <div className="field-section">
          <div className="rows">
            <div className="row columns has-items-centered">
              <div className="column is-narrow">
                <BriefHeader>foto brief</BriefHeader>
              </div>
              <div className="column">
                <HorizontalLine />
              </div>
            </div>
            <div className="row rows">
              <div className="row">
                <Field
                  name="arrangementPhoto"
                  component={FieldTextArea}
                  placeholder="Zdjęcia aranżacyjne"
                  className="form__input--textarea"
                  disabled={!isEditable}
                />
              </div>
              <div className="row">
                <ErrorMessage name="arrangementPhoto" component={FieldInputError} />
              </div>
            </div>

            <div className="row rows">
              <div className="row">
                <span className="bolder">Dodatkowe zdjęcia:</span>
              </div>
              <div className="row columns stacked">
                <div className="column is-narrow">
                  <Field
                    type="checkbox"
                    component={FieldButtonCheckbox}
                    name="config.packshot"
                    label="Packshot"
                    disabled={!isEditable}
                  />
                </div>
                <div className="column is-narrow">
                  <Field
                    type="checkbox"
                    component={FieldButtonCheckbox}
                    name="config.extraPhoto"
                    label="Zdjęcie dodatkowe"
                    disabled={!isEditable}
                  />
                </div>
                <div className="column is-narrow">
                  <Field
                    type="checkbox"
                    component={FieldButtonCheckbox}
                    name="config.clipping"
                    label="Clippingi"
                    disabled={!isEditable}
                  />
                </div>
              </div>
            </div>
            {props.values.config.packshot && (
              <div className="row rows">
                <div className="row">
                  <Field
                    name="packshot"
                    component={FieldTextArea}
                    placeholder="Packshot"
                    className="form__input--textarea"
                    disabled={!isEditable}
                  />
                </div>
                <div className="row">
                  <ErrorMessage name="packshot" component={FieldInputError} />
                </div>
              </div>
            )}
            {props.values.config.extraPhoto && (
              <div className="row rows">
                <div className="row">
                  <Field
                    name="extraPhoto"
                    component={FieldTextArea}
                    placeholder="Zdjęcie dodatkowe"
                    className="form__input--textarea"
                    disabled={!isEditable}
                  />
                </div>
                <div className="row">
                  <ErrorMessage name="extraPhoto" component={FieldInputError} />
                </div>
              </div>
            )}
            {props.values.config.clipping && (
              <div className="row rows">
                <div className="row">
                  <Field
                    name="clipping"
                    component={FieldTextArea}
                    placeholder="Clippingi"
                    className="form__input--textarea"
                    disabled={!isEditable}
                  />
                </div>
                <div className="row">
                  <ErrorMessage name="clipping" component={FieldInputError} />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {props.values.config.illustration && (
        <div className="field-section">
          <div className="rows">
            <div className="row columns has-items-centered">
              <div className="column is-narrow">
                <BriefHeader>ilustracja</BriefHeader>
              </div>
              <div className="column">
                <HorizontalLine />
              </div>
            </div>
            <div className="row rows">
              <div className="row">
                <Field
                  name="illustration"
                  component={FieldTextArea}
                  placeholder="Ilustracja"
                  className="form__input--textarea"
                  disabled={!isEditable}
                />
              </div>
              <div className="row">
                <ErrorMessage name="illustration" component={FieldInputError} />
              </div>
            </div>
          </div>
        </div>
      )}

      {props.values.config.manual && (
        <div className="field-section">
          <div className="rows">
            <div className="row columns has-items-centered">
              <div className="column is-narrow">
                <BriefHeader>instrukcja obsługi</BriefHeader>
              </div>
              <div className="column">
                <HorizontalLine />
              </div>
            </div>
            <div className="row rows">
              <div className="row">
                <Field
                  name="manual"
                  component={FieldTextArea}
                  placeholder="Instrukcja obsługi"
                  className="form__input--textarea"
                  disabled={!isEditable}
                />
              </div>
              <div className="row">
                <ErrorMessage name="manual" component={FieldInputError} />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row items-right p-2 ">
        <ProductBriefNavigationButtons
          onSaveAction={onSaveAction}
          stage={product ? product.stage : null}
          acceptable={isAcceptable}
          editable={isEditable}
          publishable={isPublishable}
          projectId={Number(projectId)}
          nextProductId={newId ? newId.nextProductId : null}
          previousProductId={newId ? newId.previousProductId : null}
          currentUserRole={userRole}
          rejectProductBrief={toggleRejectModal}
          publishProductBrief={togglePublishModal}
          acceptProductBrief={toggleAcceptModal}
          isSubmitting={isSubmitting}
          isPublishModalOpen={isPublishModalOpen}
          productsLength={currentProjectProductsLength}
          currentProductIndex={currentProductIndex}
        />
      </div>
      {isSaveModalOpen && (
        <Modal open={isSaveModalOpen} setOpen={toggleSaveModal}>
          <Modal.Root>
            <Modal.Header>Zmieniony brief produktowy</Modal.Header>
            <Modal.Content>
              Dokonano zmian w briefie produktowym.
              <br />
              Czy chcesz je zapisać?
            </Modal.Content>
            <Modal.Footer>
              <button
                className={cx('button is-orange-lighten mr-1', { 'is-loading': isSubmitting })}
                type="button"
                onClick={async (): Promise<void> => {
                  props.setSubmitting(true);
                  await onSaveAction();
                  dispatch(redirectTo(null));
                  props.setSubmitting(false);
                  toggleSaveModal();
                  briefRedirectTo && history.push(briefRedirectTo);
                }}
                disabled={isSubmitting}
              >
                <span>Zapisz</span>
              </button>
              <button type="button" className="button is-light-grey" onClick={toggleSaveModal}>
                <span>Anuluj</span>
              </button>
            </Modal.Footer>
          </Modal.Root>
        </Modal>
      )}
      {isPublishModalOpen && (
        <Modal open={isPublishModalOpen} setOpen={togglePublishModal}>
          <Modal.Root>
            <Modal.Header>Publikowanie produktu</Modal.Header>
            <Modal.Content>
              Czy chcesz opublikować produkt? &nbsp;
              <span className="bolder">{`${currentBrief && currentBrief.namePol} / ${currentBrief &&
                currentBrief.modelNo}`}</span>
            </Modal.Content>
            <Modal.Footer>
              <button
                className={cx('button is-orange-lighten mr-1', { 'is-loading': isSubmitting })}
                type="button"
                onClick={async (): Promise<void> => {
                  props.setSubmitting(true);
                  await handlePublishProduct();
                  props.setSubmitting(false);
                  togglePublishModal();
                }}
                disabled={isSubmitting}
              >
                <span>Opublikuj</span>
              </button>
              <button type="button" className="button is-light-grey" onClick={togglePublishModal}>
                <span>Anuluj</span>
              </button>
            </Modal.Footer>
          </Modal.Root>
        </Modal>
      )}
      {isRejectModalOpen && (
        <Modal open={isRejectModalOpen} setOpen={toggleRejectModal}>
          <Modal.Root>
            <Modal.Header>Odrzucenie produktu</Modal.Header>
            <Modal.Content>
              Czy chcesz odrzucić produkt? &nbsp;
              <span className="bolder">{` ${currentBrief && currentBrief.namePol} / ${currentBrief &&
                currentBrief.modelNo}`}</span>
            </Modal.Content>
            <Modal.Footer>
              <button
                className={cx('button is-orange-lighten mr-1', { 'is-loading': isSubmitting })}
                type="button"
                onClick={async (): Promise<void> => {
                  props.setSubmitting(true);
                  await handleRejectProduct();
                  props.setSubmitting(false);
                  toggleRejectModal();
                }}
                disabled={isSubmitting}
              >
                <span>Odrzuć</span>
              </button>
              <button type="button" className="button is-light-grey" onClick={toggleRejectModal}>
                <span>Anuluj</span>
              </button>
            </Modal.Footer>
          </Modal.Root>
        </Modal>
      )}
      {isAcceptModalOpen && (
        <Modal open={isAcceptModalOpen} setOpen={toggleAcceptModal}>
          <Modal.Root>
            <Modal.Header>Akceptacja produktu</Modal.Header>
            <Modal.Content>
              Czy chcesz zaakceptować produkt? &nbsp;
              <span className="bolder">{` ${currentBrief && currentBrief.namePol} / ${currentBrief &&
                currentBrief.modelNo}`}</span>
            </Modal.Content>
            <Modal.Footer>
              <button
                className={cx('button is-orange-lighten mr-1', { 'is-loading': isSubmitting })}
                onClick={(): void => props.handleSubmit()}
                disabled={isSubmitting}
              >
                <span>Zaakceptuj</span>
              </button>
              <button type="button" className="button is-light-grey" onClick={toggleAcceptModal}>
                <span>Anuluj</span>
              </button>
            </Modal.Footer>
          </Modal.Root>
        </Modal>
      )}
      {lightboxOpen && (
        <CustomLightbox
          mainSrc={props.values.photos}
          length={props.values.photos.length}
          toggle={toggleLightBox}
          initialInternalIndex={lightboxIndex}
        />
      )}
    </Form>
  );
};

export default withFormik<
  ProductBriefSelectorsProps & ManagementProps & RouteComponentProps<{ id: string; pid: string }>,
  ProductBriefFormValues
>({
  enableReinitialize: true,

  // Transform outer props into form values
  validationSchema: ProductBriefSchema,
  mapPropsToValues: ({ currentBrief }) => {
    if (currentBrief) {
      const { id, acceptable, editable, publishable, updatedAt, createdAt, ...brief } = currentBrief;

      return brief;
    }

    return {
      namePol: '',
      nameEng: '',
      modelNo: '',
      batchNo: '',
      ean: '',
      illustration: '',
      manual: '',
      hasDescriptionPol: false,
      hasDescriptionRus: false,
      hasDescriptionEng: false,
      hasDescriptionPor: false,
      hasDescriptionSpa: false,
      packagingType: null,
      mainDescription: '',
      extraDescription: '',
      material: '',
      cleaningMethod: '',
      countryOfOrigin: '',
      warning: '',
      photos: [],
      blankings: [],
      certificate: '',
      informationSigns: '',
      capabilitiesIcons: '',
      productIcons: '',
      arrangementPhoto: '',
      packshot: '',
      clipping: '',
      extraPhoto: '',
      config: {
        illustration: false,
        cleaningMethod: false,
        countryOfOrigin: false,
        manual: false,
        material: false,
        warning: false,
        photobrief: false,
        packshot: false,
        clipping: false,
        extraPhoto: false,
      },
    };
  },
  handleSubmit: async (
    values,
    {
      props: {
        dispatch,
        currentBrief,
        match: {
          params: { pid: productId },
        },
      },
      setSubmitting,
    }
  ) => {
    if (currentBrief) {
      const { id, acceptable, editable, publishable, config, ...brief } = currentBrief;
      if (editable && !isEqual({ brief, config }, values)) {
        await briefActions.patchBriefAction(dispatch, Number(productId), values);
      } else if (acceptable) {
        await briefActions.acceptBriefAction(dispatch, Number(productId));
      }
    }
  },
})(ProductBriefForm);
